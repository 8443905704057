import { Breadcrumbs, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useParams } from 'react-router-dom';

import { ApiRoutes } from '../Api/ApiRoutes';
import { ChannelDirection, isListApiResponse } from '../Api/Util';
import { ChannelActivityType, PmaDsProfile, PmaDsResult } from '../Api/Types/CapacityBooster';
import { Cmts, TenantSchedule } from '../Api/Types/Config';
import { ListApiResponse } from '../Api/Types/General';
import { DsChannel, MacDomain } from '../Api/Types/Topology';
import CircularProgress from '@mui/material/CircularProgress';

import {
  GlobalConfigContextProvider,
  handleTenantScheduleQuery,
  useGlobalConfigContext
} from '../Configuration/GlobalStateContext';

import { FeedbackContextProvider, useFeedbackContext } from '../Feedback/FeedbackContext';

import { castToNumber } from './CastToNumber';
import {
  ActionButtonBar,
  DeployCalculatedProfiles,
  DeployFallbackProfiles
} from './Channel/ActionButtonBar';
import { ChannelDeploymentContext } from './Channel/DeploymentContext';
import { ProfileSetDisplay } from './Channel/ProfileSetDisplay';
import { TimeRemainingBar } from './Channel/TimeRemainingBar';
import { NoChannelInfo } from './NoChannelInfo';
import { ManualProfileDeployment } from './Channel/ManualDeployment/ManualProfileDeployment';

import { useGrafanaLink } from '../Dashboard/Dashboard';
import { ApiGet } from '../Api/Util';
import Box from '@mui/material/Box';

export function DownstreamChannelDeploymentComponent(): JSX.Element {
  const { t } = useTranslation();
  const { setGrafanaLink, setGrafanaLinkButton } = useGrafanaLink();
  const dashboardLinkUrl = '/grafana/d/zCgyNO0Vk/operational-deploy-downstream-channel';

  const { cmtsId, macDomainIfIndex, channelIfIndex } = useParams();
  const cmtsIdNum = castToNumber(cmtsId, 0);
  const macDomainIfIndexNum = castToNumber(macDomainIfIndex, 0);
  const channelIfIndexNum = castToNumber(channelIfIndex, 0);

  const [cmtsInfo, setCmtsInfo] = useState<Cmts | null>(null);
  const [macDomain, setMacDomain] = useState<MacDomain | null>(null);
  const [dsChannel, setDsChannel] = useState<DsChannel | null>(null);
  const [actionsEnabled, setActionsEnabled] = useState<boolean>(false);
  const [data, setData] = useState<PmaDsResult[]>();
  const [pmaDsResult, setPmaDsResult] = useState<PmaDsResult | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { setTenantScheduleState } = useGlobalConfigContext();
  const { feedbackError } = useFeedbackContext();

  useEffect(() => {
    setLoading(true);
    ApiGet<ListApiResponse<PmaDsResult>>(
      ApiRoutes.cb.dsProfileSet.filter({
        latest: true,
        cmtsId: cmtsIdNum,
        macDomainIfIndex: macDomainIfIndexNum,
        channelIfIndex: channelIfIndexNum
      })
    ).then(async (response: ListApiResponse<PmaDsResult>) => {
      setLoading(false);
      setData(response.results);
      if (isListApiResponse<PmaDsResult>(response.results) && response.count == 1) {
        setPmaDsResult(response.results[0]);
      } else {
        setPmaDsResult(null);
      }
    });
  }, [cmtsIdNum, macDomainIfIndexNum, channelIfIndexNum]);

  useEffect(() => {
    setGrafanaLinkButton(true);
  }, []);

  useEffect(() => {
    ApiGet<Cmts>(ApiRoutes.config.cmts.single(cmtsIdNum))
      .then(result => {
        setCmtsInfo(result);
      })
      .catch(error => {
        console.log({
          route: 'ApiRoutes.config.cmts',
          cmtsIdNum: cmtsIdNum,
          error: error
        });
        feedbackError(t('deploy.failed_fetching_Cmts', { message: error.message }));
      });
  }, [cmtsIdNum, setCmtsInfo]);

  useEffect(() => {
    ApiGet<ListApiResponse<MacDomain>>(
      ApiRoutes.topology.mac_domain.filter({
        cmtsId: cmtsIdNum,
        macDomainIfIndex: macDomainIfIndexNum,
        latest: true
      })
    )
      .then(result => {
        if (result.count) {
          setMacDomain(result.results[0]);
        }
      })
      .catch(error => {
        console.log({
          route: 'ApiRoutes.topology.mac_domain',
          cmtsId: cmtsIdNum,
          macDomainIfIndex: macDomainIfIndexNum,
          latest: true,
          error: error
        });
        feedbackError(t('deploy.failed_fetching_MacDomain', { message: error.message }));
      });
  }, [cmtsIdNum, macDomainIfIndexNum, setMacDomain]);

  useEffect(() => {
    ApiGet<ListApiResponse<DsChannel>>(
      ApiRoutes.topology.ds_channel.filter({
        cmtsId: cmtsIdNum,
        macDomainIfIndex: macDomainIfIndexNum,
        channelIfIndex: channelIfIndexNum,
        latest: true
      })
    )
      .then(result => {
        if (result.count) {
          setDsChannel(result.results[0]);
        }
      })
      .catch(error => {
        console.log({
          route: 'ApiRoutes.topology.ds_channel',
          cmtsId: cmtsIdNum,
          macDomainIfIndex: macDomainIfIndexNum,
          channelIfIndex: channelIfIndexNum,
          latest: true,
          error: error
        });
        feedbackError(t('deploy.failed_fetching_DsChannel', { message: error.message }));
      });
  }, [cmtsIdNum, macDomainIfIndexNum, channelIfIndexNum, setDsChannel]);

  useEffect(() => {
    ApiGet<ListApiResponse<TenantSchedule>>(ApiRoutes.config.schedule.all)
      .then(async (response: ListApiResponse<TenantSchedule>) => {
        handleTenantScheduleQuery(response, setTenantScheduleState);
      })
      .catch(error => {
        console.log({
          route: 'ApiRoutes.config.schedule',
          error: error
        });
        feedbackError(t('deploy.failed_fetching_TenantSchedule', { message: error.message }));
      });
  }, []);

  useEffect(() => {
    setGrafanaLink(
      `${dashboardLinkUrl}?var-cmts=${cmtsInfo?.name}&var-mac_dom=${macDomain?.ifDescr}&var-ds_chan=${dsChannel?.ifDescr}`
    );
  }, [cmtsInfo, macDomain, dsChannel]);

  return (
    <ChannelDeploymentContext.Provider
      value={{
        cmts: cmtsInfo,
        macDomain: macDomain,
        dsChannel: dsChannel,
        usChannel: null,
        pmaDsResult: pmaDsResult,
        pmaUsResult: null,
        pageState: {
          actionsEnabled: actionsEnabled,
          setActionsEnabled: setActionsEnabled
        }
      }}
    >
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : data === null || pmaDsResult === null ? (
        <NoChannelInfo />
      ) : (
        <FeedbackContextProvider>
          <Breadcrumbs separator="›" sx={{ marginBottom: '20px' }}>
            {/* TODO: These will be links in future builds */}
            <Typography color={(theme): string => theme.colors.inactive.main}>CMTS List</Typography>
            <Typography color={(theme): string => theme.colors.inactive.main}>
              {pmaDsResult.cmts_name}
            </Typography>{' '}
            {/* Link to {cmtsId} */}
            <Typography color={(theme): string => theme.colors.inactive.main}>
              {pmaDsResult.md_ifdescr}
            </Typography>{' '}
            {/* Link to {macDomainId} */}
            <Typography color="inherit">{pmaDsResult.ds_ifdescr}</Typography>{' '}
            {/* Link to {channelId} */}
          </Breadcrumbs>

          <ActionButtonBar>
            {/*<PageFilters />*/}
            {/*<DeploymentStrategy />*/}
            <DeployCalculatedProfiles channel_direction={ChannelDirection.Downstream} />
            <DeployFallbackProfiles channel_direction={ChannelDirection.Downstream} />
          </ActionButtonBar>

          <Grid2 container spacing={2} columns={12}>
            <TimeRemainingBar downstream={true} />
            <ManualProfileDeployment cmtsInfo={cmtsInfo} downstream={true} />
            <ProfileSetDisplay pmaResult={pmaDsResult} downstream={true} />
          </Grid2>
        </FeedbackContextProvider>
      )}
    </ChannelDeploymentContext.Provider>
  );
}

export function DownstreamChannelDeployment(): JSX.Element {
  return (
    <GlobalConfigContextProvider>
      <DownstreamChannelDeploymentComponent />
    </GlobalConfigContextProvider>
  );
}
