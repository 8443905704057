import Grid2 from "@mui/material/Unstable_Grid2";
import {useState} from "react";
import {useGlobalConfigContext} from "../../Configuration/GlobalStateContext";
import {useChannelDeploymentContext} from "./DeploymentContext";
import {ListApiResponse} from "../../Api/Types/General";
import {Trace} from "../../Api/Types/Trace";
import {TimeRemainingPanel} from "./TimeRemainingPanel";

export function TimeRemainingBar({downstream}: {downstream: boolean}): JSX.Element {

    // const [topologyTraces, setTopologyTraces] = useState<ListApiResponse<Trace> | null>(null);
    // const [requestRxMerTraces, setRequestRxMerTraces] = useState<ListApiResponse<Trace> | null>(null);
    // const [calculateProfilesTraces, setCalculateProfilesTraces] = useState<ListApiResponse<Trace> | null>(null);
    // // const [deployProfileTraces, setDeployProfileTraces] = useState<ListApiResponse<Trace> | null>(null);
    // const {cmts, macDomain, dsChannel} = useChannelDeploymentContext();
    // const {setFeedbackState} = useFeedbackContext();
    // const {t} = useTranslation();

    const [topologyTraces, ] = useState<ListApiResponse<Trace> | null>(null);
    const [requestRxMerTraces, ] = useState<ListApiResponse<Trace> | null>(null);
    const [calculateProfilesTraces, ] = useState<ListApiResponse<Trace> | null>(null);
    const {cmts} = useChannelDeploymentContext();

    const {tenantScheduleState: {
        topologySchedule: {topology},
        pnmSchedule: {dsRxmer, usRxmer},
        profileCalcSchedule: {dsProfileCalc, usProfileCalc},
        tenantScheduleLoaded
    }} = useGlobalConfigContext();

    // useEffect(() => {
    //     // TODO: get correct action
    //     ApiGet(ApiRoutes.trace.filter({
    //         latest: true,
    //         cmts: cmtsInfo?.id,
    //         mac_domain_ifindex: macDomain?.ifIndex,
    //         channel_ifindex: dsChannel?.ifIndex,
    //         action_taken: "request_topology"
    //     })).then(
    //         (result) => {
    //             if (isListApiResponse<Trace>(result)) {
    //                 setTopologyTraces(result);
    //             } else {
    //                 // TODO Error
    //                 setFeedbackState({
    //                     open: true,
    //                     message: `${t('deploy.apiGet_error')}`,
    //                     severity: "error",
    //                   });
    //             }
    //         }
    //     ).catch((error: Error) => {
    //         setFeedbackState({
    //             open: true,
    //             message: `${t('deploy.apiGet_error')}: ${error.message}`,
    //             severity: "error",
    //           });
    //     });
    // }, [cmtsInfo, macDomain, dsChannel]);

    // useEffect(() => {
    //     // TODO: get correct action
    //     ApiGet(ApiRoutes.trace.filter({
    //         latest: true,
    //         cmts: cmtsInfo?.id,
    //         mac_domain_ifindex: macDomain?.ifIndex,
    //         channel_ifindex: dsChannel?.ifIndex,
    //         action_taken: "request_ds_rxmer"
    //     })).then(
    //         (result) => {
    //             if (isListApiResponse<Trace>(result)) {
    //                 setRequestRxMerTraces(result);
    //             } else {
    //                 // TODO Error
    //                 setFeedbackState({
    //                     open: true,
    //                     message: `${t('deploy.apiGet_error')}`,
    //                     severity: "error",
    //                   });
    //             }
    //         }
    //     ).catch((error: Error) => {
    //         setFeedbackState({
    //             open: true,
    //             message: `${t('deploy.apiGet_error')}: ${error.message}`,
    //             severity: "error",
    //           });
    //     });
    // }, [cmtsInfo, macDomain, dsChannel]);

    // useEffect(() => {
    //     // TODO: get correct action
    //     ApiGet(ApiRoutes.trace.filter({
    //         latest: true,
    //         cmts: cmtsInfo?.id,
    //         mac_domain_ifindex: macDomain?.ifIndex,
    //         channel_ifindex: dsChannel?.ifIndex,
    //         action_taken: "calculate_profiles"
    //     })).then(
    //         (result) => {
    //             if (isListApiResponse<Trace>(result)) {
    //                 setCalculateProfilesTraces(result);
    //             } else {
    //                 // TODO Error
    //                 setFeedbackState({
    //                     open: true,
    //                     message: `${t('deploy.apiGet_error')}`,
    //                     severity: "error",
    //                   });
    //             }
    //         }
    //     ).catch((error: Error) => {
    //         setFeedbackState({
    //             open: true,
    //             message: `${t('deploy.apiGet_error')}: ${error.message}`,
    //             severity: "error",
    //           });
    //     });
    // }, [cmtsInfo, macDomain, dsChannel]);


    // useEffect(() => {
    //     // TODO: get correct action
    //     ApiGet(ApiRoutes.trace.filter({
    //         latest: true,
    //         cmts: cmtsInfo?.id,
    //         mac_domain_ifindex: macDomain?.ifIndex,
    //         channel_ifindex: dsChannel?.ifIndex,
    //         action_taken: "Deploy Downstream Profile"
    //     })).then(
    //         (result) => {
    //             if (isListApiResponse<Trace>(result)) {
    //                 setDeployProfileTraces(result);
    //             } else {
    //                 // TODO Error
    //                 setFeedbackState({
    //                     open: true,
    //                     message: `${t('deploy.apiGet_error')}`,
    //                     severity: "error",
    //                   });
    //             }
    //         }
    //     ).catch((error: Error) => {
    //         setFeedbackState({
    //             open: true,
    //             message: `${t('deploy.apiGet_error')}: ${error.message}`,
    //             severity: "error",
    //           });
    //     });
    // }, [cmtsInfo, macDomain, dsChannel]);

    return (
        <Grid2 md={12} container>
            <TimeRemainingPanel
                loaded={!!cmts && tenantScheduleLoaded}
                title_t="deploy.topology" lastAction_t="deploy.last_topo_scan" nextAction_t="deploy.next_topo_scan"
                globalScheduleRate_s={topology?.schedule_rate_s}
                scheduleInfo={cmts?.topology_schedule}
                showTrace={false} // TODO Remove this when Topo does traces
                traces={topologyTraces?.results}
            />
            <TimeRemainingPanel
                loaded={!!cmts && tenantScheduleLoaded}
                title_t="deploy.rxmer" lastAction_t="deploy.last_rxmer_scan" nextAction_t="deploy.next_rxmer_scan"
                globalScheduleRate_s={downstream ? dsRxmer?.schedule_rate_s : usRxmer?.schedule_rate_s}
                scheduleInfo={downstream ? cmts?.ds_rxmer_schedule : cmts?.us_rxmer_schedule}
                showTrace={false} // TODO Remove this when request rxmer does traces
                traces={requestRxMerTraces?.results}
            />
            <TimeRemainingPanel
                loaded={!!cmts && tenantScheduleLoaded}
                title_t="deploy.profile_calc" lastAction_t="deploy.last_profile_calc"
                nextAction_t="deploy.next_profile_calc"
                globalScheduleRate_s={downstream ? dsProfileCalc?.schedule_rate_s : usProfileCalc?.schedule_rate_s}
                scheduleInfo={downstream ? cmts?.ds_profile_calc_schedule : cmts?.us_profile_calc_schedule}
                showTrace={false} // TODO Remove this when calc profiles does traces
                traces={calculateProfilesTraces?.results}
            />
            {/*<TimeRemainingPanel*/}
            {/*    loaded={!!cmtsInfo}*/}
            {/*    title_t="deploy.profile_deployment" lastAction_t="deploy.last_profile_deploy"*/}
            {/*    nextAction_t="deploy.next_profile_deploy"*/}
            {/*    globalScheduleRate_s={0}*/}
            {/*    scheduleInfo={downstream ? cmtsInfo?.ds_profile : cmtsInfo?.us_profile}*/}
            {/*    traces={deployProfileTraces?.results}*/}
            {/*/>*/}
        </Grid2>
    )
}
