import {createContext, useContext, useMemo, useState, useEffect} from "react";
import { Tenant} from "../Api/Types/Config";
import { ApiRoutes } from "../Api/ApiRoutes";
import {useAuthContext} from "../Authentication/AuthContext";
import { ApiGet } from "../Api/Util";

type Props = {
    children: JSX.Element[] | JSX.Element
}

export type UserContextType = {
    tenant: Tenant
}

const defaultUserContext = {
    tenant: {
        id: 0n,
        name: "",
        preferred_stream: "both"
    }
}

export const UserContext = createContext<UserContextType>(
    defaultUserContext
);

export function UserContextProvider({children}: Props): JSX.Element {
    const {userToken} = useAuthContext();

    const [tenant, setTenant] = useState<Tenant>({
        id: 0n,
        name: "",
        preferred_stream: "both"
    });

    useEffect(() => {
        if (!userToken) return;

        ApiGet<Tenant>(ApiRoutes.config.tenant.single(1))
            .then(async (response: Tenant) => {
                setTenant(response);
            })
            .catch((error) => {
                //TODO
            });
    }, [userToken])

    const ctx = useMemo<UserContextType>(() => ({tenant:tenant}), [tenant])

    return <UserContext.Provider value={ctx}>{children}</UserContext.Provider>
}

export function useUserContext(): UserContextType{
    return useContext(UserContext);
}
