import { useState, useEffect, useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ChannelContext } from "./OperationalCM";

export function SummaryTypeSelector(): JSX.Element {
  const [summaryTypeSelector, setSummaryTypeSelector] = useState("24hr");

  const { setSummaryType, searchParams, preferredStream } = useContext(ChannelContext);

  const handleSummaryTypeChange = (event: SelectChangeEvent): void => {
    setSummaryTypeSelector(event.target.value);
    const summaryTypeObject = {time: event.target.value};

    setSummaryType(summaryTypeObject);
  };

  useEffect(() => {
    const summaryTypeParam: string | null = searchParams?.get("summarytype");

    if(summaryTypeParam !== "" && summaryTypeParam !== null){
      setSummaryTypeSelector(summaryTypeParam);
    }
  }, [])

  return (
    <Grid lg={preferredStream !== "both" ? 3 : 4} sx={{ padding: 0 }}>
      <FormControl
        sx={{ m: 1, minWidth: 120 }}
        size="small"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Typography
          sx={{
            alignSelf: "start",
            marginRight: "2px",
            border: "1px solid #bcbcbc",
            padding: "7px",
            borderRadius: "3px",
            color: "#3065D8",
            fontWeight: "600",
          }}
        >
          Absolute Time Range
        </Typography>
        <Select
          value={summaryTypeSelector}
          onChange={handleSummaryTypeChange}
          displayEmpty
          defaultValue="10"
          label="Summary Type Selector"
          inputProps={{ "aria-label": "Summary Type Selector" }}
          sx={{
            width: "100%",
            flex: 1,
            overflow: "hidden",
            "& .MuiSvgIcon-root": {
              color: (theme) => theme.palette.secondary.main,
            },
            "& .MuiOutlinedInput-notchedOutline span": {
              display: "none",
            },
          }}
        >
          <MenuItem value="10min">Last 10 min</MenuItem>
          <MenuItem value="24hr">Last 24 hours</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}

