import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiRoutes } from "../../../Api/ApiRoutes";
import { CmtsUsOfdmaChan } from "../../../Api/Types/Docis31";
import { ListApiResponse } from "../../../Api/Types/General";
import { ApiGet } from "../../../Api/Util";
import { useFeedbackContext } from "../../../Feedback/FeedbackContext";
import { useChannelDeploymentContext } from "../DeploymentContext";
import { ConstructCbr8UsInstructions } from "./InstructionConstructors/Cbr8Us";
import { ManualDeploymentInstructionsDetail } from "./ManualProfileDeployment";

export function Cbr8UsManualProfileDeployment(): JSX.Element {
  const { t } = useTranslation();
  const t_no_escape = function (
    key: string,
    options: { [p: string]: any } = {},
  ): string {
    options = Object.assign({ interpolation: { escapeValue: false } }, options);
    return t(key, options);
  };

  const { feedbackError } = useFeedbackContext();

  const { cmts, macDomain, usChannel, pmaUsResult } =
    useChannelDeploymentContext();

  const [cmtsUsOfdmaChan, setCmtsUsOfdmaChan] =
    useState<CmtsUsOfdmaChan | null>(null);

  // CBR8 Config form state
  const [controllerUsChannelId, setControllerUsChannelId] = useState<string>(
    t("deploy.cbr8_config.controller_us_channel_id_default"),
  );
  const [includeSafetyChecks, setIncludeSafetyChecks] = useState<boolean>(true);
  const [includePmaUsResult, setIncludePmaUsResult] = useState<boolean>(false);

  const instructions = useMemo((): string => {
    return ConstructCbr8UsInstructions(
      t_no_escape,
      cmts,
      macDomain,
      usChannel,
      pmaUsResult,
      cmtsUsOfdmaChan,
      controllerUsChannelId,
      includeSafetyChecks,
      includePmaUsResult,
    );
  }, [
    t_no_escape,
    cmts,
    macDomain,
    usChannel,
    pmaUsResult,
    cmtsUsOfdmaChan,
    controllerUsChannelId,
    includeSafetyChecks,
    includePmaUsResult,
  ]);

  useEffect(() => {
    if (cmts === null || usChannel === null || pmaUsResult === null) {
      return;
    }

    ApiGet<ListApiResponse<CmtsUsOfdmaChan>>(
      ApiRoutes.topology.docsis31.docsIf31CmtsUsOfdmaChan.filter({
        captureId: pmaUsResult?.topology_capture,
        ifIndex: usChannel?.ifIndex,
      }),
    )
      .then(async (response: ListApiResponse<CmtsUsOfdmaChan>) => {
        if (response.results.length === 1) {
          setCmtsUsOfdmaChan(response.results[0]);
        }
      })
      .catch((error) => {
        console.log({
          route: "ApiRoutes.topology.docsis31.docsIf31CmtsUsOfdmaChan",
          captureId: pmaUsResult?.topology_capture,
          ifIndex: usChannel?.ifIndex,
          error: error,
        });
        feedbackError(
          t("deploy.failed_fetching_CmtsUsOfdmaChan", {
            message: error.message,
          }),
        );
      });
  }, [pmaUsResult, usChannel]);

  return (
    <ManualDeploymentInstructionsDetail instructions={instructions}>
      <Typography
        variant={"h6"}
        color={(theme): any => theme.palette.primary.main}
      >
        {t("deploy.cbr8_config.upstream_config_header")}
      </Typography>
      <FormGroup sx={{ marginBottom: "25px" }}>
        <TextField
          sx={{ marginTop: "25px" }}
          label={t_no_escape(
            "deploy.cbr8_config.controller_us_channel_id_label",
            {
              channel_name: usChannel?.ifDescr,
            },
          )}
          variant="outlined"
          defaultValue={t(
            "deploy.cbr8_config.controller_us_channel_id_default",
          )}
          onChange={(e): void => {
            setControllerUsChannelId(e.target.value);
          }}
          helperText={t("deploy.cbr8_config.controller_us_channel_id_note")}
        />
        <FormControl sx={{ marginTop: "15px", alignItems: "start" }}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={(e): void => {
                  setIncludeSafetyChecks(e.target.checked);
                }}
                defaultChecked={includeSafetyChecks}
              />
            }
            label={t("deploy.include_safety_checks")}
            labelPlacement="start"
          ></FormControlLabel>
        </FormControl>
        <FormControl sx={{ marginTop: "15px", alignItems: "start" }}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={(e): void => {
                  setIncludePmaUsResult(e.target.checked);
                }}
                defaultChecked={includePmaUsResult}
              />
            }
            label={t("deploy.include_pma_result")}
            labelPlacement="start"
          ></FormControlLabel>
        </FormControl>
      </FormGroup>
    </ManualDeploymentInstructionsDetail>
  );
}
