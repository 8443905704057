import {createContext, useContext} from "react";
import {PmaDsResult, PmaUsResult} from "../../Api/Types/CapacityBooster";
import {DsChannel, MacDomain, UsChannel} from "../../Api/Types/Topology";
import {Cmts} from "../../Api/Types/Config";


export type ChannelDeploymentType = {
    cmts: Cmts | null;
    macDomain: MacDomain | null;
    dsChannel: DsChannel | null;
    usChannel: UsChannel | null;
    pmaDsResult: PmaDsResult | null;
    pmaUsResult: PmaUsResult | null;

    pageState: PageState
}

type PageState = {
    actionsEnabled: boolean;
    setActionsEnabled: (actionsEnabled: boolean) => void;
}

export const DefaultChannelDeploymentContext: ChannelDeploymentType = {
    cmts: null,
    macDomain: null,
    dsChannel: null,
    usChannel: null,
    pmaDsResult: null,
    pmaUsResult: null,
    pageState: {
        actionsEnabled: false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setActionsEnabled: (actionsEnabled: boolean) => {},
    },
}

export const ChannelDeploymentContext = createContext<ChannelDeploymentType>(DefaultChannelDeploymentContext);

export function useChannelDeploymentContext(): ChannelDeploymentType {
    return useContext(ChannelDeploymentContext);
}
