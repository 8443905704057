import {ChannelActivityType} from "../Api/Types/CapacityBooster";
import {useEffect, useState} from "react";
import {Cmts} from "../Api/Types/Config";
import {ApiRoutes} from "../Api/ApiRoutes";
import {ApiGet} from "../Api/Util";
import {FeedbackContextProvider, useFeedbackContext} from "../Feedback/FeedbackContext";
import {useTranslation} from "react-i18next";
import {ManualProfileDeployment} from "./Channel/ManualDeployment/ManualProfileDeployment";

export type ChannelActivityManualDeploymentProps = {
  selectedChannel: ChannelActivityType;
};

export const ChannelActivityManualDeploymentContent = ({selectedChannel}: ChannelActivityManualDeploymentProps): JSX.Element => {
  const { feedbackError } = useFeedbackContext();
  const { t } = useTranslation()
  const [cmtsInfo, setCmtsInfo] = useState<Cmts | null>(null);

  useEffect(() => {
    ApiGet<Cmts>(ApiRoutes.config.cmts.single(Number(selectedChannel.cmts)))
      .then(result => {
        setCmtsInfo(result);
      })
      .catch(error => {
        console.log({
          route: 'ApiRoutes.config.cmts',
          cmtsIdNum: Number(selectedChannel.cmts),
          error: error
        });
        feedbackError(t('deploy.failed_fetching_Cmts', { message: error.message }));
      });
  }, [selectedChannel]);

  return (
    selectedChannel.channel_direction === 'downstream' ?
      <ManualProfileDeployment cmtsInfo={cmtsInfo} downstream={true} title={t("channel_activity.trial_deployment")}/> :
      <ManualProfileDeployment cmtsInfo={cmtsInfo} downstream={false} title={t("channel_activity.trial_deployment")}/>
  )
}

export const ChannelActivityManualDeployment = ({selectedChannel}: ChannelActivityManualDeploymentProps): JSX.Element => {
  return (
    <FeedbackContextProvider>
      <ChannelActivityManualDeploymentContent selectedChannel={selectedChannel} />
    </FeedbackContextProvider>
  )
}
