import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthContext } from './AuthContext';
import { useCookies } from 'react-cookie';

const ProtectedRoute = () => {
  const { userToken } = useAuthContext();
  const location = useLocation();
  const [cookies] = useCookies(['userTokenExpiration']);

  const userTokenExpiration = new Date(cookies.userTokenExpiration);
  const now = new Date();

  if (userToken == null || userToken === "" || now > userTokenExpiration) {
    return (
      <Navigate
        to={'/login'}
        replace
        state={{
          redirectTo: location
        }}
      />
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
