import { NavRoutes } from "./NavRoutes";
import React, {
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Collapse,
} from "@mui/material";
import { Link, useLocation, useMatch, useResolvedPath } from "react-router-dom";
import { IoSettingsSharp } from "react-icons/io5";
import { IconContext } from "react-icons";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { MdBadge, MdCircle } from "react-icons/md";
import DisplaySettingsRoundedIcon from '@mui/icons-material/DisplaySettingsRounded';
import AltRouteRoundedIcon from '@mui/icons-material/AltRouteRounded';
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {useAuthContext} from "../Authentication/AuthContext";


export function HomeNavIcon(): JSX.Element {
  return <DashboardRoundedIcon sx={{fontSize: "32px"}}/>;
}

export function OperationsIcon(): JSX.Element {
  return <DisplaySettingsRoundedIcon sx={{fontSize: "32px"}} />;
}

export function ProfileManagementIcon(): JSX.Element {
  return <AltRouteRoundedIcon sx={{fontSize: "32px"}} />;
}

export function SystemConfigurationIcon(): JSX.Element {
  return <EngineeringRoundedIcon sx={{fontSize: "32px"}} />;
}

function ReportsNavIcon(): JSX.Element {
  return <AssessmentOutlinedIcon sx={{fontSize: "32px"}} />;
}

// function CareNavIcon(): JSX.Element {
//     const resolvedPath = useResolvedPath(NavRoutes.care);
//     const active = useMatch({path: resolvedPath.pathname, end: false}) && true || false;
//
//     return <>{
//         active ?
//             <img src={"/icons/active_care.svg"} alt="CMTS Deployment" height="32px" width="32px" /> :
//             <img src={"/icons/inactive_care.svg"} alt="CMTS Deployment" height="32px" width="32px" />
//     }</>
// }

export type NavMenuItemProps = {
  icon?: () => JSX.Element;
  expandedText: string;
  secondaryText?: string;
  smallText?: string;
  route: string;
  expanded: boolean;
  submenu?: boolean;
  onclick: () => void;
};

export type NavMenuCollapseProps = {
  icon: () => JSX.Element;
  expandedText: string;
  secondaryText?: string;
  smallText?: string;
  active: boolean;
  expanded: boolean;
  dropdownStatus: DropdownOpen;
  state: DropdownOpen;
  setState: Dispatch<SetStateAction<DropdownOpen>>;
  children: JSX.Element;
};

const ButtonLink = styled(Link)({
  textDecoration: "none",
});

enum DropdownOpen {
  OPERATIONS = "operations",
  PROFILES = "profiles",
  SYSCONFIG = "sysconfig",
  NONE = "none"
}

export function NavMenuItem(props: NavMenuItemProps): JSX.Element {
  const {
    icon,
    expandedText,
    smallText,
    secondaryText,
    route,
    expanded,
    submenu,
  } = props;

  const usedSmallText = smallText ? smallText : expandedText;

  const resolvedPath = useResolvedPath(route);
  const active =
    (useMatch({ path: resolvedPath.pathname, end: true }) && true) || false;

  const theme = useTheme();
  const textColor = useMemo(() => {
    return active ? "rgba(254, 254, 254, 1)" : "rgba(254, 254, 254, 0.6)";
  }, [active, theme]);

  const iconStyle = useMemo(() => {
    return {
      px: "4px",
      py: "4px",
      mr: !expanded ? "16px" : 0,
      gap: "8px",
      borderTopLeftRadius: "6px",
      borderBottomLeftRadius: "6px",
      borderTopRightRadius: expanded ? 0 : "6px",
      borderBottomRightRadius: expanded ? 0 : "6px",
      transition: theme.transitions.create(
        [
          "border-top-right-radius",
          "border-bottom-right-radius",
          "margin-right",
          "min-width",
          "height",
        ],
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }
      ),
      width: "32px",
      minWidth: !expanded ? "56px" : "56px",
      display: "block",
      background:
        active && !submenu
          ? theme.palette.secondary.main
          : submenu && active
          ? theme.palette.primary.light
          : submenu && !active
          ? theme.palette.primary.dark
          : theme.palette.primary.main,
      color: textColor,
      overflow: "hidden",
    };
  }, [active, theme, textColor, expanded]);

  const iconTextStyle = useMemo(() => {
    const expandedColor = active
      ? theme.palette.secondary.main
      : theme.palette.primary.main;
    return {
      color: expanded ? expandedColor : textColor,
      transition: theme.transitions.create(["color", "height", "display"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      height: expanded ? 0 : "21px",
      textAlign: "center",
      fontFamily: "Encode Sans Condensed",
      letterSpacing: ".02rem"
    };
  }, [active, theme, textColor, expanded]);

  const listItemStyle = useMemo(() => {
    return {
      px: "5px",
      pb: "8px",
      textAlign: expanded ? "left" : "center",
      background:
        active && !submenu
          ? theme.palette.secondary.main
          : submenu && active
          ? theme.palette.primary.light
          : submenu && !active
          ? theme.palette.primary.dark
          : theme.palette.primary.main,
      transition: theme.transitions.create(["height", "padding-bottom"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    };
  }, [active, theme, textColor, expanded]);

  const textStyle = useMemo(() => {
    return {
      px: "4px",
      py: "8px",
      my: 0,
      gap: "8px",
      // letterSpacing: ".02rem",
      borderTopLeftRadius: expanded ? 0 : "6px",
      borderBottomLeftRadius: expanded ? 0 : "6px",
      borderTopRightRadius: "6px",
      borderBottomRightRadius: "6px",
      transition: theme.transitions.create(
        ["border-top-left-radius", "border-bottom-left-radius", "height"],
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }
      ),
      width: "100%",
      height: "40px",
      background:
        active && !submenu
          ? theme.palette.secondary.main
          : submenu && active
          ? theme.palette.primary.light
          : submenu && !active
          ? theme.palette.primary.dark
          : theme.palette.primary.main,
      color: active && submenu ? "#FEFEFE" : textColor,
    };
  }, [active, theme, textColor, expanded]);

  return (
    <ButtonLink to={route} onClick={props.onclick}>
      <ListItem sx={listItemStyle}>
        <ListItemIcon sx={iconStyle}>
          {icon ? (
            <Typography
              sx={{ color: textColor, ml: "8px", mr: "8px" }}
              height="32px"
            >
              {icon()}
            </Typography>
          ) : (
            <Typography
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ color: textColor, ml: "8px", mr: "8px" }}
              height="32px"
            >
              <MdCircle
                size="10px"
                style={
                  active
                    ? { color: "#FEFEFE" }
                    : { color: textColor }
                }
              />
            </Typography>
          )}
          <Typography variant="subtitle1" sx={iconTextStyle}>
            {usedSmallText}
          </Typography>
        </ListItemIcon>
        <ListItemText
          primary={expandedText}
          secondary={secondaryText}
          sx={textStyle}
          primaryTypographyProps={{
            fontSize: ".85rem",
            fontFamily: "Encode Sans",
            fontWeight: !submenu ? "600" : "",
            letterSpacing: ".02rem"
          }}
        />
      </ListItem>
    </ButtonLink>
  );
}

export function NavMenuCollapse(props: NavMenuCollapseProps): JSX.Element {
  const {
    icon,
    expandedText,
    smallText,
    secondaryText,
    expanded,
    dropdownStatus,
    state,
    setState,
    children,
    active,
  } = props;
  const usedSmallText = smallText ? smallText : expandedText;

  const theme = useTheme();
  const textColor = useMemo(() => {
    return active ? "rgba(254, 254, 254, 1)" : "rgba(254, 254, 254, 0.6)";
  }, [active, theme]);

  const iconStyle = useMemo(() => {
    return {
      px: "4px",
      py: "4px",
      mr: !expanded ? "16px" : 0,
      gap: "8px",
      borderTopLeftRadius: "6px",
      borderBottomLeftRadius: "6px",
      borderTopRightRadius: expanded ? 0 : "6px",
      borderBottomRightRadius: expanded ? 0 : "6px",
      transition: theme.transitions.create(
        [
          "border-top-right-radius",
          "border-bottom-right-radius",
          "margin-right",
          "min-width",
          "height",
        ],
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }
      ),
      width: "32px",
      minWidth: !expanded ? "56px" : "56px",
      display: "block",
      background: active
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
      color: textColor,
      overflow: "hidden",
    };
  }, [active, theme, textColor, expanded]);

  const iconTextStyle = useMemo(() => {
    const expandedColor = active
      ? theme.palette.secondary.main
      : theme.palette.primary.main;
    return {
      color: expanded ? expandedColor : textColor,
      transition: theme.transitions.create(["color", "height", "display"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      height: expanded ? 0 : "21px",
      textAlign: "center",
      fontFamily: "Encode Sans Condensed",
      // letterSpacing: ".02rem"
    };
  }, [active, theme, textColor, expanded]);

  const listItemStyle = useMemo(() => {
    return {
      px: "5px",
      pb: expanded ? "20px" : "8px",
      cursor: "pointer",
      textAlign: expanded ? "left" : "center",
      background: active
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
      transition: theme.transitions.create(["height", "padding-bottom"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    };
  }, [active, theme, textColor, expanded]);

  const textStyle = useMemo(() => {
    return {
      px: "4px",
      py: "8px",
      my: 0,
      gap: "8px",
      borderTopLeftRadius: expanded ? 0 : "6px",
      borderBottomLeftRadius: expanded ? 0 : "6px",
      borderTopRightRadius: "6px",
      borderBottomRightRadius: "6px",
      transition: theme.transitions.create(
        ["border-top-left-radius", "border-bottom-left-radius", "height"],
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }
      ),
      width: "100%",
      height: "40px",
      background: active
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
      color: textColor,
    };
  }, [active, theme, textColor, expanded]);

  const handleClick = () => {
    if(state === dropdownStatus){
      setState(DropdownOpen.NONE)
    }else{
      setState(dropdownStatus);
    }
  }

  return (
    <>
      <ListItem sx={listItemStyle} onClick={handleClick}>
        <ListItemIcon sx={iconStyle}>
          <Typography
            sx={{ color: textColor, ml: "8px", mr: "8px" }}
            height="32px"
          >
            {icon()}
          </Typography>
          <Typography variant="subtitle1" sx={iconTextStyle}>
            {usedSmallText}
          </Typography>
        </ListItemIcon>
        <ListItemText
          primary={expandedText}
          secondary={secondaryText}
          sx={textStyle}
          primaryTypographyProps={{
            fontSize: ".85rem",
            fontFamily: "Encode Sans",
            fontWeight: "600",
            letterSpacing: ".02rem"
          }}
        />
        {state === dropdownStatus ? (
            <ExpandLess sx={{color: textColor}} onClick={handleClick}/>
          ) : (
            <ExpandMore sx={{color: textColor}} onClick={handleClick}/>
          )}
      </ListItem>
      <Collapse
        in={state === dropdownStatus}
        timeout="auto"
        unmountOnExit
        sx={{ backgroundColor: theme.palette.primary.light }}
      >
        {expanded && children}
      </Collapse>
    </>
  );
}

export function MainMenu({ expanded }: { expanded: boolean }): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const {userInfo} = useAuthContext();

  const [dropdown, setDropdown] = useState<DropdownOpen>(DropdownOpen.NONE);
  const [dropdownActive, setDropdownActive] = useState<DropdownOpen>(DropdownOpen.NONE);

  const resolvedTechSupervisor = useResolvedPath(
    NavRoutes.tech_supervisor
  ).pathname;
  const resolvedChannelView = useResolvedPath(NavRoutes.channel_view).pathname;
  const resolvedDeployment = useResolvedPath(
    NavRoutes.deployment.home
  ).pathname;
  const resolvedConfig = useResolvedPath(NavRoutes.config).pathname;

  //For when the page is refreshed...
  useEffect(() => {
    if (location.pathname.includes(resolvedTechSupervisor)) {
      handleOperationsClick();
    } else if (
      location.pathname.includes(resolvedChannelView) ||
      location.pathname.includes(resolvedDeployment)
    ) {
      handleProfileClick();
    } else if (location.pathname.includes(resolvedConfig)) {
      handleSysConfigClick();
    } else {
      handleNormalClick();
    }
  }, []);

  const handleOperationsClick = () => {
    setDropdownActive(DropdownOpen.OPERATIONS);
  };

  const handleProfileClick = () => {
    setDropdownActive(DropdownOpen.PROFILES);
  };

  const handleSysConfigClick = () => {
    setDropdownActive(DropdownOpen.SYSCONFIG);
  };

  const handleNormalClick = () => {
    setDropdownActive(DropdownOpen.NONE);
  };

  return (
    <List component="nav">
      <IconContext.Provider value={{ size: "32px" }}>
        <NavMenuItem
          icon={HomeNavIcon}
          expandedText={t("nav.overview")}
          route={NavRoutes.home}
          expanded={expanded}
          onclick={handleNormalClick}
        />
        <NavMenuCollapse
          icon={OperationsIcon}
          expandedText={t("nav.operations")}
          expanded={expanded}
          dropdownStatus={DropdownOpen.OPERATIONS}
          state={dropdown}
          setState={setDropdown}
          active={dropdownActive === DropdownOpen.OPERATIONS}
        >
          <NavMenuItem
            submenu={true}
            expandedText={t("nav.tech_operations")}
            smallText={t("nav.ts")}
            route={NavRoutes.tech_supervisor}
            expanded={expanded}
            onclick={handleOperationsClick}
          />
        </NavMenuCollapse>
        <NavMenuCollapse
          icon={ProfileManagementIcon}
          expandedText={t("nav.profile_management")}
          smallText={t("nav.prof_mgmt")}
          expanded={expanded}
          dropdownStatus={DropdownOpen.PROFILES}
          state={dropdown}
          setState={setDropdown}
          active={dropdownActive === DropdownOpen.PROFILES}
        >
          <>
            <NavMenuItem
              submenu={true}
              expandedText={t("nav.channel_view")}
              route={NavRoutes.channel_view}
              expanded={expanded}
              onclick={handleProfileClick}
            />
            {userInfo.role === "Admin" && (
              <NavMenuItem
                submenu={true}
                expandedText={t("nav.channel_deployment")}
                route={NavRoutes.deployment.home}
                expanded={expanded}
                onclick={handleProfileClick}
              />
            )}
          </>
        </NavMenuCollapse>
        <NavMenuCollapse
          icon={SystemConfigurationIcon}
          expandedText={t("nav.system_configuration")}
          smallText={t("nav.config")}
          expanded={expanded}
          dropdownStatus={DropdownOpen.SYSCONFIG}
          state={dropdown}
          setState={setDropdown}
          active={dropdownActive === DropdownOpen.SYSCONFIG}
        >
          <>
            <NavMenuItem
              submenu={true}
              expandedText={t("nav.configuration")}
              route={NavRoutes.config}
              expanded={expanded}
              onclick={handleSysConfigClick}
            />
            {userInfo.role === "Admin" && (
              <NavMenuItem
                submenu={true}
                expandedText={t("nav.manage_users")}
                route={NavRoutes.manage_users}
                expanded={expanded}
                onclick={handleSysConfigClick}
              />
            )}
          </>
        </NavMenuCollapse>
        <NavMenuItem
          icon={ReportsNavIcon}
          expandedText={t("nav.reports")}
          route={NavRoutes.reports}
          expanded={expanded}
          onclick={handleNormalClick}
        />
        {/*<NavMenuItem icon={DataNavIcon} primary_text="Data" route={NavRoutes.data} expanded={expanded} />*/}

        {/*<NavMenuItem icon={CareNavIcon} expandedText={t('nav.cmts_care')} smallText={t('nav.care')}*/}
        {/*             route={NavRoutes.care}*/}
        {/*             expanded={expanded} />*/}
      </IconContext.Provider>
    </List>
  );
}
