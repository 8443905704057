import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ManualDeploymentInstructionsDetail} from "./ManualProfileDeployment";

export function UnknownCmtsManualProfileDeployment({cmtsType}: { cmtsType?: string | null }): JSX.Element {
  const {t} = useTranslation();

  return <ManualDeploymentInstructionsDetail>
    <Typography variant={"h5"}>
      {
        cmtsType ?
        t("deploy.unknown_cmts_type_given", {cmtsType: cmtsType}) :
        t("deploy.unknown_cmts_type_not_given")
      }
    </Typography>
  </ManualDeploymentInstructionsDetail>;
}
