import { ChannelActivityType, PmaDsResult, PmaUsResult } from '../Api/Types/CapacityBooster';
import React, { useEffect, useState } from 'react';
import { ApiGet } from '../Api/Util';
import { ApiRoutes } from '../Api/ApiRoutes';
import { Cmts } from '../Api/Types/Config';
import { GrafanaPanel } from '../Components/GrafanaPanel';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { NavRoutes } from '../Navigation/NavRoutes';
import {useFeedbackContext} from "../Feedback/FeedbackContext";

const dsPanelId = {
  existingProfiles: 6,
  boostedProfiles: 7
};

const usPanelId = {
  existingProfiles: 6,
  boostedProfiles: 7
};

export function ChannelInformationExpanded({
  row: channel
}: {
  row: ChannelActivityType;
}): JSX.Element {
  const { t } = useTranslation();
  const { setFeedbackState } = useFeedbackContext();
  const [cmtsData, setCmtsData] = useState<Cmts>();
  const [loading, setLoading] = useState<boolean>(false);

  const dsUrl = '/grafana/d-solo/zCgyNO0Vk/operational-deploy-downstream-channel';
  const usUrl = '/grafana/d-solo/bZdfpsuVz/operational-deploy-upstream-channel';
  const dashboardUrl = channel.channel_direction === 'downstream' ? dsUrl : usUrl;

  const panelId1 =
    channel.channel_direction === 'downstream'
      ? dsPanelId.existingProfiles
      : usPanelId.existingProfiles;
  const panelId2 =
    channel.channel_direction === 'downstream'
      ? dsPanelId.boostedProfiles
      : usPanelId.boostedProfiles;

  useEffect(() => {
    setLoading(true);
    ApiGet<Cmts>(ApiRoutes.config.cmts.single(channel.cmts)).then(async (response: Cmts) => {
      setCmtsData(response);
      setLoading(false);
    }).catch(error => {
      setFeedbackState({
        open: true,
        message: t('channel_activity.fail_get_cmts_data'),
        severity: 'error'
      });
    });
  }, [channel]);

  const extraParams = {
    'var-cmts': cmtsData?.name,
    'var-mac_dom': channel?.mac_domain_ifdescr,
    [channel.channel_direction === 'downstream' ? 'var-ds_chan' : 'var-us_chan']:
      channel?.channel_ifdescr,
    from: 'now-24h',
    to: 'now'
  };

  const channelViewUrlParams = `?cmts=${channel.cmts}&macdomain=${channel.mac_domain_ifindex}&dschannel=${channel.channel_ifindex}`;
  const channelViewUrl = `${NavRoutes.channel_view}${channelViewUrlParams}`;

  return (
    <>
      {!loading ? (
        <Grid sx={{ backgroundColor: 'rgba(202, 214, 228, 1)' }}>
          <Grid xs={12} display="flex" alignContent="center" gap={1} marginLeft="5px">
            <InfoOutlinedIcon sx={{ color: 'rgba(51, 51, 51, 1)' }} />
            {/*  Will put back in once the link is fixed */}
            {/*{channel.channel_direction === 'downstream' ? (*/}
            {/*  <Typography fontSize="14px" color="rgba(51, 51, 51, 1)" lineHeight={2}>*/}
            {/*    {t('channel_activity.expanded_information')}*/}
            {/*    <Link to={channelViewUrl}>{t('channel_activity.expanded_information_extra')}</Link>*/}
            {/*  </Typography>*/}
            {/*) : (*/}
            {/*  <Typography fontSize="14px" color="rgba(51, 51, 51, 1)" lineHeight={2}>*/}
            {/*    {t('channel_activity.expanded_information_no_link')}*/}
            {/*  </Typography>*/}
            {/*)}*/}
            <Typography fontSize="14px" color="rgba(51, 51, 51, 1)" lineHeight={2}>
              {t('channel_activity.expanded_information_no_link')}
            </Typography>
          </Grid>
          <Grid xs={12} display="flex">
            <Grid xs={12} md={6}>
              <GrafanaPanel
                url={dashboardUrl}
                panelId={panelId1}
                extraParams={extraParams}
                width="100%"
                height="300px"
              />
            </Grid>
            <Grid xs={12} md={6}>
              <GrafanaPanel
                url={dashboardUrl}
                panelId={panelId2}
                extraParams={extraParams}
                width="100%"
                height="300px"
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
