import {Skeleton, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ManualDeploymentInstructionsDetail} from "./ManualProfileDeployment";

export function DataLoadingManualProfileDeployment(): JSX.Element {
  const {t} = useTranslation();
  return <ManualDeploymentInstructionsDetail>
    <Typography variant={"h5"}>
      {t("deploy.loading")}
    </Typography>
    <Skeleton height={30} animation="wave" sx={{bgcolor: "grey.300"}} />
    <Skeleton height={30} animation="wave" sx={{bgcolor: "grey.300"}} />
    <Skeleton height={30} animation="wave" sx={{bgcolor: "grey.300"}} />
  </ManualDeploymentInstructionsDetail>;
}
