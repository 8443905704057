import {Cmts} from "../../Api/Types/Config";
import {
    IsValidHostname,
    IsValidIPv4,
    IsValidJsonString,
    IsValidPort,
    IsValidTime,
    RequireField
} from "../../Feedback/ValidationUtils";


export function validateCmts(t: any, values: Cmts, downstreamRxmer?: boolean): object {
    const errors: any = {};

    RequireField(t, errors, values.name, "name");
    RequireField(t, errors, values.hostname, "hostname");
    RequireField(t, errors, values.cmts_port, "cmts_port");
    RequireField(t, errors, values.cmts_community_string, "cmts_community_string");
    RequireField(t, errors, values.cm_port, "cm_port");

    IsValidHostname(t, errors, values.hostname, "hostname");

    IsValidPort(t, errors, values.cmts_port, "cmts_port");
    IsValidPort(t, errors, values.cm_port, "cm_port");
    IsValidJsonString(t, errors, values.kafka_extra_config, "kafka_extra_config");


    if (downstreamRxmer) {
        RequireField(t, errors, values.cm_community_string, "cm_community_string");
    }

    if (values.topology_schedule?.schedule_rate_s) {
        IsValidTime(t, errors, values.topology_schedule.schedule_rate_s, ["topology_schedule", "schedule_rate_s"]);
    }

    if (values.ds_rxmer_schedule?.schedule_rate_s) {
        IsValidTime(t, errors, values.ds_rxmer_schedule.schedule_rate_s, ["ds_rxmer_schedule", "schedule_rate_s"]);
    }

    if (values.us_rxmer_schedule?.schedule_rate_s) {
        IsValidTime(t, errors, values.us_rxmer_schedule.schedule_rate_s, ["us_rxmer_schedule", "schedule_rate_s"]);
    }

    if (values.ds_fec_schedule?.schedule_rate_s) {
        IsValidTime(t, errors, values.ds_fec_schedule.schedule_rate_s, ["ds_fec_schedule", "schedule_rate_s"]);
    }

    if (values.us_fec_schedule?.schedule_rate_s) {
        IsValidTime(t, errors, values.us_fec_schedule.schedule_rate_s, ["us_fec_schedule", "schedule_rate_s"]);
    }

    if (values.ds_profile_calc_schedule?.schedule_rate_s) {
        IsValidTime(t, errors, values.ds_profile_calc_schedule.schedule_rate_s, ["ds_profile_calc_schedule", "schedule_rate_s"]);
    }

    if (values.us_profile_calc_schedule?.schedule_rate_s) {
        IsValidTime(t, errors, values.us_profile_calc_schedule.schedule_rate_s, ["us_profile_calc_schedule", "schedule_rate_s"]);
    }

    if (values.pnm_server_ipv4_addr) {
        IsValidIPv4(t, errors, values.pnm_server_ipv4_addr, "pnm_server_ipv4_addr");
    }

    return errors;
}
