import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiRoutes } from "../../../Api/ApiRoutes";
import { CmtsDsOfdmChan } from "../../../Api/Types/Docis31";
import { ListApiResponse } from "../../../Api/Types/General";
import { useFeedbackContext } from "../../../Feedback/FeedbackContext";
import { useChannelDeploymentContext } from "../DeploymentContext";
import { ConstructCbr8DsInstructions } from "./InstructionConstructors/Cbr8Ds";
import { ManualDeploymentInstructionsDetail } from "./ManualProfileDeployment";
import { ApiGet } from "../../../Api/Util";

export function Cbr8DsManualProfileDeployment(): JSX.Element {
  const { t } = useTranslation();
  const t_no_escape = function (
    key: string,
    options: { [p: string]: any } = {},
  ): string {
    options = Object.assign({ interpolation: { escapeValue: false } }, options);
    return t(key, options);
  };

  const { feedbackError } = useFeedbackContext();

  const { cmts, macDomain, dsChannel, pmaDsResult } =
    useChannelDeploymentContext();

  const [cmtsDsOfdmChan, setCmtsDsOfdmChan] = useState<CmtsDsOfdmChan | null>(
    null,
  );

  // CBR8 Config form state
  const [firstNewOfdmModProfId, setFirstNewOfdmModProfId] = useState<string>(
    t("deploy.cbr8_config.first_new_ofdm_mod_prof_id_default"),
  );
  const [ofdmChanProfId, setOfdmChanProfId] = useState<string>(
    t("deploy.cbr8_config.ofdm_chan_prof_id_default"),
  );
  const [hitless, setHitless] =
    useState<boolean>(true);
  const [includeSafetyChecks, setIncludeSafetyChecks] = useState<boolean>(true);
  const [includePmaDsResult, setIncludePmaDsResult] = useState<boolean>(false);

  const instructions = useMemo((): string => {
    return ConstructCbr8DsInstructions(
      t_no_escape,
      cmts,
      macDomain,
      dsChannel,
      pmaDsResult,
      cmtsDsOfdmChan,
      firstNewOfdmModProfId,
      ofdmChanProfId,
      hitless,
      includeSafetyChecks,
      includePmaDsResult,
    );
  }, [
    t_no_escape,
    cmts,
    macDomain,
    dsChannel,
    pmaDsResult,
    cmtsDsOfdmChan,
    firstNewOfdmModProfId,
    ofdmChanProfId,
    hitless,
    includeSafetyChecks,
    includePmaDsResult,
  ]);

  useEffect(() => {
    if (cmts === null || dsChannel === null || pmaDsResult === null) {
      return;
    }

    ApiGet<ListApiResponse<CmtsDsOfdmChan>>(
      ApiRoutes.topology.docsis31.docsIf31CmtsDsOfdmChan.filter({
        captureId: pmaDsResult?.topology_capture,
        ifIndex: dsChannel?.ifIndex,
      }),
    )
      .then(async (response: ListApiResponse<CmtsDsOfdmChan>) => {
        if (response.results.length === 1) {
          setCmtsDsOfdmChan(response.results[0]);
        }
      })
      .catch((error) => {
        console.log({
          route: "ApiRoutes.topology.docsis31.docsIf31CmtsDsOfdmChan",
          captureId: pmaDsResult?.topology_capture,
          ifIndex: dsChannel?.ifIndex,
          error: error,
        });
        feedbackError(
          t("deploy.failed_fetching_CmtsDsOfdmChan", {
            message: error.message,
          }),
        );
      });
  }, [pmaDsResult, dsChannel]);

  return (
    <ManualDeploymentInstructionsDetail instructions={instructions}>
      <Typography
        variant={"h6"}
        color={(theme): any => theme.palette.primary.main}
      >
        {t("deploy.cbr8_config.downstream_config_header")}
      </Typography>
      <FormGroup sx={{ marginBottom: "25px" }}>
        <TextField
          sx={{ marginTop: "25px" }}
          label={t("deploy.cbr8_config.first_new_ofdm_mod_prof_id_label")}
          variant="outlined"
          defaultValue={t(
            "deploy.cbr8_config.first_new_ofdm_mod_prof_id_default",
          )}
          onChange={(e): void => {
            setFirstNewOfdmModProfId(e.target.value);
          }}
          helperText={t("deploy.cbr8_config.first_new_ofdm_mod_prof_id_note")}
        />
        <TextField
          sx={{ marginTop: "25px" }}
          label={t_no_escape("deploy.cbr8_config.ofdm_chan_prof_id_label", {
            channel_name: dsChannel?.ifDescr,
          })}
          defaultValue={t("deploy.cbr8_config.ofdm_chan_prof_id_default")}
          onChange={(e): void => {
            setOfdmChanProfId(e.target.value);
          }}
          helperText={t("deploy.cbr8_config.ofdm_chan_prof_id_note")}
        />

        <FormControl sx={{ marginTop: "15px", alignItems: "start" }}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={(e): void => {
                  setHitless(e.target.checked);
                }}
                defaultChecked={hitless}
                />
              }
              label={t("deploy.cbr8_config.hitless")}
              labelPlacement="start"
          ></FormControlLabel>
          <Typography variant={"caption"} sx={{ marginLeft: "16px" }}>
            {t("deploy.cbr8_config.hitless_note")}
          </Typography>
        </FormControl>

        <FormControl sx={{ marginTop: "15px", alignItems: "start" }}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={(e): void => {
                  setIncludeSafetyChecks(e.target.checked);
                }}
                defaultChecked={includeSafetyChecks}
              />
            }
            label={t("deploy.include_safety_checks")}
            labelPlacement="start"
          ></FormControlLabel>
        </FormControl>

        <FormControl sx={{ marginTop: "15px", alignItems: "start" }}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={(e): void => {
                  setIncludePmaDsResult(e.target.checked);
                }}
                defaultChecked={includePmaDsResult}
              />
            }
            label={t("deploy.include_pma_result")}
            labelPlacement="start"
          ></FormControlLabel>
        </FormControl>
      </FormGroup>
    </ManualDeploymentInstructionsDetail>
  );
}
