import React, { useRef } from 'react';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { ApiRoutes } from '../../Api/ApiRoutes';
import { ApiPost } from '../../Api/Util';
import { useAuthContext } from '../../Authentication/AuthContext';
import { useFeedbackContext } from '../../Feedback/FeedbackContext';
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import axios from 'axios';

export function UploadTemplateButton() {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { userToken } = useAuthContext();
  const { setFeedbackState } = useFeedbackContext();

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files?.length !== 0) {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      const config = {
        headers: {
          Authorization: 'token ' + userToken,
          'content-type': 'multipart/form-data'
        }
      };

      axios
        .post(ApiRoutes.config.templateProfile.import, formData, config)
        .then(response => {
          setFeedbackState({
            open: true,
            message: t('template_config.upload_success'),
            severity: 'success'
          });
        })
        .catch(error => {
          setFeedbackState({
            open: true,
            message: t('template_config.upload_failure'),
            severity: 'error'
          });
        });
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <Box display="flex" mb="20px">
        <PriorityHighIcon fontSize="small" color="warning" />
        <Typography>{t('template_profile.upload_instruction')}</Typography>
      </Box>
      <Box>
        <input
          ref={fileInputRef}
          type="file"
          accept=".jsonl"
          style={{ display: 'none' }}
          onChange={handleUpload}
        />
        <Button onClick={handleClick} variant="contained">
          {t('template_profile.upload')}
        </Button>
      </Box>
    </>
  );
}
