import { GlobalTopologyScheduleConfig } from './GlobalTopologyScheduleConfig';
import { GlobalPnmRequesterScheduleConfig } from './GlobalPnmRequesterScheduleConfig';
import { GlobalProfileCalculationScheduleConfig } from './GlobalProfileCalculationScheduleConfig';
import { GlobalKafkaScheduleConfig } from "./GlobalKafkaScheduleConfig";
import { useEffect } from 'react';
import { ApiRoutes } from '../../Api/ApiRoutes';
import { useTranslation } from 'react-i18next';
import { useFeedbackContext } from '../../Feedback/FeedbackContext';
import { handleTenantScheduleQuery, useGlobalConfigContext } from '../GlobalStateContext';
import {CalcConstraintTemplateProfile, CalcMaxProfilesInfo, TenantSchedule} from '../../Api/Types/Config';
import { ListApiResponse } from '../../Api/Types/General';
import { ApiGet } from '../../Api/Util';
import { handleGlobalCalcMaxProfilesQuery, useGlobalCalcMaxProfilesContext } from '../GlobalCalcMaxProfilesContext';
import { GlobalCalcMaxProfilesConfig } from './GlobalCalcMaxProfilesConfig';
import {handleGlobalTemplateProfilesQuery, useGlobalTemplateProfilesContext} from "../GlobalTemplateProfilesContext";
import {GlobalTemplateProfilesConfig} from "./GlobalTemplateProfilesConfig";

export default function GlobalConfig(): JSX.Element {
  const { t } = useTranslation();
  const { setFeedbackState } = useFeedbackContext();
  const { setTenantScheduleState } = useGlobalConfigContext();
  const { setGlobalCalcMaxProfilesState } = useGlobalCalcMaxProfilesContext();
  const { setGlobalTemplateProfilesState } = useGlobalTemplateProfilesContext();


  useEffect(() => {
    ApiGet<ListApiResponse<TenantSchedule>>(
      ApiRoutes.config.schedule.filter({
        tenant_id: 1
      })
    ).then(
      async (response: ListApiResponse<TenantSchedule>) => {
        handleTenantScheduleQuery(response, setTenantScheduleState);
      },
      async reason => {
        setFeedbackState({
          open: true,
          message: `${t('cmts_config.failed_list')}: ${reason.message}`,
          severity: 'error'
        });
      }
    );
  }, []);

  const getDsCalcMaxProfilesInfo = (): Promise<CalcMaxProfilesInfo | null> => {
    return ApiGet<CalcMaxProfilesInfo>(
      ApiRoutes.config.calcMaxProfiles.filter({
        tenantId: 1,
        channelDirection: 'D'
      })
    ).then(
      async (response: CalcMaxProfilesInfo) => {
        return response;
      },
      async reason => {
        setFeedbackState({
          open: true,
          message: `${t('calc_max_profiles_config.global_failed_get')}: ${reason.message}`,
          severity: 'error'
        });
        return null;
      }
    );
  };

  const getUsCalcMaxProfilesInfo = (): Promise<CalcMaxProfilesInfo | null> => {
    return ApiGet<CalcMaxProfilesInfo>(
      ApiRoutes.config.calcMaxProfiles.filter({
        tenantId: 1,
        channelDirection: 'U'
      })
    ).then(
      async (response: CalcMaxProfilesInfo) => {
        return response;
      },
      async reason => {
        setFeedbackState({
          open: true,
          message: `${t('calc_max_profiles_config.global_failed_get')}: ${reason.message}`,
          severity: 'error'
        });
        return null;
      }
    );
  };

  const getDsTemplateProfiles = (): Promise<CalcConstraintTemplateProfile | null> => {
    return ApiGet<CalcConstraintTemplateProfile>(
      ApiRoutes.config.calcConstraintTemplateProfile.filter({
        tenantId: 1,
        channelDirection: 'D'
      })
    ).then(
      async (response: CalcConstraintTemplateProfile) => {
        return response;
      },
      async reason => {
        setFeedbackState({
          open: true,
          message: `${t('template_config.failed_list')}: ${reason.message}`,
          severity: 'error'
        });
        return null;
      }
    );
  };

  const getUsTemplateProfiles = (): Promise<CalcConstraintTemplateProfile | null> => {
    return ApiGet<CalcConstraintTemplateProfile>(
      ApiRoutes.config.calcConstraintTemplateProfile.filter({
        tenantId: 1,
        channelDirection: 'U'
      })
    ).then(
      async (response: CalcConstraintTemplateProfile) => {
        return response;
      },
      async reason => {
        setFeedbackState({
          open: true,
          message: `${t('template_config.failed_list')}: ${reason.message}`,
          severity: 'error'
        });
        return null;
      }
    );
  };


  useEffect(() => {
    Promise.all([getDsCalcMaxProfilesInfo(), getUsCalcMaxProfilesInfo()])
      .then(([dsProfilesInfo, usProfilesInfo]) => {
        const profilesInfo: (CalcMaxProfilesInfo | null)[] = [dsProfilesInfo, usProfilesInfo];
        handleGlobalCalcMaxProfilesQuery(profilesInfo, setGlobalCalcMaxProfilesState);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    Promise.all([getDsTemplateProfiles(), getUsTemplateProfiles()])
      .then(([dsTemplates, usTemplates]) => {
        const templates: (CalcConstraintTemplateProfile | null)[] = [dsTemplates, usTemplates];
        handleGlobalTemplateProfilesQuery(templates, setGlobalTemplateProfilesState);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);


  return (
    <>
      <GlobalTopologyScheduleConfig />
      <GlobalPnmRequesterScheduleConfig />
      <GlobalProfileCalculationScheduleConfig />
      <GlobalKafkaScheduleConfig />
      <GlobalCalcMaxProfilesConfig />
      <GlobalTemplateProfilesConfig />
    </>
  );
}
