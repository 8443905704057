import isValidHostname from "is-valid-hostname";


function SetField(obj: any, field_name: string | string[], value: string): void {
    if (typeof field_name === "string") {
        obj[field_name] = value;
    } else {
        obj[field_name.join('.')] = value;
    }
}


export function RequireField(t: any, errors: any, value: any, field_name: string | string[]): void {
    if (!value) {
        SetField(errors, field_name, t("form_error.required"));
    }
}

export function IsValidHostname(t: any, errors: any, value: any, field_name: string | string[]): void {
    if (typeof value !== "string") {
        SetField(errors, field_name, t("form_error.valid_hostname"))
    }
    if (!isValidHostname(value)) {
        SetField(errors, field_name, t("form_error.valid_hostname"))
    }
}

export function IsValidPort(t: any, errors: any, value: any, field_name: string | string[]): void {
    if (typeof value === "string") {
        value = parseInt(value);
        if (isNaN(value)) {
            SetField(errors, field_name, t("form_error.number"))
            return;
        }
    } else if (typeof value !== "number") {
        SetField(errors, field_name, t("form_error.number"))
        return;
    }

    if (!Number.isSafeInteger(value)) {
        SetField(errors, field_name, t("form_error.integer"))
        return;
    }

    if (value < 1) {
        SetField(errors, field_name, t("form_error.positive"))
    } else if (value > 65535) {
        SetField(errors, field_name, t("form_error.port_max"))
    }
}

export function IsValidTime(t: any, errors: any, value: any, field_name: string | string[]): void {
    if (typeof value === "string") {
        value = parseInt(value);
        if (isNaN(value)) {
            SetField(errors, field_name, t("form_error.number"))
            return;
        }
    }

    if (!Number.isSafeInteger(value)) {
        SetField(errors, field_name, t("form_error.integer"))
        return;
    }

    if (value < 1) {
        SetField(errors, field_name, t("form_error.positive"))
        return;
    }

    // 5 minutes to 30 days
    if(value < 300 || value > 2592000) {
        SetField(errors, field_name, t("form_error.time_range"))
        return;
    }
}


export function IsValidIPv4(t: any, errors: any, value: any, field_name: string | string[]): void {
    const regex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
    if (typeof value !== "string") {
        SetField(errors, field_name, t("form_error.ip_addr"))
    } else if (!regex.test(value)) {
        SetField(errors, field_name, t("form_error.ip_addr"))
    }
}

// noinspection JSUnusedGlobalSymbols
export function IsValidIPv6(t: any, errors: any, value: any, field_name: string | string[]): void {
    const regex = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/gi;
    if (typeof value !== "string") {
        SetField(errors, field_name, t("form_error.ip_addr"))
    } else if (!regex.test(value)) {
        SetField(errors, field_name, t("form_error.ip_addr"))
    }
}

export function IsValidJsonString(t: any, errors: any, value: any, field_name: string | string[]): void {
    if (typeof value === "string") {
        try {
            if (value.trim() !== "") {
                JSON.parse(value)
            }
        } catch (ignored) {
            SetField(errors, field_name, t("form_error.valid_json"))
        }
    }
}

