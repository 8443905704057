import React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogContent, DialogContentText } from "@mui/material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";

export type ConfirmationDialogType = {
  open: boolean;
  message: string;
  confirmText: string;
  testId?: string;
  onClose: (value: boolean) => void;
  children?: JSX.Element[] | JSX.Element;
  users?: any;
};

export function DeleteConfirmationDialog({
  open,
  message,
  confirmText,
  onClose,
  users,
  children,
  testId,
}: ConfirmationDialogType) {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onClose(true);
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <>
      {children}
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle
          sx={{
            backgroundColor: "rgba(187, 31, 31, 0.15)",
            marginBottom: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <PriorityHighIcon fontSize="small" color="error" />
          <Typography color="error" fontWeight="600">{t("manage_users.delete_dialog_title")}</Typography>
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <DialogContentText>{message}</DialogContentText>
          <Box>
            {users && users.length > 0 ? (
              <ul>
                {users.map((user: any) => (
                  <li key={user.id}>{user.email}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Box>
          <DialogContentText>
            {t("manage_users.delete_dialog_message")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Go Back
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirm}
            autoFocus
            data-testid={testId}
          >
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
