import {createTheme, ThemeOptions, Theme} from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
        colors: {
            background: {
                main: string
                secondary?: string
            },
            inactive: {
                main: string
            },
            direction: {
                downstream: string;
                upstream: string;
            },
            audit: {
                failed: string;
            }
        }
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
        colors?: {
            background?: {
                main?: string
                secondary?: string
            },
            inactive?: {
                main?: string
            },
            direction?: {
                downstream?: string;
                upstream?: string;
            },
            audit: {
                failed: string;
            }
        },
    }
}

const darkThemeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#44556C',
        },
        secondary: {
            main: '#D46D01',
        },
    },
    colors: {
        background: {
            main: "#EAEFF5"
        },
        inactive: {
            main: "#8A8A8A"
        },
        direction: {
            downstream: "#5C749A",
            upstream: "#678DB7"
        },
        audit: {
            failed: "#D91010"
        }
    },
    typography: {
        h1: {
            fontFamily: 'Encode Sans',
        },
        h2: {
            fontFamily: 'Encode Sans',
            fontSize: "24px",
        },
        h3: {
            fontFamily: 'Encode Sans',
        },
        h4: {
            fontFamily: 'Encode Sans',
        },
        h5: {
            fontFamily: 'Encode Sans',
        },
        h6: {
            fontFamily: 'Encode Sans',
            fontWeight: "600",
            fontSize: "1rem"
        },
        subtitle1: {
            fontSize: "12px"
        }
    },
    components: {}
};

const lightThemeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#44556C',
            light: '#334681',
        }, 
        secondary: {
            main: '#D46D01',
        },
        text: {
            primary: "#FFFFF",
            secondary: "#44556C"
        },
    },
    colors: {
        background: {
            main: "#EAEFF5",
            secondary: "#FEFEFE"
        },
        inactive: {
            main: "#8A8A8A"
        },
        direction: {
            downstream: "#5C749A",
            upstream: "#678DB7"
        },
        audit: {
            failed: "#D91010"
        }
    },
    typography: {
        body1: {
            fontFamily: 'Encode Sans',
        },
        h1: {
            fontFamily: 'Encode Sans',
        },
        h2: {
            fontFamily: 'Encode Sans',
        },
        h3: {
            fontFamily: 'Encode Sans',
        },
        h4: {
            fontFamily: 'Encode Sans',
        },
        h5: {
            fontFamily: 'Encode Sans',
        },
        h6: {
            fontFamily: 'Encode Sans',
            fontWeight: "600",
            fontSize: "1rem"
        },
        subtitle1: {
            fontSize: "12px"
        },
    }
};

export function Themes(themeName: string): Theme {
    if (themeName === "dark") {
        return createTheme(darkThemeOptions)
    } else {
        return createTheme(lightThemeOptions)
    }
}
