import { useState, useEffect, Dispatch, SetStateAction } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Typography } from "@mui/material";
import { ListApiResponse } from "../../Api/Types/General";
import { ApiRoutes } from "../../Api/ApiRoutes";
import { Cmts } from "../../Api/Types/Config";
import Grid from "@mui/material/Unstable_Grid2";
import { FeedbackContextProvider } from "../../Feedback/FeedbackContext";
import { useFeedbackContext } from "../../Feedback/FeedbackContext";
import { ApiGet } from "../../Api/Util";
import {useTranslation} from "react-i18next";

type IdMap<T> = { [key: string]: T };

export type CmtsSelectorComponentProp = {
  width?: number;
  direction?: string;
  region?: number[];
  currentCmtsId: string;
  setCurrentCmtsId: Dispatch<SetStateAction<string>>;
  cmtsIdToCmts: IdMap<Cmts>;
  setCmtsIdToCmts: Dispatch<SetStateAction<IdMap<Cmts>>>;
  firstItem?: boolean;
};

export function CmtsSelectorComponentContent({
  width,
  direction,
  region,
  currentCmtsId,
  setCurrentCmtsId,
  cmtsIdToCmts,
  setCmtsIdToCmts,
  firstItem,
}: CmtsSelectorComponentProp): JSX.Element {
  const { feedbackError } = useFeedbackContext();
  const { t } = useTranslation();

  const [selectedCmts, setSelectedCmts] = useState<string>("");

  const handleCmtsChange = (event: SelectChangeEvent): void => {
    const id = event.target.value;
    setCurrentCmtsId(id);
  };

  const loadData = () => {
    ApiGet<ListApiResponse<Cmts>>(
      ApiRoutes.config.cmts.filter({
        regions: region,
      }),
    )
      .then(async (response: ListApiResponse<Cmts>) => {
        const cmtsIdStringToCmts: { [key: string]: Cmts } = {};

        response.results.map((result) => {
          if (result.id === undefined) {
            return;
          }
          cmtsIdStringToCmts[result.id] = result;
        });

        setCmtsIdToCmts(cmtsIdStringToCmts);
      })
      .catch((error) => {
        feedbackError(t("selectors.cmts_error"));
      });
  };

  useEffect(() => {
    loadData();
  }, [region]);

  const cmtsList = Object.values(cmtsIdToCmts).sort(function (a, b) {
    return a.name.localeCompare(b.name, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  });

  const cmtsName = Object.hasOwn(cmtsIdToCmts, currentCmtsId)
    ? cmtsIdToCmts[currentCmtsId].name
    : "";

  const defaultKey = cmtsList.length
    ? Number(cmtsList[0].id as number).toString()
    : "all";

  useEffect(() => {
    if (cmtsList.length) {
      if (currentCmtsId === "" || !currentCmtsId || !cmtsIdToCmts[currentCmtsId]?.id) {
        if(currentCmtsId === 'all'){
          setCurrentCmtsId("all");
          setSelectedCmts("all");
        }else if(firstItem){
          setCurrentCmtsId(Number(cmtsList[0].id as number).toString());
          setSelectedCmts(Number(cmtsList[0].id as number).toString());
        }else{
          setCurrentCmtsId("all");
          setSelectedCmts("all");
        }
      } else {
        setSelectedCmts(currentCmtsId);
      }
    } else {
      setSelectedCmts("all");
    }
  }, [cmtsList, currentCmtsId]);

  return (
    <>
      <Grid lg={width ? width : 3} sx={{ padding: 0 }}>
        <FormControl
          sx={{ m: 1, minWidth: 120 }}
          size="small"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <Typography
            sx={{
              fontFamily: "Encode Sans",
              alignSelf: "center",
              padding: "7px",
              borderRadius: "6px 0px 0px 6px",
              color: "#fff",
              fontWeight: "600",
              backgroundColor:
                direction === "upstream"
                  ? (theme) => theme.colors.direction.upstream
                  : direction === "downstream"
                    ? (theme) => theme.colors.direction.downstream
                    : (theme) => theme.palette.primary.main,
            }}
          >
            {t("selectors.cmts")}
          </Typography>
          <Select
            // This solves an out-of-range warning
            value={selectedCmts}
            onChange={handleCmtsChange}
            displayEmpty
            label="Cmts Selector"
            data-testid="cmtsSelector"
            defaultValue={defaultKey}
            inputProps={{
              "data-testid": "selected",
              "aria-label": t("selectors.cmts_aria"),
              "aria-selected": !!cmtsName,
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundColor: "#FEFEFE",
                  },
                },
              },
            }}
            sx={{
              borderRadius: "0px 6px 6px 0",
              width: "100%",
              flex: 1,
              "& .MuiSvgIcon-root": {
                color: (theme) => theme.palette.secondary.main,
              },
              "& .MuiOutlinedInput-notchedOutline span": {
                display: "none",
              },
              "& .MuiSelect-select": {
                backgroundColor: "#FEFEFE"
              }
            }}
          >
            <MenuItem value="all">{t('selectors.all')}</MenuItem>
            {cmtsList.length &&
              cmtsList.map((cmts) => {
                return (
                  <MenuItem key={cmts.id} value={cmts.id}>
                    {cmts.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}

export function CmtsSelectorComponent({
  width,
  direction,
  region,
  currentCmtsId,
  setCurrentCmtsId,
  cmtsIdToCmts,
  setCmtsIdToCmts,
  firstItem,
}: CmtsSelectorComponentProp): JSX.Element {
  return (
    <FeedbackContextProvider>
      <CmtsSelectorComponentContent
        width={width}
        direction={direction}
        region={region}
        currentCmtsId={currentCmtsId}
        setCurrentCmtsId={setCurrentCmtsId}
        cmtsIdToCmts={cmtsIdToCmts}
        setCmtsIdToCmts={setCmtsIdToCmts}
        firstItem={firstItem}
      />
    </FeedbackContextProvider>
  );
}
