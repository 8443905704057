import React, {
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  GrafanaPanel,
  LazyGrafanaPanel,
  GraphPanelStyle,
  GraphPanelStyleDownstream,
  GraphPanelStyleUpstream,
  SingleValuePanelStyle,
  SingleValuePanelStyleDownstream,
  SingleValuePanelStyleUpstream,
  RankingPanelStyleDownstream,
  RankingPanelStyleUpstream,
} from "../Components/GrafanaPanel";
import Grid from "@mui/material/Unstable_Grid2";
import { useUserContext } from "../Components/UserContext";
import { CmtsTag } from "../Api/Types/Config";
import { useGrafanaLink } from "../Dashboard/Dashboard";
import CircularProgress from "@mui/material/CircularProgress";
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';

import { FeedbackContextProvider } from "../Feedback/FeedbackContext";
import { useFeedbackContext } from "../Feedback/FeedbackContext";
import { useSearchParams } from "react-router-dom";
import { RegionSelectorComponent } from "../Components/Selectors/RegionSelectorComponent";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import {CmtsDetails} from "./CmtsDetails";
import {useMediaQuery, useTheme} from "@mui/material";

const DownstreamPanelIDs = {
  streamDirection: "d",
  CapacityGained: 2,
  NumberOfCmts: 4,
  RawOFDMGain: 6,
  TrafficOnProfile: 12,
  LowestRankedInterface: 22,
  TrafficDistributionPerProfile: 26,
  HighestUncorrectableError: 30,
};

const UpstreamPanelIDs = {
  streamDirection: "u",
  CapacityGained: 14,
  NumberOfCmts: 16,
  RawOFDMGain: 18,
  TrafficOnProfile: 20,
  LowestRankedInterface: 24,
  TrafficDistributionPerProfile: 28,
  HighestUncorrectableError: 39,
};

type GrafanaStreamProp = {
  stream: any;
  extraParams: any;
  loading: boolean;
};

function GrafanaStream({
  stream,
  extraParams,
  loading,
}: GrafanaStreamProp): JSX.Element {
  const dashboardUrl = "/grafana/d-solo/-bD3vnCVk/overview";
  const techSupervisorUrl = "/grafana/d-solo/xXcFiqA4z/tech-supervisor-overview";
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchSM = useMediaQuery(theme.breakpoints.down("sm"));

  const SingleValuePanel =
    stream.streamDirection === "d"
      ? SingleValuePanelStyleDownstream
      : SingleValuePanelStyleUpstream;
  const GraphPanel =
    stream.streamDirection === "d"
      ? GraphPanelStyleDownstream
      : GraphPanelStyleUpstream;
  const RankingPanel =
    stream.streamDirection === "d"
      ? RankingPanelStyleDownstream
      : RankingPanelStyleUpstream;

  return (
    <>
      <Grid xs={12} display="flex" gap={1} flexDirection={matchSM ? "column" : "row"}>
        <Grid xs={12} lg={3} sx={{ p: 0 }}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.CapacityGained}
            width="100%"
            height="100%"
            injectedClassName="single-value-panel"
            injectedStyle={SingleValuePanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} lg={3} sx={{ p: 0 }}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.NumberOfCmts}
            width="100%"
            height="100%"
            injectedClassName="single-value-panel"
            injectedStyle={SingleValuePanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} lg={3} sx={{ p: 0 }}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.RawOFDMGain}
            width="100%"
            height="100%"
            injectedClassName="single-value-panel"
            injectedStyle={SingleValuePanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} lg={3} sx={{ p: 0 }}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.TrafficOnProfile}
            width="100%"
            height="100%"
            injectedClassName="single-value-panel"
            injectedStyle={SingleValuePanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid xs={12} display="flex" gap={1} padding="0" flexDirection={matchMD ? "column" : "row"}>
        <Grid xs={12} lg={4}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.LowestRankedInterface}
            width="100%"
            height="480px"
            injectedClassName="ranking-panel"
            injectedStyle={RankingPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} lg={4}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.TrafficDistributionPerProfile}
            width="100%"
            height="480px"
            injectedClassName="graph-panel"
            injectedStyle={GraphPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} lg={4}>
          <LazyGrafanaPanel
            url={techSupervisorUrl}
            panelId={stream.HighestUncorrectableError}
            width="100%"
            height="480px"
            injectedClassName="graph-panel"
            injectedStyle={GraphPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
    </>
  );
}

type IdMap<T> = { [key: string]: T };

export function OverviewContent(): JSX.Element {
  const { tenant } = useUserContext();
  const preferredStream = tenant.preferred_stream;
  const tenantName = tenant.name;
  const [showStream, setShowStream] = useState<string>("downstream");

  const { feedbackError } = useFeedbackContext();

  const { setGrafanaLink, setGrafanaLinkButton } = useGrafanaLink();
  const dashboardLinkUrl = "/grafana/d/-bD3vnCVk/overview";

  const [loading, setLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams({
    region: "all",
  });

  const [cmtsTagIdToCmtsTag, setCmtsTagIdToCmtsTag] = useState<IdMap<CmtsTag>>(
    {},
  );
  const cmtsTagParam: string[] | null = searchParams.getAll("region");

  const [currentCmtsTagIds, setCurrentCmtsTagIds] = useState<string[]>(
    cmtsTagParam ? cmtsTagParam : [],
  );

  useEffect(() => {
    setGrafanaLink(dashboardLinkUrl);
    setGrafanaLinkButton(true);
  }, []);

  const cmtsTagsParamCollectionId: number[] = useMemo(() => {
    const collection: number[] = [];

    if (Object.keys(cmtsTagIdToCmtsTag).length > 0) {
      currentCmtsTagIds.map((cmtsTagId) => {
        if (cmtsTagId !== "all") {
          if (cmtsTagId && Object.hasOwn(cmtsTagIdToCmtsTag, cmtsTagId)) {
            collection.push(Number(cmtsTagIdToCmtsTag[cmtsTagId]?.id));
          } else {
            feedbackError(
              "tag id does not exist in cmtsTagsIdToCmtsTag, cmtsTagIdToCmtsTag, cmtsTagId",
            );
          }
        }
      });
    }

    return collection;
  }, [currentCmtsTagIds, cmtsTagIdToCmtsTag]);

  const cmtsTagsParamCollectionName: string[] = useMemo(() => {
    const collection:string[] = [];

    currentCmtsTagIds.map((cmtsTagId) => {
      if(cmtsTagId === "all"){
        collection.push("All");
      }else{
        if(cmtsTagId && Object.hasOwn(cmtsTagIdToCmtsTag, cmtsTagId)){
          collection.push(cmtsTagIdToCmtsTag[cmtsTagId]?.name);
        }else{
          feedbackError(
            "tag id does not exist in cmtsTagsIdToCmtsTag, cmtsTagIdToCmtsTag, cmtsTagId",
          );
        }
      }
    });

    return collection
  }, [currentCmtsTagIds, cmtsTagIdToCmtsTag]);

  useEffect(() => {
    setSearchParams({
      region: currentCmtsTagIds,
    });
  }, [currentCmtsTagIds]);

  const handleChangeStream = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setShowStream(newValue);
  };


  const extraParams = {
    "var-region": cmtsTagsParamCollectionName,
  };

  return (
    <Grid container spacing={2}>
      <RegionSelectorComponent
        width={3}
        direction={preferredStream}
        cmtsTagIdToCmtsTag={cmtsTagIdToCmtsTag}
        setCurrentCmtsTagIds={setCurrentCmtsTagIds}
        setCmtsTagIdToCmtsTag={setCmtsTagIdToCmtsTag}
        currentCmtsTagIds={currentCmtsTagIds}
      />
      <CmtsDetails cmtsTagsParamCollection={cmtsTagsParamCollectionId} preferredStream={showStream} />
      <Grid lg={12}>
        <TabContext value={showStream}>
          <Box>
            <TabList
              onChange={handleChangeStream}
              aria-label="stream tab"
              centered
              sx={{
                ".MuiTabs-centered": {
                  justifyContent: "space-evenly"
                },
            }}
            >
              <Tab
                label="Downstream Metrics"
                value="downstream"
                aria-controls="downstream-tab"
                id="downstream-tab"
                icon={<PlayForWorkIcon fontSize="large" />}
                iconPosition="start"
                sx={{
                  fontWeight: "500",
                  fontSize: "24px",
                  color: "#777777",
                  textTransform :"none",
                  "&.Mui-selected": {
                    color: (theme) => theme.palette.secondary.main,
                  },
                }}
              />
              <Tab
                label="Upstream Metrics"
                value="upstream"
                aria-controls="upstream-tab"
                id="upstream-tab"
                icon={<PlayForWorkIcon fontSize="large" sx={{transform: "rotate(180deg)"}} />}
                iconPosition="start"
                sx={{
                  fontWeight: "500",
                  fontSize: "24px",
                  color: "#777777",
                  textTransform :"none",
                  "&.Mui-selected": {
                    color: (theme) => theme.palette.secondary.main,
                  },
                }}
              />
            </TabList>
          </Box>
          <TabPanel data-testid="downstream-tab" value="downstream" sx={{padding: 0}}>
            <Grid lg={12} sx={{padding: 0}}>
              <GrafanaStream
                stream={DownstreamPanelIDs}
                extraParams={extraParams}
                loading={loading}
              />
            </Grid>
          </TabPanel>
          <TabPanel data-testid="upstream-tab" value="upstream" sx={{padding: 0}}>
            <Grid lg={12} sx={{padding: 0}}>
              <GrafanaStream
                stream={UpstreamPanelIDs}
                extraParams={extraParams}
                loading={loading}
              />
            </Grid>
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
}

export function Overview(): JSX.Element {
  return (
    <FeedbackContextProvider>
      <OverviewContent />
    </FeedbackContextProvider>
  );
}
