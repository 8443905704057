import {TextField} from "@mui/material";
import {TextFieldProps} from "@mui/material/TextField/TextField";


export  type BaseNumberFieldProps = {
   mode?: string
}

export type NumberFieldProps  = TextFieldProps & BaseNumberFieldProps;

export default function NumberField(props: NumberFieldProps): JSX.Element {
   const {mode, ...rest} = props;

   if (mode === "decimal") {
      return (<TextField
         inputProps={{inputMode: "decimal", pattern: "([0-9]+)?[,\\.]?[0-9]*", type: "number"}}
         {...rest}
      />);
   }

   return (<TextField
      inputProps={{inputMode: "numeric", pattern: "[0-9]*", type: "number"}}
      {...rest}
   />);
}

