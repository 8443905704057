import {Accordion, AccordionDetails, AccordionSummary, Button, FormGroup, Grid, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, {useCallback, useEffect} from "react";
import {ApiRoutes} from "../../Api/ApiRoutes";
import {ApiPut} from "../../Api/Util";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {useFeedbackContext} from "../../Feedback/FeedbackContext";
import Placeholder from "../../Placeholder";
import NumberField from "../../Components/NumberField";
import {MdSave} from "react-icons/md";
import {TenantTopologyScheduleState, useGlobalConfigContext} from "../GlobalStateContext";
import {IsValidTime, RequireField} from "../../Feedback/ValidationUtils";


function validateGlobalTopology(t: any, values: TenantTopologyScheduleState): object {
    const errors: any = {};

    RequireField(t, errors, values.topology?.schedule_rate_s, "topology");
    RequireField(t, errors, values.cm_topology?.schedule_rate_s, "cm_topology");

    IsValidTime(t, errors, values.topology?.schedule_rate_s, "topology");
    IsValidTime(t, errors, values.cm_topology?.schedule_rate_s, "cm_topology");

    return errors;
}

export function GlobalTopologyScheduleConfig(): JSX.Element {
    const {tenantScheduleState: {topologySchedule, tenantScheduleLoaded}, } = useGlobalConfigContext();

    const {t} = useTranslation();
    const {setFeedbackState} = useFeedbackContext();

    const updateFailure = useCallback((error: any) => {

        setFeedbackState({
            open: true,
            message: `${t('topology_config.failed_update')}: ${error.message}`,
            severity: "error"
        });
    }, [setFeedbackState, t]);

    const updateSuccess = useCallback(() => {
        // console.debug(response.json());
        setFeedbackState({open: true, message: `${t('topology_config.success_update')}`, severity: "success"});
    }, [setFeedbackState, t]);

    const submitCallback = useCallback((values: TenantTopologyScheduleState) => {
        ApiPut(
          ApiRoutes.config.schedule.subset,
          [values.topology, values.cm_topology]
        ).then(updateSuccess, updateFailure);
    }, [updateSuccess, updateFailure]);

    const validateSubmission = useCallback(
        (values: TenantTopologyScheduleState): object => validateGlobalTopology(t, values),
        [t]
    );

    const formik = useFormik({
            initialValues: topologySchedule,
            validate: validateSubmission,
            onSubmit: submitCallback,
            validateOnBlur: true,
            validateOnChange: true
        }
    );

    useEffect(() => {
        formik.setValues(topologySchedule);
    }, [topologySchedule]);

    return <>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="global-topology-schedule"
            >
                <Typography variant="h5">Topology Collection Schedule</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    !tenantScheduleLoaded ? (
                        <Placeholder />
                    ) : (
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormGroup row>
                                        <NumberField
                                            mode="integer"
                                            label={t('topology_config.schedule_rate')}
                                            name="topology.schedule_rate_s"
                                            value={formik.values.topology?.schedule_rate_s || ''}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.errors.topology)}
                                            helperText={formik.errors.topology}
                                            variant="outlined"
                                            sx={{width: "100%"}}
                                            inputProps={{'data-testid': "schedule_rate_s"}}
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup row>
                                        <NumberField
                                          mode="integer"
                                          label={t('topology_config.cm_topo_schedule_rate')}
                                          name="cm_topology.schedule_rate_s"
                                          value={formik.values.cm_topology?.schedule_rate_s || ''} //CM TOPO
                                          onChange={formik.handleChange}
                                          error={Boolean(formik.errors.cm_topology)}
                                          helperText={formik.errors.cm_topology}
                                          variant="outlined"
                                          sx={{width: "100%"}}
                                          inputProps={{'data-testid': "cm_topo_schedule_rate_s"}}
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button color="primary" startIcon={<MdSave />} variant="outlined"
                                            data-testid="submit"
                                            type="submit">
                                        {t('topology_config.save')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }
            </AccordionDetails>
        </Accordion>
    </>;
}
