import {useState} from "react";
import {Button} from "@mui/material";
import {HiOutlineClipboardDocument} from "react-icons/hi2";
import {useFeedbackContext} from "../Feedback/FeedbackContext";
import {useTranslation} from "react-i18next";
import {copyTextToClipboard} from "../Utils/CopyTextToClipboard";


export function ClipboardCopyButton({copyText}: { copyText: string }): JSX.Element {
  const [isCopied, setIsCopied] = useState(false);
  const {feedbackError, feedbackSuccess} = useFeedbackContext();
  const {t} = useTranslation();

  // onClick handler function for the copy button
  const handleCopyClick = (): void => {
    // Asynchronously call copyTextToClipboard

    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
        feedbackSuccess(t("copy.success"));
      })
      .catch((error) => {
        // console.log(error);
        feedbackError(t("copy.failure"));
      });
  };

  return (
    <Button variant="outlined" onClick={handleCopyClick}>
      <span>{isCopied ? t("copy.copied") : t("copy.copy")}</span>
      <HiOutlineClipboardDocument />
    </Button>
  );
}
