import {FormControlLabel, FormGroup, Switch} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useChannelDeploymentContext} from "../DeploymentContext";
import {ConstructE6kDsInstructions} from "./InstructionConstructors/E6kDs";
import {ManualDeploymentInstructionsDetail} from "./ManualProfileDeployment";


export function E6kDsManualProfileDeployment(): JSX.Element {
  const {t} = useTranslation();
  const t_no_escape = function (key: string, options: { [p: string]: any } = {}): string {
    options = Object.assign({interpolation: {escapeValue: false}}, options);
    return t(key, options);
  };

  const {cmts, macDomain, dsChannel, pmaDsResult} = useChannelDeploymentContext();

  // E6K Config form state
  const [includeSafetyChecks, setIncludeSafetyChecks] = useState<boolean>(true);
  const [includePmaDsResult, setIncludePmaDsResult] = useState<boolean>(false);

  const instructions = useMemo((): string => {
    return ConstructE6kDsInstructions(
      t_no_escape, cmts, macDomain, dsChannel, pmaDsResult,
      includeSafetyChecks, includePmaDsResult
    );
  }, [
    t_no_escape, cmts, macDomain, dsChannel, pmaDsResult,
    includeSafetyChecks, includePmaDsResult
  ]);

  return (
    <ManualDeploymentInstructionsDetail instructions={instructions}>
      <Typography variant={"h6"} color={(theme): any => theme.palette.primary.main}>
        {t("deploy.e6k_config.downstream_config_header")}
      </Typography>
      <FormGroup sx={{marginBottom: "25px"}}>

        <FormControl sx={{marginTop: "15px", alignItems: "start"}}>
          <FormControlLabel
            control={
              <Switch color="primary"
                      onChange={(e): void => {
                        setIncludeSafetyChecks(e.target.checked);
                      }}
                      defaultChecked={includeSafetyChecks}
              />
            }
            label={t("deploy.include_safety_checks")}
            labelPlacement="start"
          ></FormControlLabel>
        </FormControl>

        <FormControl sx={{marginTop: "15px", alignItems: "start"}}>
          <FormControlLabel
            control={
              <Switch color="primary"
                      onChange={(e): void => {
                        setIncludePmaDsResult(e.target.checked);
                      }}
                      defaultChecked={includePmaDsResult}
              />
            }
            label={t("deploy.include_pma_result")}
            labelPlacement="start"
          ></FormControlLabel>
        </FormControl>

      </FormGroup>
    </ManualDeploymentInstructionsDetail>
  );
}
