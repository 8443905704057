import {useTranslation} from "react-i18next";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Paper, Typography} from "@mui/material";
import {CmtsSchedule} from "../../Api/Types/Config";
import {Timestamp} from "../../Components/Timestamp";
import {Trace} from "../../Api/Types/Trace";
import {styled} from "@mui/material/styles";

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    boxShadow: "none",
    border: "0.5px solid rgba(8, 26, 81, 0.4)",
    color: theme.palette.text.secondary,
}));

function ScheduleRate({scheduleRate_s}: { scheduleRate_s: number | undefined | null }): JSX.Element {
    const {t} = useTranslation();

    if (!scheduleRate_s) {
        return <em>{t("deploy.never")}</em>
    }
    let scheduleRate = scheduleRate_s;
    let scheduleUnit = "Second";

    if (scheduleRate % 60 === 0) {
        scheduleRate /= 60;
        scheduleUnit = "Minute";
    }
    if (scheduleRate % 60 === 0) {
        scheduleRate /= 60;
        scheduleUnit = "Hour";
    }
    if (scheduleRate % 24 === 0) {
        scheduleRate /= 24;
        scheduleUnit = "Day";
    }

    if (scheduleRate > 1) {
        scheduleUnit += "s"
    }

    return <em>Every {scheduleRate} {scheduleUnit}</em>;
}

function GetStateI18n(state: string): string {
    return "deploy_state.unknown";
}

function GetWithDefault<T>(value: T | undefined, defaultValue: T): T {
    return value !== undefined ? value : defaultValue;
}

export type TimeRemainingPanelProps = {
    loaded: boolean;
    title_t: string;
    lastAction_t: string;
    nextAction_t: string;
    globalScheduleRate_s: number | undefined;
    scheduleInfo: CmtsSchedule | undefined;
    traces: Trace[] | null | undefined;
    showTrace?: boolean;
}

export function TimeRemainingPanel(
    {
        loaded,
        title_t,
        lastAction_t,
        nextAction_t,
        globalScheduleRate_s,
        scheduleInfo,
        traces,
        showTrace
    }: TimeRemainingPanelProps): JSX.Element {
    const {t} = useTranslation();
    const showTraceDefaulted = GetWithDefault(showTrace, true);
    if (!loaded) {
        return <Grid2 md={4} lg={4}>
            <Item>
                <Grid2 container>
                    <Grid2 md={12}><Typography variant={"h6"}>{t(title_t)}</Typography> </Grid2>

                    <Grid2 md={5}><Typography variant="subtitle2">{t(lastAction_t) /* Last Scan*/}</Typography></Grid2>
                    <Grid2 md={7}><Typography variant="subtitle2" color={(theme): any => theme.colors.inactive.main}>
                        {t("deploy.loading") /* Loading...*/}
                    </Typography></Grid2>

                    <Grid2 md={5}><Typography variant="subtitle2">{t(nextAction_t) /* Next Scan*/}</Typography></Grid2>
                    <Grid2 md={7}><Typography variant="subtitle2" color={(theme): any => theme.colors.inactive.main}>
                        {t("deploy.loading") /* Loading...*/}
                    </Typography></Grid2>

                    <Grid2 md={5}><Typography variant="subtitle2">
                        {t("deploy.schedule") /* Schedule */}
                    </Typography></Grid2>
                    <Grid2 md={7}><Typography variant="subtitle2" color={(theme): any => theme.colors.inactive.main}>
                        {t("deploy.loading") /* Loading... */}
                    </Typography></Grid2>

                    {showTraceDefaulted ?
                        <>
                            <Grid2 md={5}>
                                <Typography variant="subtitle2">
                                    {t("deploy.state") /* State */}
                                </Typography>
                            </Grid2>
                            <Grid2 md={7}>
                                <Typography variant="subtitle2" color={(theme): any => theme.colors.inactive.main}>
                                    {t("deploy.loading") /* Loading... */}
                                </Typography>
                            </Grid2>
                        </> :
                        <></>}
                </Grid2>
            </Item>
        </Grid2>;
    }

    if (!scheduleInfo) {
        return <Grid2 md={4} lg={4}>
            <Item>
                <Grid2 container>
                    <Grid2 md={12}><Typography variant={"h6"}>{t(title_t)}</Typography> </Grid2>

                    <Grid2 md={5}><Typography
                        variant="subtitle2">{t(lastAction_t) /* Last RxMER Scan*/}</Typography></Grid2>
                    <Grid2 md={7}><Typography variant="subtitle2" color={(theme): any => theme.colors.inactive.main}>
                        {t("deploy.disabled") /* Disabled */}
                    </Typography></Grid2>

                    <Grid2 md={5}><Typography variant="subtitle2">{t(nextAction_t) /* Next Scan*/}</Typography></Grid2>
                    <Grid2 md={7}><Typography variant="subtitle2" color={(theme): any => theme.colors.inactive.main}>
                        {t("deploy.disabled") /* Disabled */}
                    </Typography></Grid2>

                    <Grid2 md={5}><Typography
                        variant="subtitle2">{t("deploy.schedule") /* Next Scan*/}</Typography></Grid2>
                    <Grid2 md={7}><Typography variant="subtitle2" color={(theme): any => theme.colors.inactive.main}>
                        {t("deploy.none") /* None */}
                    </Typography></Grid2>
                    {showTraceDefaulted ?
                        <><Grid2 md={5}><Typography
                            variant="subtitle2">{t("deploy.state") /* Next Scan*/}</Typography></Grid2>
                            <Grid2 md={7}><Typography variant="subtitle2" color={(theme): any => theme.colors.inactive.main}>
                                {t("deploy.disabled") /* Disabled */}
                            </Typography></Grid2></> :
                        <></>}
                </Grid2>
            </Item>
        </Grid2>;
    }

    const scheduleRate_s = scheduleInfo.use_global ? globalScheduleRate_s : scheduleInfo.schedule_rate_s;
    const lastRunTimestamp = scheduleInfo.last_run ? Date.parse(scheduleInfo.last_run) / 1000 : null;
    const nextAction_ts: number | null = lastRunTimestamp && scheduleRate_s ? lastRunTimestamp + scheduleRate_s : null;
    const lastAction_ts: number | null = lastRunTimestamp && scheduleRate_s ? lastRunTimestamp : null;
    const actionState = !traces?.length ? "unknown" : traces[0].states.length ? traces[0].states[0].state : "unknown";

    return <Grid2 md={4} lg={4}>
        <Item>
            <Grid2 container>
                <Grid2 md={12}><Typography variant={"h6"}>{t(title_t)}</Typography> </Grid2>

                <Grid2 md={5}><Typography variant="subtitle2">{t(lastAction_t) /* Last Scan*/}</Typography></Grid2>
                <Grid2 md={7}>
                    <Typography variant="subtitle2" color={(theme): any => theme.colors.inactive.main}>
                        <Timestamp value_s={lastAction_ts} />
                    </Typography>
                </Grid2>
                <Grid2 md={5}><Typography variant="subtitle2">{t(nextAction_t) /* Next Scan*/}</Typography></Grid2>

                <Grid2 md={7}><Typography variant="subtitle2" color={(theme): any => theme.colors.inactive.main}>
                    <Timestamp value_s={nextAction_ts} />
                </Typography></Grid2>
                <Grid2 md={5}><Typography variant="subtitle2">{t("deploy.schedule") /* Next Scan*/}</Typography></Grid2>

                <Grid2 md={7}><Typography variant="subtitle2" color={(theme): any => theme.colors.inactive.main}>
                    <ScheduleRate scheduleRate_s={scheduleRate_s} />
                </Typography></Grid2>

                {showTraceDefaulted ?
                    <><Grid2 md={5}><Typography
                        variant="subtitle2">{t("deploy.state") /* Next Scan*/}</Typography></Grid2>
                        <Grid2 md={7}>
                            <Typography variant="subtitle2" color={(theme): any => theme.colors.inactive.main}>
                                {t(GetStateI18n(actionState))/* Disabled */}
                            </Typography>
                        </Grid2></> :
                    <></>}
            </Grid2>
        </Item>
    </Grid2>;
}
