import { MenuItem, Select, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { CalcConstraintTemplateProfile, TemplateProfile } from '../../Api/Types/Config';
import { ApiRoutes } from '../../Api/ApiRoutes';
import { ApiGet } from '../../Api/Util';
import { ListApiResponse } from '../../Api/Types/General';
import { useFeedbackContext } from '../../Feedback/FeedbackContext';
import { useGlobalTemplateProfilesContext } from '../GlobalTemplateProfilesContext';

export type CalcTemplateProfilesDropdownProps = {
  handleChange: any;
  calcTemplateProfilesInfo: CalcConstraintTemplateProfile | null;
  fieldName: string;
  globalCalcTemplateProfileValue?: number | null;
  direction: string;
};

export function CalcTemplateProfilesDropdown(props: CalcTemplateProfilesDropdownProps): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const { setFeedbackState } = useFeedbackContext();
  const { globalTemplateProfilesState } = useGlobalTemplateProfilesContext();
  const {
    handleChange,
    calcTemplateProfilesInfo,
    fieldName,
    globalCalcTemplateProfileValue,
    direction
  } = props;
  const [globalTemplateProfile, setGlobalTemplateProfile] = useState<TemplateProfile>();

  const getGlobalTemplateProfiles = useCallback(() => {
    const templateId =
      direction === 'D'
        ? globalTemplateProfilesState.templateProfilesGlobalConfig.downstreamTemplateProfiles
            ?.calc_constraint_template_profile_id
        : globalTemplateProfilesState.templateProfilesGlobalConfig.upstreamTemplateProfiles
            ?.calc_constraint_template_profile_id;
    if (!templateId || templateId === null) return;
    ApiGet<TemplateProfile>(ApiRoutes.config.templateProfile.single(templateId))
      .then(response => {
        setGlobalTemplateProfile(response);
      })
      .catch(error => {
        setFeedbackState({
          open: true,
          message: `${t('cmts_config.failed_get_template_profile')}: ${error.message}`,
          severity: 'error'
        });
      });
  }, [globalCalcTemplateProfileValue]);

  useEffect(() => {
    getGlobalTemplateProfiles();
  }, [globalCalcTemplateProfileValue]);

  const global_value =
    globalCalcTemplateProfileValue !== undefined &&
    globalCalcTemplateProfileValue !== null
      ? globalCalcTemplateProfileValue
      : 0;

  const initialCalcTemplateProfileValue =
    calcTemplateProfilesInfo?.calc_constraint_template_profile_id === null
      ? 0
      : typeof calcTemplateProfilesInfo?.calc_constraint_template_profile_id ===
          'number'
        ? calcTemplateProfilesInfo.calc_constraint_template_profile_id
        : 0;

  const [templateProfilesData, setTemplateProfilesData] = useState<TemplateProfile[]>([]);
  const [calcTemplateProfiles, setCalcTemplateProfiles] = useState<number>(initialCalcTemplateProfileValue);

  useEffect(() => {
    ApiGet<ListApiResponse<TemplateProfile>>(
      ApiRoutes.config.templateProfile.filter({
        tenant: 1,
        channelDirection: direction
      })
    )
      .then((response: ListApiResponse<TemplateProfile>) => {
        setTemplateProfilesData(response.results);
      })
      .catch(error => {
        setFeedbackState({
          open: true,
          message: `${t('template_config.failed_list')}: ${error.message}`,
          severity: 'error'
        });
      });
  }, []);

  useEffect(() => {
    setCalcTemplateProfiles(initialCalcTemplateProfileValue);
    const returnObj = {
      ...calcTemplateProfilesInfo,
      calc_constraint_template_profile_id: calcTemplateProfiles
    };
    handleChange(returnObj);
  }, []);

  const onCalcTemplateChange = (event: any): void => {
    setCalcTemplateProfiles(event.target.value);

    const returnObj = {
      ...calcTemplateProfilesInfo,
      calc_constraint_template_profile_id: Number(event.target.value)
    };
    handleChange(returnObj);
  };

  return (
    <Box>
      <Typography>{t(`template_config.${fieldName}`)}</Typography>
      <Box sx={{ pl: '4px', pr: '4px' }}>
        <Select
          name={`${fieldName}.calc_template_profiles`}
          value={calcTemplateProfiles}
          onChange={onCalcTemplateChange}
          variant={'standard'}
          inputProps={{
            'aria-label': `${fieldName}-calc_template_profiles`
          }}
          sx={{ marginTop: '16px' }}
        >
          <MenuItem value={0}>{t(`template_config.use_global`)}</MenuItem>
          {templateProfilesData.map((templateProfile: TemplateProfile) => (
            <MenuItem key={templateProfile.id} value={templateProfile.id}>
              {templateProfile.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {global_value === 0 ? (
        <Typography variant="subtitle1">
          {t('cmts_config.global_setting')} {t('template_config.none')}
        </Typography>
      ) : (
        <Typography variant="subtitle1">
          {t('cmts_config.global_setting')} {globalTemplateProfile?.name}
        </Typography>
      )}
    </Box>
  );
}
