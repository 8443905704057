import {PmaUsProfile, PmaUsResult} from "../../../../Api/Types/CapacityBooster";
import {Cmts} from "../../../../Api/Types/Config";
import {MacDomain, UsChannel} from "../../../../Api/Types/Topology";
import {
  ALLOWED_IUCS,
  BITLOADING_TO_C100G_MODULATION,
  C100gModulation,
  FrequencyHz,
  INDENT,
  PilotPattern
} from "./Common";
import {BigIntReplacer} from "../../../../Utils/Converters";

export function ConstructC100gUsInstructions(
  t_no_escape: (key: string, options?: any) => string,
  cmts: Cmts | null,
  macDomain: MacDomain | null,
  usChannel: UsChannel | null,
  pmaUsResult: PmaUsResult | null,
  ofdmaIucProfileId: string,
  firstNewOfdmaMinislotCfgId: string,
  includeSafetyChecks: boolean,
  includePmaUsResult: boolean
): string {
  const C100G_US_CHAN_DESCR_RE
    = /OFDMA Upstream (?<interfaceOfdmaId>\d+\/\d+\.\d+)/;

  type C100gOfdmaMinislotCfgId = string;
  type C100gSubcarrierGroupMinislotId = number;

  // Exception zone, aka subcarrier group minislot
  class C100gOfdmaMinislotCfgExceptionZone {
    subcarrierGroupMinislotId: C100gSubcarrierGroupMinislotId | null = null;
    startFreqHz: FrequencyHz | null = null;
    endFreqHz: FrequencyHz | null = null;
    modulation: C100gModulation | null = null;
    pilotPattern: PilotPattern | null = null
  }

  class C100gOfdmaMinislotCfg {
    ofdmaMinislotCfgId: C100gOfdmaMinislotCfgId | null = null;
    channelIuc: number | null = null;
    exceptionZones: C100gOfdmaMinislotCfgExceptionZone[] = [];
    // logging only
    meanBitLoading: number | null = null;
    defaultBitLoading: number | null = null;

    getLines(): string[] {
      const lines: string[] = [];
      lines.push("");
      lines.push(`! ${t_no_escape("deploy.commands.c100g.ofdma_minislot_cfg_info", {
        channel_iuc: this.channelIuc,
        mean_bit_loading: this.meanBitLoading,
        default_bit_loading: this.defaultBitLoading,
      })}`);
      lines.push(`ofdma minislot-cfg ${this.ofdmaMinislotCfgId}`);
      this.exceptionZones.forEach(exceptionZone => {
        lines.push(`${INDENT}subcarrier-group-minislot ${exceptionZone.subcarrierGroupMinislotId}`
          + ` ${exceptionZone.startFreqHz} ${exceptionZone.endFreqHz}`
          + ` modulation ${exceptionZone.modulation}`
          + ` pilot-pattern ${exceptionZone.pilotPattern}`);
      });
      lines.push(`exit`);
      return lines;
    }
  }

  const nextOfdmaMinislotCfgId = function (): () => C100gOfdmaMinislotCfgId {
    let counter: number = +firstNewOfdmaMinislotCfgId;
    if (isNaN(counter)) {
      counter = 0;
      return function (): C100gOfdmaMinislotCfgId {
        const s: C100gOfdmaMinislotCfgId = firstNewOfdmaMinislotCfgId + (counter == 0 ? "" : `+${counter}`);
        counter++;
        return s;
      };
    }
    return function (): C100gOfdmaMinislotCfgId {
      const s: C100gOfdmaMinislotCfgId = `${counter}`;
      counter++;
      return s;
    };
  }();

  // ====

  const resultsLines: string[] = [];
  resultsLines.push(`! ${t_no_escape("deploy.commands.instructions_title")}`);
  if (pmaUsResult == null || !pmaUsResult.calculated_profiles) {
    resultsLines.push("");
    resultsLines.push(`! ${t_no_escape("deploy.commands.no_profile_data")}`);
    return resultsLines.join("\n");
  }

  resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.for_cmts_mac_domain_us_channel", {
    cmts: pmaUsResult.cmts_name,
    mac_domain: pmaUsResult.md_ifdescr,
    us_channel: pmaUsResult.us_ifdescr
  })}`);
  resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.for_pma_task_id_us_result_id", {
    pma_task_id: pmaUsResult.task,
    pma_us_result_id: pmaUsResult.id
  })}`);

  let timestamp = pmaUsResult.result_completed_timestamp;
  timestamp = timestamp.replace("T", " ").replace("Z", " UTC");
  resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.profiles_calculated_at_timestamp", {
    timestamp: timestamp
  })}`);

  // build ofdmaMinislotCfgs
  const ofdmaMinislotCfgs: C100gOfdmaMinislotCfg[] = [];
  pmaUsResult.calculated_profiles.forEach(pmaUsProfile => {
    const subcarrier_statuses = pmaUsProfile.us_subcarrier_statuses;

    // this object has exception zones only
    if (subcarrier_statuses.length == 1) {
      return;
    }

    const ofdmaMinislotCfg = new C100gOfdmaMinislotCfg();
    ofdmaMinislotCfg.ofdmaMinislotCfgId = nextOfdmaMinislotCfgId();
    ofdmaMinislotCfg.channelIuc = pmaUsProfile.data_iuc
    ofdmaMinislotCfg.meanBitLoading = pmaUsProfile.mean_subcarrier_bit_loading;
    ofdmaMinislotCfg.defaultBitLoading = pmaUsProfile.most_frequent_bit_loading;

    subcarrier_statuses.forEach(subcarrier_status => {
      if (subcarrier_status.minislot_bit_loading == pmaUsProfile.most_frequent_bit_loading) {
        return;
      }
      const exceptionZone = new C100gOfdmaMinislotCfgExceptionZone();
      exceptionZone.modulation = BITLOADING_TO_C100G_MODULATION[subcarrier_status.minislot_bit_loading];
      exceptionZone.startFreqHz = subcarrier_status.start_frequency_hz;
      exceptionZone.endFreqHz = subcarrier_status.end_frequency_hz;
      exceptionZone.pilotPattern = subcarrier_status.minislot_pilot_pattern;
      ofdmaMinislotCfg.exceptionZones.push(exceptionZone);
      exceptionZone.subcarrierGroupMinislotId = ofdmaMinislotCfg.exceptionZones.length;
    });

    ofdmaMinislotCfgs.push(ofdmaMinislotCfg);
  });
  
  // get cmts-specific ids
  let us_chan_info = pmaUsResult.us_ifdescr.match(C100G_US_CHAN_DESCR_RE)?.groups;
  if (!us_chan_info) {
    us_chan_info = {
      interfaceOfdmaId: t_no_escape("deploy.commands.c100g.unknown_interface_ofdma_id"),
    };
  }
  const interfaceOfdmaId = us_chan_info.interfaceOfdmaId;

  const iucToPmaUsProfile: { [p: number]: PmaUsProfile | null } = {};
  const iucToOfdmaMinislotCfg: { [p: number]: C100gOfdmaMinislotCfg | null } = {};
  ALLOWED_IUCS.forEach(iuc => {
    iucToPmaUsProfile[iuc] = null;
  });
  pmaUsResult.calculated_profiles.forEach(pmaUsProfile => {
    iucToPmaUsProfile[pmaUsProfile.data_iuc] = pmaUsProfile;
  });
  ofdmaMinislotCfgs.forEach(ofdmaMinislotCfg => {
    if (ofdmaMinislotCfg.channelIuc === null) { throw Error("ofdmaMinislotCfg has null channelIuc"); }
    iucToOfdmaMinislotCfg[ofdmaMinislotCfg.channelIuc] = ofdmaMinislotCfg;
  });

  if (includeSafetyChecks) {
    resultsLines.push("");
    resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.safety_check_upstream_channel_intro", {
      interface_ofdma_id: interfaceOfdmaId,
      ofdma_iuc_profile_id: ofdmaIucProfileId
    })}`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.safety_check_upstream_channel_do_command")}`);
    resultsLines.push(`show interface ofdma ${interfaceOfdmaId}`
      + ` | include "iuc-profile ${ofdmaIucProfileId}"`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.safety_check_upstream_channel_check", {
      interface_ofdma_id: interfaceOfdmaId,
      ofdma_iuc_profile_id: ofdmaIucProfileId
    })}`);

    resultsLines.push("");
    resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.safety_check_unique_iuc_profile_id_intro", {
      ofdma_iuc_profile_id: ofdmaIucProfileId
    })}`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.safety_check_unique_iuc_profile_id_do_command")}`);
    resultsLines.push(`show interface ofdma | include "iuc-profile ${ofdmaIucProfileId}"`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.safety_check_unique_iuc_profile_id_check_line", {
      ofdma_iuc_profile_id: ofdmaIucProfileId
    })}`);

    resultsLines.push("");
    resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.safety_check_create_ofdma_minislot_cfg_intro")}`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.safety_check_create_ofdma_minislot_cfg_do_command")}`);
    resultsLines.push(`show ofdma minislot-cfg | include "ofdma minislot-cfg"`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.safety_check_create_ofdma_minislot_cfg_check")}`);

    // check: channel frequency range matches new profiles?
    // check: subcarrier-spacing?
  }

  resultsLines.push("");
  resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.begin_configuration_heading")}`);
  resultsLines.push(`config`);

  // output ofdmProfiles
  resultsLines.push("");
  resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.create_ofdma_minislot_cfgs_heading")}`);
  ofdmaMinislotCfgs.forEach(ofdmaMinislotCfg => {
    ofdmaMinislotCfg.getLines().forEach(line => resultsLines.push(line));
  });

  resultsLines.push("");
  resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.set_channel_config_heading")}`);
  resultsLines.push(`ofdma iuc-profile ${ofdmaIucProfileId}`);
  ALLOWED_IUCS.forEach(iuc => {
    const pmaUsProfile = iucToPmaUsProfile[iuc];
    const ofdmaMinislotCfg = iucToOfdmaMinislotCfg[iuc];
    if (pmaUsProfile) {
      resultsLines.push(`! ${INDENT}${t_no_escape(
        "deploy.commands.c100g.configure_data_iuc_default_bit_loading_mean_bit_loading",
        {
          iuc: iuc,
          default_bit_loading: pmaUsProfile.most_frequent_bit_loading,
          mean_bit_loading: pmaUsProfile.mean_subcarrier_bit_loading
        })}`);
      const default_modulation = BITLOADING_TO_C100G_MODULATION[pmaUsProfile.most_frequent_bit_loading];
      if (ofdmaMinislotCfg) {
        resultsLines.push(`${INDENT}data-iuc ${iuc}`
          + ` modulation ${default_modulation}`
          + ` pilot-pattern ${pmaUsProfile.most_frequent_pilot_pattern}`
          + ` minislot-cfg ${ofdmaMinislotCfg.ofdmaMinislotCfgId}`);
      } else {
        resultsLines.push(`${INDENT}data-iuc ${iuc}`
          + ` modulation ${default_modulation}`
          + ` pilot-pattern ${pmaUsProfile.most_frequent_pilot_pattern}`);
      }
    } else {
      resultsLines.push(`! ${INDENT}${t_no_escape(
        "deploy.commands.c100g.configure_data_iuc_unused",
        {iuc: iuc})}`);
      resultsLines.push(`${INDENT}no data-iuc ${iuc}`);
    }
  });
  resultsLines.push(`exit`);

  if (includeSafetyChecks) {
    resultsLines.push("");
    resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.clean_up_ofdma_minislot_cfg_intro")}`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.clean_up_ofdma_minislot_cfg_do_command")}`);
    resultsLines.push(`show ofdma minislot-cfg | include "ofdma minislot-cfg"`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.clean_up_ofdma_minislot_cfg_check")}`);
    resultsLines.push(`show ofdma iuc-profile | include "minislot-cfg <config_id>"`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.clean_up_ofdma_minislot_cfg_check_2")}`);
    resultsLines.push(`no ofdma minislot-cfg <config_id>`);
  }

  resultsLines.push("");
  resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.configuration_complete_heading")}`);
  resultsLines.push(`exit`);

  // output debug info if requested
  if (includePmaUsResult) {
    resultsLines.push("");
    resultsLines.push(`! pmaUsResult: ${JSON.stringify(pmaUsResult, BigIntReplacer)}`);
  }

  return resultsLines.join("\n");
}
