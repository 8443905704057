import {Box, Typography} from "@mui/material";
import {isRouteErrorResponse, useRouteError} from "react-router-dom";

export class NavigationException extends Error {
    public statusText: string;
    public status: number;
    public data: any;

    constructor(status: number, statusText: string, data: any) {
        super();
        this.status = status;
        this.statusText = statusText;
        this.message = statusText;
        this.data = data;
    }
}


interface NavErrorProps {
    status: number;
    statusText: string;
}

function NavigationErrorElement({status} : NavErrorProps): JSX.Element {
    return <Box height="100%" sx={{display: "flex", justifyContent: "center", alignContent: "center"}}><img
        alt={status.toString()} src={`${process.env.PUBLIC_URL}/errors/${status}.jpg`} /></Box>
}

function isError(e : any): e is Error {
    return e instanceof Error;
}


function isNavigationException(e : any): e is NavigationException {
    return e instanceof NavigationException;
}

export function NavigationError(): JSX.Element {

    const error = useRouteError();
    if (isRouteErrorResponse(error)) {
        return <NavigationErrorElement status={error?.status} statusText={error?.statusText}/>
    }

    if (isNavigationException(error)) {
        return <NavigationErrorElement status={error?.status} statusText={error?.statusText}/>
    }

    if (isError(error)) {
        return <Box>
            <Typography>Exception thrown from router:</Typography>
            <Typography>{error.name}</Typography>
            <Typography>{error.stack}</Typography>
        </Box>
    }

    return <Box><Typography>Unknown error from router</Typography></Box>
}

