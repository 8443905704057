
export async function copyTextToClipboard(text: string): Promise<void> {
  if (("clipboard" in navigator) && (window.isSecureContext)) {
    // console.log("Using navigator.clipboard to copy to clipboard.");
    return await navigator.clipboard.writeText(text);
  } else {
    // deprecated but still generally works
    // https://www.30secondsofcode.org/js/s/copy-to-clipboard/
    const textAreaElement = document.createElement("textarea");
    textAreaElement.value = text;
    textAreaElement.setAttribute("readonly", "");
    textAreaElement.style.position = "absolute";
    textAreaElement.style.left = "-9999px";
    document.body.appendChild(textAreaElement);

    const selection = document.getSelection();
    const selected = !selection ? false
      : (selection.rangeCount > 0 ? selection.getRangeAt(0) : false);

    textAreaElement.select();

    const cmd_result = document.execCommand("copy");
    document.body.removeChild(textAreaElement);
    if (selection && selected) {
      selection.removeAllRanges();
      selection.addRange(selected);
    }

    if (!cmd_result) {
      throw Error("document.execCommand('copy') failed.");
    }
  }
}
