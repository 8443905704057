import {Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {GrafanaPanel} from "../../Components/GrafanaPanel";
import {useTranslation} from "react-i18next";
import {useChannelDeploymentContext} from "./DeploymentContext";
import {PmaProfile, PmaResult} from "../../Api/Types/CapacityBooster";
import {StyledDivider} from "../../OperationalCM/StyledDivider";

type ProfileGraphProps = {
    profileId?: number;
    dataIuc?: number;
    calculated: boolean;
};

export function DownstreamProfileGraph(props: ProfileGraphProps): JSX.Element {
    const {profileId, calculated} = props;

    const dashboardUrl = "/grafana/d-solo/zCgyNO0Vk/operational-deploy-downstream-channel";
    const {t} = useTranslation();
    const existingProfilePanelId = 2;
    const calculatedProfilePanelId = 5;

    const {cmts, dsChannel, macDomain} = useChannelDeploymentContext();

    const extraParams = {
        "var-cmts": cmts?.name,
        "var-mac_dom": macDomain?.ifDescr,
        "var-ds_chan": dsChannel?.ifDescr,
        "var-cur_profile_id": profileId,
        "var-boosted_profile_id": profileId,
        "from": "now-24h",
        "to": "now",
    }

    return cmts ? (
        <GrafanaPanel
            url={dashboardUrl}
            panelId={calculated ? calculatedProfilePanelId : existingProfilePanelId}
            extraParams={extraParams}
            width="100%"
            height="300px"
        />
    ) : (
        <Typography width="100%" height="300px">
            {t("deploy.loading")}
        </Typography>
    );
}

export function UpstreamProfileGraph(props: ProfileGraphProps): JSX.Element {
    const {dataIuc, calculated} = props;

    const dashboardUrl = "/grafana/d-solo/bZdfpsuVz/operational-deploy-upstream-channel";
    const {t} = useTranslation();
    const existingProfilePanelId = 2;
    const calculatedProfilePanelId = 5;

    const {cmts, usChannel, macDomain} = useChannelDeploymentContext();

    const extraParams = {
        "var-cmts": cmts?.name,
        "var-mac_dom": macDomain?.ifDescr,
        "var-us_chan": usChannel?.ifDescr,
        "var-cur_profile_id": dataIuc,
        "var-boosted_profile_id": dataIuc,
    }

    return cmts ? (
        <GrafanaPanel
            url={dashboardUrl}
            panelId={calculated ? calculatedProfilePanelId : existingProfilePanelId}
            extraParams={extraParams}
            width="100%"
            height="300px"
        />
    ) : (
        <Typography width="100%" height="300px">
            {t("deploy.loading")}
        </Typography>
    );
}

type ProfileSetDisplayProps = {
    pmaResult: PmaResult;
    downstream: boolean;
};

function DeployedProfileColumn({pmaResult, downstream}: ProfileSetDisplayProps): JSX.Element {
    const {t} = useTranslation();
    const deployedProfilesRendered = pmaResult.deployed_profiles.map(
        (profile: PmaProfile, index: number) => {
            return (
                <Grid2 key={index} xs={12} md={12} lg={12}>
                  {downstream
                    ? <DownstreamProfileGraph profileId={profile.profile_id} calculated={false} />
                    : <UpstreamProfileGraph dataIuc={profile.data_iuc} calculated={false} />
                  }
                </Grid2>
            );
        }
    );

    return (
        <>
            <Grid2 md={12} sx={{marginBottom: "20px"}}>
                <StyledDivider textAlign="left">
                    <Typography>{t("deploy.deployed_profiles")}</Typography>
                </StyledDivider>
            </Grid2>
            <Grid2
                md={12}
                sx={{
                    //   border: "1px solid #C6C6C6",
                    borderRadiusTopLeft: "10px",
                    borderRadiusBottomLeft: "10px",
                }}
            >
                {deployedProfilesRendered.map((item) => item)}
            </Grid2>
        </>
    );
}

function DefaultProfileColumn({pmaResult, downstream}: ProfileSetDisplayProps): JSX.Element {
    const {t} = useTranslation();
    const deployedProfilesRendered = pmaResult.default_profiles.map(
        (profile: PmaProfile,  index: number) => {
            return (
                <Grid2 key={index} xs={12} md={12} lg={12}>
                  {downstream
                    ? <DownstreamProfileGraph profileId={profile.profile_id} calculated={false} />
                    : <UpstreamProfileGraph dataIuc={profile.data_iuc} calculated={false} />
                  }
                </Grid2>
            );
        }
    );

    return (
        <>
            <Grid2 md={12} sx={{marginBottom: "20px"}}>
                <StyledDivider
                    textAlign="left"
                    sx={{
                        color: "#33751F",
                        "&::before, &::after": {
                            borderColor: "#33751F",
                        },
                    }}>
                    <Typography textAlign="right">{t("deploy.default_profiles")}</Typography>
                </StyledDivider>
            </Grid2>
            <Grid2
                md={12}
                sx={{
                    //   border: "1px solid #C6C6C6",
                    borderRadiusTopRight: "10px",
                    borderRadiusBottomRight: "10px"
                }}
            >
                {deployedProfilesRendered.map((item) => item)}
            </Grid2>
        </>
    );
}

function CalculatedProfileColumn({pmaResult, downstream}: ProfileSetDisplayProps): JSX.Element {
    const {t} = useTranslation();
    const calculatedProfilesRendered = pmaResult.calculated_profiles.map(
        (profile: PmaProfile, index: number) => {
            return (
                <Grid2 key={index} xs={12} md={12} lg={12}>
                  {downstream
                    ? <DownstreamProfileGraph profileId={profile.profile_id} calculated={true} />
                    : <UpstreamProfileGraph dataIuc={profile.data_iuc} calculated={true} />
                  }
                </Grid2>
            );
        }
    );

    return (
        <>
            <Grid2 md={12} sx={{marginBottom: "20px"}}>
                <StyledDivider
                    textAlign="left"
                    sx={{
                        color: "#33751F",
                        "&::before, &::after": {
                            borderColor: "#33751F",
                        },
                    }}
                >
                    <Typography>{t("deploy.calculated_profiles")}</Typography>
                </StyledDivider>
            </Grid2>
            <Grid2
                md={12}
                sx={{
                    // border: "1px solid #C6C6C6",
                    borderRadiusTopRight: "10px",
                    borderRadiusBottomRight: "10px"
                }}>
                {calculatedProfilesRendered.map((item) => item)}
            </Grid2>
        </>
    );
}

export function ProfileSetDisplay(props: ProfileSetDisplayProps): JSX.Element {

    return (
        <Grid2
            md={12}
            spacing={2}
            container
        >
            <Grid2 md={6}>
                <DeployedProfileColumn {...props}/>
            </Grid2>
            <Grid2 md={6}>
                <CalculatedProfileColumn {...props}/>
                {/*<DefaultProfileColumn {...props}/>*/}
            </Grid2>
        </Grid2>
    );
}
