import {
    Autocomplete,
    Chip,
    TextField
} from "@mui/material";
import React from "react";
import {CmtsTag} from "../Api/Types/Config";

export type BaseTagFieldProps = {
   initValue?: any[];
   initTags?: CmtsTag[] | null;
   onChange?: (values: any[]) => void;
}

export type TagFieldProps = BaseTagFieldProps;

function TagInput(params: any): JSX.Element {
    const {
        inputProps,
        ...rest
    } = params;

    inputProps['data-testid'] = 'tagInput';

    return (
        <TextField
            {...rest}
            inputProps={inputProps}
            label="Tags"
        />
    );
}

export function TagField(props: TagFieldProps): JSX.Element {
    const {
        initValue,
        initTags,
        onChange
    } = props;

    const [values, setValues] = React.useState<string[]>([]);
    const [defaultTags, setDefaultTags] = React.useState<any[]>([]);

    React.useEffect(() => {
        if (initValue) {
            setValues(initValue);
        }
    }, [setValues]);

    React.useEffect(() => {
        if (initTags) {
            setDefaultTags(initTags);
        }
    }, [
        initTags,
        setDefaultTags
    ]);

    const onValueChange = (evt: any, newValue: string[]): void => {
        setValues(newValue);
        onChange?.(newValue);
    }

    return (
       <Autocomplete
            multiple
            data-testid="tags"
            role="combobox"
            id="tags"
            options={defaultTags.map((elem) => elem.name)}
            freeSolo
            clearOnBlur
            onChange={onValueChange}
            value={values}
            renderTags={(value: readonly any[], getTagProps) =>
              value.map((option: any, index: number) =>(
                  <Chip variant="outlined" label={option} key={index}/>
              ))
            }
            renderInput={TagInput}
       />
   );
}

