import {ReactSearchAutocomplete} from "react-search-autocomplete";
import {useCallback, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {ApiRoutes} from "../Api/ApiRoutes";
import {useFeedbackContext} from "../Feedback/FeedbackContext";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material";


export enum SearchItemType {
    Mac = "MAC",
    DsChannel = "Downstream Channel",
    UsChannel = "Upstream Channel",
}

export type MacMeta = {
    cmts: string,
    mac_domain: string,
}

export type ChannelMeta = {
    cmts: string,
    mac_domain: string,
}

export type SearchItem = {
    id: number,
    itemType: SearchItemType,
    search: string,
    title: string,
    detail: string,
    meta: MacMeta | ChannelMeta,
};

export type SearchDataResult = {
    time_start: string;
    time_end: string;
    data: SearchItem[];
}

const Urls = {
    OperationalCm: "/grafana/d/lXsaTFwnz/operational-cm",
    OperationalDsChannel: "/grafana/d/pkpIPDl7k/operational-downstream-channel-rxmer",
    OperationalUsChannel: "/grafana/d/Os6m0A_nk/operational-upstream-channel-rxmer",
}

function FormatUrl(searchItem: SearchItem): string {
    switch (searchItem.itemType) {
        case SearchItemType.Mac:
            return FormatMacUrl(searchItem);
        case SearchItemType.DsChannel:
            return FormatDsChannelUrl(searchItem);
        case SearchItemType.UsChannel:
            return FormatUsChannelUrl(searchItem);
        default:
        // TODO error
    }
    return ""
}

function encodeParams(parameters: object): string {
    return Object.entries(parameters).map(([key, value]) => {
        if (!value) {
            return `${encodeURIComponent(key)}=`;
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }).join('&');
}

function FormatMacUrl(searchItem: SearchItem): string {
    const parameters = {
        // orgId: 1,
        "var-cmts": searchItem.meta.cmts,
        "var-mac_dom": searchItem.meta.mac_domain,
        "var-mac_addr": searchItem.title,
        // "var-ds_chan": searchItem.meta.ds_chan,
        // "var-us_chan": searchItem.meta.us_chan,
        // "var-ds_rxmer_timestamp": null,
        // "var-us_rxmer_timestamp": null,
        // "from": from,
        // "to": to,
    }

    return `${Urls.OperationalCm}?${encodeParams(parameters)}`
}

function FormatDsChannelUrl(searchItem: SearchItem): string {
    const parameters = {
        // orgId: 1,
        "var-cmts": searchItem.meta.cmts,
        "var-mac_dom": searchItem.meta.mac_domain,
        "var-ds_chan": searchItem.title,
        // "var-ds_chan": searchItem.meta.ds_chan,
        // "var-us_chan": searchItem.meta.us_chan,
        // "var-ds_rxmer_timestamp": null,
        // "var-us_rxmer_timestamp": null,
        // "from": from,
        // "to": to,
    }

    return `${Urls.OperationalDsChannel}?${encodeParams(parameters)}`
}

function FormatUsChannelUrl(searchItem: SearchItem): string {
    const parameters = {
        // orgId: 1,
        "var-cmts": searchItem.meta.cmts,
        "var-mac_dom": searchItem.meta.mac_domain,
        "var-us_chan": searchItem.title,
        // "var-ds_chan": searchItem.meta.ds_chan,
        // "var-us_chan": searchItem.meta.us_chan,
        // "var-ds_rxmer_timestamp": null,
        // "var-us_rxmer_timestamp": null,
        // "from": from,
        // "to": to,
    }

    return `${Urls.OperationalDsChannel}?${encodeParams(parameters)}`
}

function SearchListing({item}: { item: SearchItem }): JSX.Element {
    const theme = useTheme();

    return <>
        <span style={{display: 'block', textAlign: 'left'}}>{item.itemType}: <strong>{item.title}</strong></span>
        <span style={{display: 'block', textAlign: 'left', color: theme.palette.text.primary[600]}}>
         <small><em>{item.detail}</em></small>
      </span>
    </>
}


export function Search({sx, color}: { sx?: any, color?: string }): JSX.Element {
    const [searchData, setSearchData] = useState<SearchItem[]>([]);
    const [searchResults, setSearchResults] = useState<SearchItem[]>([]);
    const {setFeedbackState} = useFeedbackContext();
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {

        fetch(
            ApiRoutes.pma.search,
            {
                method: "GET",
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                },
            }
        ).then(
            async (response: Response) => {
                if (!response.ok) {
                    setFeedbackState({
                        open: true,
                        message: `${t('search.failed_search')}: ${response.statusText}`,
                        severity: "error"
                    });

                    return;
                }

                const result: SearchDataResult = await response.json();
                // console.log(result)
                setSearchData(result.data);
            }
        );
    }, []);

    const handleOnSelect = useCallback(
        (item: SearchItem): void => {
            // the item selected
            // console.log("Search Item: ", item)
            const newUrl = FormatUrl(item);
            switch (item.itemType) {
                case SearchItemType.Mac:
                    window.open(newUrl, "_blank");
                    break;
                case SearchItemType.DsChannel:
                    window.open(newUrl, "_blank");
                    break;
                case SearchItemType.UsChannel:
                    window.open(newUrl, "_blank");
                    break;
                default:
                    // console.log("Default Behavior")
                    navigate(newUrl);
            }
        }, [navigate]);

    const handleOnSearch = useCallback((string: string, results: SearchItem[]): void => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        // console.log(string, results)
        setSearchResults(results);

    }, [navigate, handleOnSelect]);

    const handleOnSubmit = useCallback(() => {
        if (searchResults.length === 1) {
            handleOnSelect(searchResults[0]);
        }
    }, [searchResults]);

    // const handleOnHover = (result: any): void => {
    //     // the item hovered
    //     console.log(result)
    // }


    // const handleOnFocus = (): void => {
    //     // console.log('Focused')
    // }

    const formatResult = (item: SearchItem): JSX.Element => {
        return (
            <SearchListing item={item} />
        )
    }

    const fuseOptions = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
            "title", "search"
        ]
    };

    return <StyledSearchAutocomplete color={color}>
        <form onSubmit={handleOnSubmit}>
            <ReactSearchAutocomplete
                placeholder={t("search.placeholder")}
                items={searchData}
                onSearch={handleOnSearch}
                // onHover={handleOnHover}
                onSelect={handleOnSelect}
                // onFocus={handleOnFocus}
                autoFocus={false}
                formatResult={formatResult}
                fuseOptions={fuseOptions}
                resultStringKeyName="title"
                showNoResultsText={searchData.length ? t("search.no_results") : t("search.loading")}
                showItemsOnFocus={true}
                styling={sx}
            />
        </form>
    </StyledSearchAutocomplete>
}


const StyledSearchAutocomplete = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: row;

  & > form {
    width: 100%;
    & > div {
      width: 100%;

      & > .wrapper {
        // border-color: ${props => props.color};
        border-color: rgba(33, 49, 98, 0.6);
        border-width: .5px;
        border-radius: 30px;
        background-color: #F8F9FF;

        & > div {
          
          & > input {
            height: 46px;
            font-family: 'Encode Sans', sans-serif;
            font-size: 14px;
            color: #555555;
            padding-left: 30px;
          }
          
          & > input + {
            font-family: 'Encode Sans', sans-serif;
          }

          & > .search-icon {
            display: none;
          }

          &:first-of-type:after {
            content: '';
            background-image: url("/icons/SearchOutline.svg");
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            height: 18px;
            width: 18px;
            margin-right: 20px;
          }
        }
      }
    }
  }
`
