import React, { createContext, useContext, useMemo, useState } from 'react';
import {CalcConstraintTemplateProfile} from '../Api/Types/Config';

type Props = {
  initialGlobalTemplateProfilesState?: GlobalTemplateProfilesState;
  children: JSX.Element[] | JSX.Element;
};

export type TemplateProfilesGlobalConfig = {
  downstreamTemplateProfiles: CalcConstraintTemplateProfile | null;
  upstreamTemplateProfiles: CalcConstraintTemplateProfile | null;
};

export type GlobalTemplateProfilesState = {
  templateProfilesGlobalConfig: TemplateProfilesGlobalConfig;
  globalTemplateProfilesLoaded: boolean;
};

export const DefaultGlobalTemplateProfilesState: GlobalTemplateProfilesState = {
  templateProfilesGlobalConfig: {
    downstreamTemplateProfiles: null,
    upstreamTemplateProfiles: null
  },
  globalTemplateProfilesLoaded: false
};

export type GlobalTemplateProfilesContextType = {
  globalTemplateProfilesState: GlobalTemplateProfilesState;
  setGlobalTemplateProfilesState: (globalProfilesConfig: GlobalTemplateProfilesState) => void;
};

const defaultGlobalTemplateProfilesContext = {
  globalTemplateProfilesState: DefaultGlobalTemplateProfilesState,
  setGlobalTemplateProfilesState: () => {}
};

const GlobalTemplateProfilesContext = createContext<GlobalTemplateProfilesContextType>(
  defaultGlobalTemplateProfilesContext,
);

export const GlobalTemplateProfilesContextProvider = ({ initialGlobalTemplateProfilesState, children }: Props) => {
  const defaultGlobalProfilesState = initialGlobalTemplateProfilesState
    ? initialGlobalTemplateProfilesState
    : DefaultGlobalTemplateProfilesState;
  const [globalProfilesState, setGlobalProfilesState] = useState<GlobalTemplateProfilesState>(
    defaultGlobalProfilesState
  );

  const ctx = useMemo<GlobalTemplateProfilesContextType>(
    () => ({
      globalTemplateProfilesState: globalProfilesState,
      setGlobalTemplateProfilesState: setGlobalProfilesState
    }),
    [globalProfilesState]
  );

  return <GlobalTemplateProfilesContext.Provider value={ctx}>{children}</GlobalTemplateProfilesContext.Provider>;
};

export const useGlobalTemplateProfilesContext = (): GlobalTemplateProfilesContextType =>
  useContext(GlobalTemplateProfilesContext);

export function handleGlobalTemplateProfilesQuery(
  response: (CalcConstraintTemplateProfile | null)[],
  setGlobalTemplateProfilesState: (globalTemplateProfilesState: GlobalTemplateProfilesState) => void
): void {
  const globalTemplateProfilesState: GlobalTemplateProfilesState = {
    templateProfilesGlobalConfig: {
      downstreamTemplateProfiles: null,
      upstreamTemplateProfiles: null
    },
    globalTemplateProfilesLoaded: true
  };

  if (response.length === 0) return;

  response.forEach((value: CalcConstraintTemplateProfile | null) => {
    if (value) {
      switch (value.channel_direction) {
        case 'D':
          globalTemplateProfilesState.templateProfilesGlobalConfig.downstreamTemplateProfiles = value;
          break;
        case 'U':
          globalTemplateProfilesState.templateProfilesGlobalConfig.upstreamTemplateProfiles = value;
          break;
        default:
          break;
      }
    }
  });

  setGlobalTemplateProfilesState(globalTemplateProfilesState);
}
