import { useState, FormEvent, useEffect } from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ApiLogin } from "../Api/Util";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../Authentication/AuthContext";

export function Login(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [tryAttempt, setTryAttempt] = useState<number>(0);
  const [showResetScreen, setShowResetScreen] = useState<Boolean>(false);

  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<Boolean>(false);

  const { userToken, login } = useAuthContext();
  const { redirectTo } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    const { next } = Object.fromEntries(new URLSearchParams(location.search));
    if (userToken) {
      if (!next && !redirectTo) {
        navigate("/");
      } else if(next) {
        window.location.href = next
      } else {
        navigate(redirectTo);
      }
    }
  }, [userToken]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username: string = data.get("username") as string;
    const password: string = data.get("password") as string;

    setLoading(true);
    setErrorMessage("");
    setTryAttempt(tryAttempt + 1);

    if (username && password) {
      ApiLogin(username, password)
        .then(async (response: any) => {
          login(response.token.access_token, response.token.refresh_token);
        })
        .catch((error) => {
          if (tryAttempt >= 2) {
            setShowResetScreen(true);
          }
          if (error.status === 401) {
            setErrorMessage(t("login.incorrect_credential"));
          } else if (error.status === 500) {
            setErrorMessage(t("login.server_error"));
          } else {
            setErrorMessage(t("login.unknown_error"));
          }
          setLoading(false);
        });
    } else {
      console.log("Username and password not filled in");
    }
  };

  const resetTryAttempts = () => {
    setTryAttempt(0);
    setShowResetScreen(false);
    setErrorMessage("");
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{
        backgroundImage: `
        url(/OV-Globe.png),
        linear-gradient(180deg, rgba(1,7,26,1) 3%, rgba(8,31,102,1) 33%, rgba(11,46,154,1) 55%, rgba(19,30,64,1) 97%)
      `,
        backgroundSize: "90%, cover",
        backgroundPosition: "left -600px bottom -300px, center center",
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        sx={{
          background: `
        linear-gradient(164deg, rgba(210,222,255,1) 4%, rgba(216,253,255,1) 36%, rgba(198,252,255,1) 50%, rgba(205,252,255,1) 70%, rgba(222,255,219,1) 95%),
        #EAEFF5
      `,
          borderRadius: matchSM ? "" : "22px",
          border: "2px solid #04822F",
          maxWidth: matchSM ? "100%" : "540px",
          height: matchSM ? "100vh" : "100%",
        }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <Box
            component="img"
            alt="Openvault Logo"
            src="/ovLogoDark.svg"
            maxWidth="320px"
            alignSelf="center"
            marginBottom="20px"
          />
          <Typography
            variant="h5"
            color="#04822F"
            fontWeight="400"
            alignSelf="center"
            marginBottom="30px"
          >
            {t("login.title")}
          </Typography>
          {showResetScreen ? (
            <>
              <Typography
                color="error"
                fontWeight="500"
                fontSize="20px"
                textAlign="center"
                marginBottom="10px"
              >
                {t("login.reset_screen_message")}
              </Typography>
              <Link
                to={`#`}
                onClick={resetTryAttempts}
                style={{
                  alignSelf: "center",
                  marginTop: "20px",
                }}
              >
                <Typography>{t("login.reset_screen_back")}</Typography>
              </Link>
            </>
          ) : (
            <>
              {errorMessage && (
                <Typography
                  color="error"
                  sx={{
                    backgroundColor: "rgba(187, 31, 31, .1)",
                    borderLeft: "5px solid #BB1F1F",
                    paddingLeft: "10px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    fontWeight: "600",
                  }}
                >
                  {errorMessage}
                </Typography>
              )}
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Enter Email Address"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  InputLabelProps={{
                    style: { color: "#555" },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon>
                          <MailOutlineIcon />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    "data-testid": "username",
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Enter Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  InputLabelProps={{
                    style: { color: "#555" },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    "data-testid": "password",
                  }}
                />
                <Button
                  type="submit"
                  data-testid="submit"
                  disabled={loading === true}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 3,
                    mb: 2,
                    padding: "10px 16px",
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  {loading ? "Loading" : "Log In"}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
