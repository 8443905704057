import * as React from "react";
import { useState, Dispatch, SetStateAction } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LaunchIcon from "@mui/icons-material/Launch";
import { Copyright } from "../Copyright";
import { AppBar } from "./AppBar";
import { Drawer } from "./Drawer";
import { MainMenu } from "../Navigation/MainMenu";
import {Outlet, useLocation, useOutletContext} from "react-router-dom";
import { Badge, useTheme } from "@mui/material";
import { Search } from "./Search";
import { AiOutlineBell } from "react-icons/ai";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconContext } from "react-icons";
import { UserDropdown } from "./NavIcons/UserDropdown";
import { FeedbackContextProvider } from "../Feedback/FeedbackContext";
import { useFeedbackContext } from "../Feedback/FeedbackContext";
import {useAuthContext} from "../Authentication/AuthContext";

export type GrafanaLinkProp = {
  grafanaLink: string;
  setGrafanaLink: Dispatch<SetStateAction<string>>;
  setGrafanaLinkButton: Dispatch<SetStateAction<boolean>>;
};

function DashboardContent(): JSX.Element {
  const [grafanaLink, setGrafanaLink] = useState("");
  const [grafanaLinkButton, setGrafanaLinkButton] = useState<boolean>(true);

  const { feedbackError } = useFeedbackContext();

  const [navBarOpen, setNavBarOpen] = useState<boolean>(false);

  const toggleDrawer = (): void => {
    setNavBarOpen(!navBarOpen);
  };

  const openDrawer = (): void => {
    setNavBarOpen(true);
  };

  const closeDrawer = (): void => {
    setNavBarOpen(false);
  };

  const grafanaLinkHandler = (): void => {
    if (grafanaLink === "") {
      feedbackError("Oops, not available from this page.");
    } else {
        window.open(`${window.location.origin}${grafanaLink}`);
    }
  };

  const getLogo = () => {
    let altText = "Openvault Logo";
    let src = "/ov_logo_typeface.svg";
    const location = window.location.href;

      if(location.includes("mediacomlab.pma")){
        altText = "Mediacom Logo";
        src = "/Mediacom_Logo.svg";
      }else if(location.includes("secv.pma")){
        altText = "SECV Logo";
        src = "/SE_Logo.svg";
      }else if(location.includes("nos.")){
        altText = "NOS Logo";
        src = "/NOS_Logo.svg";
      }else if(location.includes("midco.")){
        altText = "Midco Logo";
        src = "/Midco_Logo.svg";
      }else if(location.includes("ta.pma")){
        altText = "TA Logo";
        src = "/TA_Logo.svg";
      }else if(location.includes("brctv.")){
        altText = "Blue Ridge Logo";
        src = "/BRCTV_Logo.svg";
      }


    return (
      <Box
        component="img"
        alt={altText}
        src={src}
        minWidth="100px"
        maxWidth="150px"
      />
    );
  }

  const theme = useTheme();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={navBarOpen}
        onMouseEnter={openDrawer}
        onMouseLeave={closeDrawer}
      >
        <Toolbar
          onClick={toggleDrawer}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            // px: [1]
            // pl: "18px",
            "&.MuiToolbar-root": {
              pl: "18px",
            },
          }}
        >
          <Typography sx={{ display: "flex" }}>
            <img
              src={"/icons/SM_OV_WHT_LOGO_4.svg"}
              height="33 px"
              alt="OpenVault"
            />
          </Typography>
          {navBarOpen && (
            <Box
              component="img"
              alt="Openvault"
              src="/ov-typeface-white.svg"
              width="200px"
              sx={{
                ml: "12px",
                transition: "all 5s"
              }}
            />
          )}
        </Toolbar>
        <Divider />
        <MainMenu expanded={navBarOpen} />
      </Drawer>

      <AppBar position="absolute" open={navBarOpen}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ marginRight: "40px" }}>
            {getLogo()}
          </Box>
          <Search
            sx={{
              width: "100%",
              borderColor: theme.palette.primary.main,
              // color: theme.palette.primary.main,
              color: "rgba(33, 49, 98, 0.6",
              borderWidth: "4px",
              height: "46px",
              boxShadow: "none",
              borderRadius: "4px",
            }}
            color={theme.palette.primary.main}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
              justifyContent: "flex-end",
              gap: "20px",
            }}
          >
            <IconContext.Provider value={{ size: "24px" }}>
              {grafanaLinkButton && (
                <IconButton onClick={grafanaLinkHandler}>
                  <Box
                    component="img"
                    alt='Grafana'
                    src={'/graf_icon.svg'}
                    minWidth="25px"
                    maxWidth="50px"
                  />
                </IconButton>
              )}

              <UserDropdown />

            </IconContext.Provider>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.colors.background.main
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Container maxWidth="xl" sx={{ mt: "96px", mb: 4 }}>
          <Outlet
            context={{ grafanaLink, setGrafanaLink, setGrafanaLinkButton }}
          />
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}

export default function Dashboard(): JSX.Element {
  return (
    <FeedbackContextProvider>
      <DashboardContent />
    </FeedbackContextProvider>
  );
}

export function useGrafanaLink() {
  return useOutletContext<GrafanaLinkProp>();
}
