import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { ApiGet, ApiLogout, ApiRefreshAccessToken } from '../Api/Util';
import { ApiRoutes } from '../Api/ApiRoutes';

type Props = {
  children: JSX.Element[] | JSX.Element;
};

export type AuthContextType = {
  userToken: string;
  refreshToken: string;
  login: (userToken: string, refreshToken: string) => void;
  logout: () => void;
  userInfo: any;
};

const defaultAuthContext = {
  userToken: '',
  refreshToken: '',
  login: (userToken: string, refreshToken: string) => {},
  logout: () => {},
  userInfo: { first_name: '', last_name: '', email: '', role: '' }
};

const AuthContext = createContext(defaultAuthContext);

export const AuthContextProvider = ({ children }: Props) => {
  const [cookies, setCookie, removeCookie] = useCookies(['userToken', 'refreshToken', 'userTokenExpiration']);
  const [userToken, setUserToken] = useState<string>(cookies.userToken || null);
  const [refreshToken, setRefreshToken] = useState<string>(cookies.refreshToken || null);
  const [userInfo, setUserInfo] = useState<any>({});

  const login = (userToken: string, refreshToken: string) => {
    setUserToken(userToken);
    setRefreshToken(refreshToken);
    const refreshExpires = new Date();
    refreshExpires.setDate(refreshExpires.getDate() + 30);
    const tokenExpires = new Date();
    tokenExpires.setHours(tokenExpires.getHours() + 24);
    setCookie('userToken', userToken, { path: '/', expires: tokenExpires, sameSite: 'lax' });
    setCookie('refreshToken', refreshToken, { path: '/', expires: refreshExpires, sameSite: 'lax' });
    setCookie('userTokenExpiration', tokenExpires.toString(), { path: '/', expires: tokenExpires, sameSite: 'lax' });
  };

  const fetchUserInfo = () => {
    // TODO: Fix constant refiring
    ApiGet(ApiRoutes.auth.user_info)
      .then(async (response: any) => {
        setUserInfo(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const refreshUserToken = () => {
    const userTokenExpCookie = new Date(decodeURIComponent(cookies.userTokenExpiration));
    if (userTokenExpCookie && userTokenExpCookie <= new Date()){
      ApiRefreshAccessToken();
    }
  }

  useEffect(() => {
    const userTokenExpCookie = new Date(decodeURIComponent(cookies.userTokenExpiration));
    const today = new Date();
    let interval: number = 900000; // 15 minutes
    if (userTokenExpCookie && userTokenExpCookie > new Date()) {
      interval = (userTokenExpCookie.getTime() - today.getTime());
    }
    const refresh = setInterval(() => {
      refreshUserToken();
    }, interval);
    return () => clearInterval(refresh);
  }, [cookies.userTokenExpiration]);

  useEffect(() => {
    if (userToken) {
      fetchUserInfo();
    }
  }, [userToken]);

  const logout = () => {
    ApiLogout<null>(refreshToken)
      .then(async (response: any) => {
        setUserToken('');
        setRefreshToken('');
        removeCookie('userToken', { path: '/' });
        removeCookie('refreshToken', { path: '/' });
        removeCookie('userTokenExpiration', { path: '/' })
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <AuthContext.Provider value={{ userToken, userInfo, refreshToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
