export type TimeUnit = "seconds" | "minutes" | "hours";

export function SecondsToTimeInterval(time_seconds: number): any[] {
    if (time_seconds % (60 * 60) === 0) {
        return [time_seconds / (60 * 60), "hours"];
    } else if (time_seconds % (60) === 0) {
        return [time_seconds / (60), "minutes"];
    }

    return [time_seconds, "seconds"];
}

export function TimeIntervalToSeconds(time: number, time_unit: TimeUnit): number {
    if (!time && !time_unit) {
        return -1;
    }

    let time_seconds = time;
    if (time_unit === "minutes") {
        time_seconds = time * 60;
    } else if (time_unit === "hours") {
        time_seconds = time * 60 * 60;
    }

    return time_seconds;
}

export function DateFormatted(time: string): string {
    const date = new Date(time);
    const dateFormat =
      date.toDateString() +
      ' ' +
      date.getHours() +
      ':' +
      date.getMinutes();
    return dateFormat;
}

// For use with JSON.stringify().
//    See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/BigInt#use_within_json
export function BigIntReplacer(key: string, value: any): any {
    return typeof value === "bigint" ? value.toString() : value;
}
