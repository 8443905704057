import React, {useEffect, useMemo, useState} from "react";
import {Cmts, CmtsTag} from "../Api/Types/Config";
import {FeedbackContextProvider, useFeedbackContext} from "../Feedback/FeedbackContext";
import { useGrafanaLink } from "../Dashboard/Dashboard";
import {DsChannel, MacDomain, UsChannel} from "../Api/Types/Topology";
import {useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import {RegionSelectorComponent} from "../Components/Selectors/RegionSelectorComponent";
import {CmtsSelectorComponent} from "../Components/Selectors/CmtsSelectorComponent";
import {MacDomainSelectorComponent} from "../Components/Selectors/MacDomainSelectorComponent";
import {ChannelSelectorComponent} from "../Components/Selectors/ChannelSelectorComponent";
import {ChannelActivity} from "./ChannelActivity";
import {useTranslation} from "react-i18next";

type IdMap<T> = { [key: string]: T };
function DeploymentComponent(): JSX.Element {
  const { t } = useTranslation();
  const {feedbackError} = useFeedbackContext();

  const {setGrafanaLinkButton} = useGrafanaLink();

  const [cmtsTagIdToCmtsTag, setCmtsTagIdToCmtsTag] = useState<IdMap<CmtsTag>>(
    {}
  );
  const [cmtsIdToCmts, setCmtsIdToCmts] = useState<IdMap<Cmts>>({});
  const [macDomainIdToMacDomain, setMacDomainIdToMacDomain] = useState<
    IdMap<MacDomain>
  >({});
  const [channelIdToChannel, setChannelIdToChannel] = useState<
    IdMap<DsChannel | UsChannel>
  >({});
    
  useEffect(() => {
    setGrafanaLinkButton(false);
  }, []);

  const [searchParams, setSearchParams] = useSearchParams({
    region: "all",
    cmts: "",
    macdomain: "",
    channel: "",
  });

  const cmtsTagParam: string[] | null = searchParams.getAll("region");
  const [currentCmtsTagIds, setCurrentCmtsTagIds] = useState<string[]>(
    cmtsTagParam ? cmtsTagParam : []
  );
  const cmtsParam: string | null = searchParams.get("cmts");
  const [currentCmtsId, setCurrentCmtsId] = useState(
    cmtsParam ? cmtsParam : ""
  );
  const macDomainParam = searchParams.get("macdomain");
  const [currentMacDomainId, setCurrentMacDomainId] = useState(
    macDomainParam ? macDomainParam : ""
  );
  const channelParam: string | null = searchParams.get("channel");
  const [currentChannelId, setCurrentChannelId] = useState(
    channelParam ? channelParam : ""
  );

  const macDomainIfIndex = Object.hasOwn(
    macDomainIdToMacDomain,
    currentMacDomainId
  )
    ? macDomainIdToMacDomain[currentMacDomainId].ifIndex.toString()
    : "";

  const channelIfIndex = Object.hasOwn(
    channelIdToChannel,
    currentChannelId)
    ? channelIdToChannel[currentChannelId].ifIndex.toString()
    : "";

  const cmtsTagsParamCollection: number[] = useMemo(() => {
    const collection: number[] = [];

    if (Object.keys(cmtsTagIdToCmtsTag).length > 0) {
      currentCmtsTagIds.map((cmtsTagId) => {
        if (cmtsTagId !== "all") {
          if (cmtsTagId && Object.hasOwn(cmtsTagIdToCmtsTag, cmtsTagId)) {
            collection.push(Number(cmtsTagIdToCmtsTag[cmtsTagId]?.id));
          } else {
            feedbackError(t('audit.cmts_tag_error'));
          }
        }
      });
    }

    return collection;
  }, [currentCmtsTagIds, cmtsTagIdToCmtsTag]);

  useEffect(() => {
    setSearchParams({
      region: currentCmtsTagIds,
      cmts: currentCmtsId,
      macdomain: currentMacDomainId,
      channel: currentChannelId,
    });
  }, [currentCmtsTagIds, currentCmtsId, currentMacDomainId, currentChannelId]);

  return (
    <Grid container spacing={2}>
      <Grid xs={12} display="flex" padding="0px">
        <RegionSelectorComponent
          width={3}
          cmtsTagIdToCmtsTag={cmtsTagIdToCmtsTag}
          setCurrentCmtsTagIds={setCurrentCmtsTagIds}
          setCmtsTagIdToCmtsTag={setCmtsTagIdToCmtsTag}
          currentCmtsTagIds={currentCmtsTagIds}
        />
        <CmtsSelectorComponent
          width={3}
          currentCmtsId={currentCmtsId}
          setCurrentCmtsId={setCurrentCmtsId}
          cmtsIdToCmts={cmtsIdToCmts}
          setCmtsIdToCmts={setCmtsIdToCmts}
          region={cmtsTagsParamCollection}
        />
        <MacDomainSelectorComponent
          width={3}
          cmtsId={currentCmtsId}
          currentMacDomainId={currentMacDomainId}
          setCurrentMacDomainId={setCurrentMacDomainId}
          macDomainIdToMacDomain={macDomainIdToMacDomain}
          setMacDomainIdToMacDomain={setMacDomainIdToMacDomain}
          region={cmtsTagsParamCollection}
        />
        <ChannelSelectorComponent
          width={3}
          cmtsId={currentCmtsId}
          macDomainIfIndex={macDomainIfIndex}
          currentChannel={currentChannelId}
          setCurrentChannel={setCurrentChannelId}
          channelIdToChannel={channelIdToChannel}
          setChannelIdToChannel={setChannelIdToChannel}
        />
      </Grid>
      <Grid xs={12} padding={0}>
        <ChannelActivity cmtsId={currentCmtsId} macDomainIfIndex={macDomainIfIndex} channelIfIndex={channelIfIndex} />
      </Grid>
    </Grid>
  );
}

export function Deployment(): JSX.Element {
  return <FeedbackContextProvider>
    <DeploymentComponent />
  </FeedbackContextProvider>;
}
