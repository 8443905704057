import React, { createContext, useContext, useMemo, useState } from 'react';
import { CalcMaxProfilesInfo } from '../Api/Types/Config';

export type GlobalDsUsCalcMaxProfilesInfo = {
  dsCalcMaxProfilesInfo: CalcMaxProfilesInfo | null;
  usCalcMaxProfilesInfo: CalcMaxProfilesInfo | null;
};

export type GlobalCalcMaxProfilesState = {
  globalDsUsCalcMaxProfilesInfo: GlobalDsUsCalcMaxProfilesInfo;
  globalCalcMaxProfilesLoaded: boolean;
};

export const defaultGlobalCalcMaxProfilesState: GlobalCalcMaxProfilesState = {
  globalDsUsCalcMaxProfilesInfo: {
    dsCalcMaxProfilesInfo: null,
    usCalcMaxProfilesInfo: null
  },
  globalCalcMaxProfilesLoaded: false
};

export type GlobalCalcMaxProfilesContext = {
  globalCalcMaxProfilesState: GlobalCalcMaxProfilesState;
  setGlobalCalcMaxProfilesState: (globalCalcMaxProfilesState: GlobalCalcMaxProfilesState) => void;
};

const defaultGlobalCalcMaxProfilesContext: GlobalCalcMaxProfilesContext = {
  globalCalcMaxProfilesState: defaultGlobalCalcMaxProfilesState,
  setGlobalCalcMaxProfilesState: () => {}
};

const GlobalCalcMaxProfilesContext = createContext<GlobalCalcMaxProfilesContext>(
  defaultGlobalCalcMaxProfilesContext
);

type Props = {
  initialGlobalCalcMaxProfilesState?: GlobalCalcMaxProfilesState;
  children: JSX.Element[] | JSX.Element;
};

export const GlobalCalcMaxProfilesContextProvider = ({ initialGlobalCalcMaxProfilesState, children }: Props) => {
  const providerInitialGlobalCalcMaxProfilesState = initialGlobalCalcMaxProfilesState
    ? initialGlobalCalcMaxProfilesState
    : defaultGlobalCalcMaxProfilesState;
  const [globalCalcMaxProfilesState, setGlobalCalcMaxProfilesState]
    = useState<GlobalCalcMaxProfilesState>(providerInitialGlobalCalcMaxProfilesState);

  const ctx = useMemo<GlobalCalcMaxProfilesContext>(
    () => ({
      globalCalcMaxProfilesState: globalCalcMaxProfilesState,
      setGlobalCalcMaxProfilesState: setGlobalCalcMaxProfilesState
    }),
    [globalCalcMaxProfilesState]
  );

  return <GlobalCalcMaxProfilesContext.Provider value={ctx}>{children}</GlobalCalcMaxProfilesContext.Provider>;
};

export const useGlobalCalcMaxProfilesContext = (): GlobalCalcMaxProfilesContext =>
  useContext(GlobalCalcMaxProfilesContext);

export function handleGlobalCalcMaxProfilesQuery(
  response: (CalcMaxProfilesInfo | null)[],
  setGlobalCalcMaxProfilesState: (globalCalcMaxProfilesState: GlobalCalcMaxProfilesState) => void
): void {
  const newGlobalCalcMaxProfilesState: GlobalCalcMaxProfilesState = {
    globalDsUsCalcMaxProfilesInfo: {
      dsCalcMaxProfilesInfo: null,
      usCalcMaxProfilesInfo: null
    },
    globalCalcMaxProfilesLoaded: true
  };

  if (response.length === 0) return;

  response.forEach((calcMaxProfilesInfo: CalcMaxProfilesInfo | null) => {
    if (calcMaxProfilesInfo) {
      switch (calcMaxProfilesInfo.channel_direction) {
        case 'D':
          newGlobalCalcMaxProfilesState.globalDsUsCalcMaxProfilesInfo.dsCalcMaxProfilesInfo = calcMaxProfilesInfo;
          break;
        case 'U':
          newGlobalCalcMaxProfilesState.globalDsUsCalcMaxProfilesInfo.usCalcMaxProfilesInfo = calcMaxProfilesInfo;
          break;
        default:
          break;
      }
    }
  });

  setGlobalCalcMaxProfilesState(newGlobalCalcMaxProfilesState);
}
