import { useState } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';

import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LogoutVariant from '@mui/icons-material/ExitToAppOutlined';
import { HiOutlineUserCircle } from 'react-icons/hi';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuthContext } from '../../Authentication/AuthContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NavRoutes } from '../../Navigation/NavRoutes';

const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}));

export const UserDropdown = (): JSX.Element => {
  const { t } = useTranslation();
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const { logout, userInfo } = useAuthContext();

  const handleDropdownOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorElement(null);
  };

  const handleLogOut = () => {
    setAnchorElement(null);
    logout();
  };

  const styles = {
    py: 1,
    px: 2,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
      color: 'text.secondary'
    }
  };

  return (
    <>
      <IconButton
        color="inherit"
        size="large"
        aria-haspopup="true"
        onClick={handleDropdownOpen}
        aria-controls="customized-menu"
        data-testid="userDropdownButton"
        sx={{ gap: '10px' }}
      >
        <HiOutlineUserCircle size="35px" />
        <Box>
          <Typography fontWeight="600" color="primary">
            {userInfo?.first_name} {userInfo?.last_name}
          </Typography>
          <Typography fontSize="10px" color="secondary" textAlign="left">
            {userInfo?.role}
          </Typography>
        </Box>
        <ExpandMoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Badge
              overlap="circular"
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Avatar
                alt="Avatar"
                // src="/SE_Logo.svg"
                sx={{ width: '2.5rem', height: '2.5rem' }}
              />
            </Badge>
            <Box
              sx={{
                display: 'flex',
                marginLeft: 1,
                alignItems: 'flex-start',
                flexDirection: 'column'
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  color: theme => theme.palette.primary.main
                }}
              >
                {userInfo?.first_name} {userInfo?.last_name}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '0.8rem', color: 'text.disabled' }}>
                {userInfo?.role}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: 0, mb: 1 }} />
        {userInfo?.role === 'Admin' && (
          <MenuItem sx={{ p: 0 }} onClick={handleDropdownClose}>
            <Link to={NavRoutes.manage_users} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Box sx={styles}>
                <Person2OutlinedIcon sx={{ marginRight: 2 }} />
                {t('user_drop_down.manage_users')}
              </Box>
            </Link>
          </MenuItem>
        )}
        {userInfo?.role === 'Admin' && <Divider />}
        <MenuItem sx={{ p: 0 }} onClick={handleDropdownClose}>
          <Box sx={styles}>
            <LocalPhoneOutlinedIcon sx={{ marginRight: 2 }} />
            {t('user_drop_down.email_us')}
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ py: 2 }} onClick={handleLogOut}>
          <LogoutVariant
            sx={{
              marginRight: 2,
              fontSize: '1.375rem',
              color: 'text.secondary'
            }}
          />
          {t('user_drop_down.logout')}
        </MenuItem>
      </Menu>
    </>
  );
};
