import {AlertColor} from "@mui/material";
import {createContext, useContext, useMemo, useState} from "react";
import {FeedbackSnackbar} from "./Snackbar";


type FeedbackState = {
  open: boolean;
  severity: AlertColor;
  message?: string;
}

type FeedbackContextType = {
  feedbackState: FeedbackState,
  setFeedbackState: (feedbackState: FeedbackState) => void,
  feedbackInfo: (message: string) => void,
  feedbackSuccess: (message: string) => void,
  feedbackWarning: (message: string) => void,
  feedbackError: (message: string) => void,
}

export const FeedbackContext = createContext<FeedbackContextType>({
  feedbackState: {
    open: false,
    severity: "info"
  },
  setFeedbackState: () => {
  },
  feedbackInfo: () => {
  },
  feedbackSuccess: () => {
  },
  feedbackWarning: () => {
  },
  feedbackError: () => {
  },
});

type Props = {
  children: JSX.Element[] | JSX.Element
}

export function FeedbackContextProvider({children}: Props): JSX.Element {
  const [feedbackState, setFeedbackState] = useState<FeedbackState>({
    open: false,
    severity: "info"
  });

  const ctx = useMemo<FeedbackContextType>(():FeedbackContextType => {
    return {
      feedbackState: feedbackState,
      setFeedbackState: setFeedbackState,
      feedbackInfo: (message: string): void => {
        setFeedbackState({open: true, severity: "info", message: message});
      },
      feedbackSuccess: (message: string): void => {
        setFeedbackState({open: true, severity: "success", message: message});
      },
      feedbackWarning: (message: string): void => {
        setFeedbackState({open: true, severity: "warning", message: message});
      },
      feedbackError: (message: string): void => {
        setFeedbackState({open: true, severity: "error", message: message});
      },
    };
  }, [feedbackState]);

  return <FeedbackContext.Provider value={ctx}>{children}<FeedbackSnackbar /></FeedbackContext.Provider>;
}

export function useFeedbackContext(): FeedbackContextType {
  return useContext(FeedbackContext);
}
