import {
  GrafanaPanel,
  GraphPanelStyle,
  RankingPanelStyle,
  SingleValuePanelStyle,
} from "../Components/GrafanaPanel";
import Grid from "@mui/material/Unstable_Grid2";
import { useGrafanaLink } from "../Dashboard/Dashboard";
import { useEffect } from "react";

const PanelIDs = {
  CapacityBar: 10,
  CapacityGainedPercentSingle: 18,
  CapacityGainedAbsoluteSingle: 16,
  EfficiencySingle: 12,
  UnrealizedCapacitySingle: 14,
  CapacityGainOverTime: 20,
  Coverage: 22,
  FecPie: 24,
  CmByBoostedQam: 26,
  LowestBoostedQamRank: 32,
  GreatestTrafficRank: 28,
  HighestUncorrectableRank: 30,
};

export function Downstream(): JSX.Element {

  const {setGrafanaLink, setGrafanaLinkButton} = useGrafanaLink();
  const dashboardUrl = "/grafana/d-solo/qNKe0tWVz/landing";
  const dashboardLinkUrl = "/grafana/d/qNKe0tWVz/landing";

  useEffect(() => {
      setGrafanaLink(dashboardLinkUrl);
      setGrafanaLinkButton(true);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid lg={3}>
        <GrafanaPanel
          url={dashboardUrl}
          panelId={PanelIDs.CapacityGainedPercentSingle}
          width="100%"
          height="100%"
          injectedClassName="single-value-panel"
          injectedStyle={SingleValuePanelStyle}
        />
      </Grid>
      <Grid lg={3}>
        <GrafanaPanel
          url={dashboardUrl}
          panelId={PanelIDs.EfficiencySingle}
          width="100%"
          height="100%"
          injectedClassName="single-value-panel"
          injectedStyle={SingleValuePanelStyle}
        />
      </Grid>
      <Grid lg={3}>
        <GrafanaPanel
          url={dashboardUrl}
          panelId={PanelIDs.CapacityGainedAbsoluteSingle}
          width="100%"
          height="100%"
          injectedClassName="single-value-panel"
          injectedStyle={SingleValuePanelStyle}
        />
      </Grid>
      <Grid lg={3}>
        <GrafanaPanel
          url={dashboardUrl}
          panelId={PanelIDs.UnrealizedCapacitySingle}
          width="100%"
          height="100%"
          injectedClassName="single-value-panel"
          injectedStyle={SingleValuePanelStyle}
        />
      </Grid>
      <Grid lg={12}>
        <GrafanaPanel
          url={dashboardUrl}
          panelId={PanelIDs.CapacityGainOverTime}
          width="100%"
          height="340px"
          injectedClassName="graph-panel"
          injectedStyle={GraphPanelStyle}
        />
      </Grid>
      <Grid lg={6} height="100%">
        <Grid lg={12} sx={{ pt: 0, pl: 0 }}>
          <GrafanaPanel
            url={dashboardUrl}
            panelId={PanelIDs.Coverage}
            width="100%"
            height="229px"
            injectedClassName="single-value-panel"
            injectedStyle={SingleValuePanelStyle}
          />
        </Grid>
        <Grid lg={12} sx={{ pb: 0, pl: 0 }}>
          <GrafanaPanel
            url={dashboardUrl}
            panelId={PanelIDs.FecPie}
            width="100%"
            height="229px"
            injectedClassName="graph-panel"
            injectedStyle={GraphPanelStyle}
          />
        </Grid>
      </Grid>
      <Grid lg={6}>
        <GrafanaPanel
          url={dashboardUrl}
          panelId={PanelIDs.CmByBoostedQam}
          width="100%"
          height="480px"
          injectedClassName="graph-panel"
          injectedStyle={GraphPanelStyle}
        />
      </Grid>
      <Grid lg={4}>
        <GrafanaPanel
          url={dashboardUrl}
          panelId={PanelIDs.HighestUncorrectableRank}
          width="100%"
          height="300px"
          injectedClassName="ranking-panel"
          injectedStyle={RankingPanelStyle}
        />
      </Grid>
      <Grid lg={4}>
        <GrafanaPanel
          url={dashboardUrl}
          panelId={PanelIDs.GreatestTrafficRank}
          width="100%"
          height="300px"
          injectedClassName="ranking-panel"
          injectedStyle={RankingPanelStyle}
        />
      </Grid>
      <Grid lg={4}>
        <GrafanaPanel
          url={dashboardUrl}
          panelId={PanelIDs.LowestBoostedQamRank}
          width="100%"
          height="300px"
          injectedClassName="ranking-panel"
          injectedStyle={RankingPanelStyle}
        />
      </Grid>
    </Grid>
  );
}
