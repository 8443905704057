import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  MenuItem,
  Typography
} from '@mui/material';
import Select from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useCallback, useEffect } from 'react';
import { ApiRoutes } from '../../Api/ApiRoutes';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useFeedbackContext } from '../../Feedback/FeedbackContext';
import Placeholder from '../../Placeholder';
import { MdSave } from 'react-icons/md';
import { ApiPut } from '../../Api/Util';
import { useGlobalCalcMaxProfilesContext, GlobalDsUsCalcMaxProfilesInfo } from '../GlobalCalcMaxProfilesContext';
import Box from '@mui/material/Box';

export function GlobalCalcMaxProfilesConfig(): JSX.Element {
  const {
    globalCalcMaxProfilesState: { globalDsUsCalcMaxProfilesInfo, globalCalcMaxProfilesLoaded }
  } = useGlobalCalcMaxProfilesContext();

  const { t } = useTranslation();
  const { setFeedbackState } = useFeedbackContext();

  const updateFailure = useCallback(
    (error: any) => {
      setFeedbackState({
        open: true,
        message: `${t('calc_max_profiles_config.failed_update')}: ${error.message}`,
        severity: 'error'
      });
    },
    [setFeedbackState, t]
  );

  const updateSuccess = useCallback(() => {
    setFeedbackState({
      open: true,
      message: `${t('calc_max_profiles_config.success_update')}`,
      severity: 'success'
    });
  }, [setFeedbackState, t]);

  const submitCallback = useCallback(
    (values: GlobalDsUsCalcMaxProfilesInfo) => {
      const downstreamProfilesData = {
        ...values.dsCalcMaxProfilesInfo,
        calc_max_profiles: values.dsCalcMaxProfilesInfo?.calc_max_profiles === 1 ? null : values.dsCalcMaxProfilesInfo?.calc_max_profiles
      };
      const upstreamProfilesData = {
        ...values.usCalcMaxProfilesInfo,
        calc_max_profiles: values.usCalcMaxProfilesInfo?.calc_max_profiles === 1 ? null : values.usCalcMaxProfilesInfo?.calc_max_profiles
      };
      ApiPut(ApiRoutes.config.calcMaxProfiles.update, [
        downstreamProfilesData,
        upstreamProfilesData
      ]).then(updateSuccess, updateFailure);
    },
    [updateSuccess, updateFailure]
  );

  const formik = useFormik({
    initialValues: {
      ...globalDsUsCalcMaxProfilesInfo,
      dsCalcMaxProfilesInfo: {
        ...globalDsUsCalcMaxProfilesInfo.dsCalcMaxProfilesInfo,
        tenant: 1,
        cmts: null,
        channel_direction: "D",
        mac_domain_ifdescr: null,
        channel_ifdescr: null,
        calc_max_profiles: globalDsUsCalcMaxProfilesInfo.dsCalcMaxProfilesInfo?.calc_max_profiles || 1
      },
      usCalcMaxProfilesInfo: {
        ...globalDsUsCalcMaxProfilesInfo.usCalcMaxProfilesInfo,
        tenant: 1,
        cmts: null,
        channel_direction: "U",
        mac_domain_ifdescr: null,
        channel_ifdescr: null,
        calc_max_profiles: globalDsUsCalcMaxProfilesInfo.usCalcMaxProfilesInfo?.calc_max_profiles || 1
      }
    },
    onSubmit: submitCallback
  });

  useEffect(() => {
    if (globalDsUsCalcMaxProfilesInfo) {
      formik.setValues({
        ...globalDsUsCalcMaxProfilesInfo,
        dsCalcMaxProfilesInfo: {
          ...globalDsUsCalcMaxProfilesInfo.dsCalcMaxProfilesInfo,
          tenant: globalDsUsCalcMaxProfilesInfo.dsCalcMaxProfilesInfo?.tenant || 1,
          cmts: null,
          channel_direction: "D",
          mac_domain_ifdescr: null,
          channel_ifdescr: null,
          calc_max_profiles: globalDsUsCalcMaxProfilesInfo.dsCalcMaxProfilesInfo?.calc_max_profiles || 1
        },
        usCalcMaxProfilesInfo: {
          ...globalDsUsCalcMaxProfilesInfo.usCalcMaxProfilesInfo,
          tenant: globalDsUsCalcMaxProfilesInfo.usCalcMaxProfilesInfo?.tenant || 1,
          cmts: null,
          channel_direction: "U",
          mac_domain_ifdescr: null,
          channel_ifdescr: null,
          calc_max_profiles: globalDsUsCalcMaxProfilesInfo.usCalcMaxProfilesInfo?.calc_max_profiles || 1
        }
      });
    }
  }, [globalDsUsCalcMaxProfilesInfo]);

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="global-topology-schedule">
          <Typography variant="h5">{t('calc_max_profiles_config.global_title')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!globalCalcMaxProfilesLoaded ? (
            <Placeholder />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} display="flex" gap="10px">
                <Grid item xs={12} display="flex">
                  <Grid item xs={6}>
                    <Typography>{t(`calc_max_profiles_config.ds_max_profiles`)}</Typography>
                    <Box sx={{ pl: '4px', pr: '4px' }}>
                      <Select
                        name="dsCalcMaxProfilesInfo.calc_max_profiles"
                        value={formik.values.dsCalcMaxProfilesInfo?.calc_max_profiles || ''}
                        onChange={formik.handleChange}
                        variant={'standard'}
                        inputProps={{
                          'aria-label': t('calc_max_profiles_config.ds_profiles_aria')
                        }}
                        sx={{ marginTop: '16px' }}
                      >
                        <MenuItem value={1}>{t(`calc_max_profiles_config.vendor_default`)}</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{t(`calc_max_profiles_config.us_max_profiles`)}</Typography>
                    <Box sx={{ pl: '4px', pr: '4px' }}>
                      <Select
                        name="usCalcMaxProfilesInfo.calc_max_profiles"
                        value={formik.values.usCalcMaxProfilesInfo?.calc_max_profiles || ''}
                        onChange={formik.handleChange}
                        variant={'standard'}
                        inputProps={{
                          'aria-label': t('calc_max_profiles_config.us_profiles_aria')
                        }}
                        sx={{ marginTop: '16px' }}
                      >
                        <MenuItem value={1}>{t(`calc_max_profiles_config.vendor_default`)}</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    color="primary"
                    startIcon={<MdSave />}
                    variant="outlined"
                    data-testid="submit"
                    type="submit"
                  >
                    {t('calc_max_profiles_config.save')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
