import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GlobalCmtsConfiguration } from './Cmts/GlobalCmtsConfiguration';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { GiOctopus } from 'react-icons/gi';
import { TbSitemap } from 'react-icons/tb';
import { FeedbackContextProvider } from '../Feedback/FeedbackContext';
import GlobalConfig from './Schedule/GlobalConfig';
import { GlobalConfigContextProvider } from './GlobalStateContext';
import { GlobalCalcMaxProfilesContextProvider } from './GlobalCalcMaxProfilesContext';
import { GlobalTemplateProfilesContextProvider } from './GlobalTemplateProfilesContext';
import { useGrafanaLink } from '../Dashboard/Dashboard';
import { useEffect } from 'react';
import {useTranslation} from "react-i18next";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {UploadTemplateButton} from "./ProfilesTemplate/UploadTemplateButton";

export function Config(): JSX.Element {
  const { t } = useTranslation();
  const { setGrafanaLinkButton } = useGrafanaLink();

  useEffect(() => {
    setGrafanaLinkButton(false);
  }, []);

  return (
    <FeedbackContextProvider>
      <GlobalConfigContextProvider>
        <GlobalCalcMaxProfilesContextProvider>
          <GlobalTemplateProfilesContextProvider>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="cmts-config-content"
              id="cmts-config-header"
            >
              <Typography variant="h4">
                <GiOctopus /> {t('config.cmts_config')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GlobalCmtsConfiguration />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="schedule-content"
              id="schedule-header"
            >
              <Typography variant="h4">
                <TbSitemap /> {t('config.global_config')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GlobalConfig />
            </AccordionDetails>
          </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="template-profile-content"
                id="template-profile-header"
              >
                <Typography variant="h4">
                  <CloudUploadOutlinedIcon /> {t('config.template_profile')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <UploadTemplateButton />
              </AccordionDetails>
            </Accordion>
          </GlobalTemplateProfilesContextProvider>
        </GlobalCalcMaxProfilesContextProvider>
      </GlobalConfigContextProvider>
    </FeedbackContextProvider>
  );
}
