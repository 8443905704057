import {styled} from "@mui/material/styles";
import MuiAppBar, {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar";

export const drawerWidth = 240;
export const drawerWidthClosed = 72;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    }
)<AppBarProps>(
    ({theme, open}) => ({
        zIndex: theme.zIndex.drawer - 1,
        backgroundColor: theme.colors.background.secondary,
        color: theme.palette.text.secondary,
        boxShadow: "0 .5px .5px rgba(8, 26, 81, 0.40)",
        ...(open ? {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        } : {
            marginLeft: drawerWidthClosed,
            width: `calc(100% - ${drawerWidthClosed}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        }),
    })
);
