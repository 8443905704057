import { useState, useEffect, Dispatch, SetStateAction } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DsChannel } from "../../Api/Types/Topology";
import { Typography } from "@mui/material";
import { ListApiResponse } from "../../Api/Types/General";
import { ApiRoutes } from "../../Api/ApiRoutes";
import Grid from "@mui/material/Unstable_Grid2";
import { FeedbackContextProvider } from "../../Feedback/FeedbackContext";
import { useFeedbackContext } from "../../Feedback/FeedbackContext";
import { ApiGet } from "../../Api/Util";
import {useTranslation} from "react-i18next";

type IdMap<T> = { [key: string]: T };

export type DsChannelSelectorComponentProp = {
  width?: number;
  direction?: string;
  macDomain: string | undefined;
  currentDsChannel: string;
  setCurrentDsChannel: Dispatch<SetStateAction<string>>;
  dsChannelIdToDsChannel: IdMap<DsChannel>;
  setDsChannelIdToDsChannel: Dispatch<SetStateAction<IdMap<DsChannel>>>;
};

export function DsChannelSelectorComponentContent({
  width,
  direction,
  macDomain,
  currentDsChannel,
  setCurrentDsChannel,
  dsChannelIdToDsChannel,
  setDsChannelIdToDsChannel,
}: DsChannelSelectorComponentProp): JSX.Element {
  const { feedbackError } = useFeedbackContext();
  const { t } = useTranslation();

  const [selectedDsChannel, setSelectedDsChannel] = useState<string>("");

  const handleChannelChange = (event: SelectChangeEvent): void => {
    const id = event.target.value;
    setCurrentDsChannel(id);
  };

  useEffect(() => {
    ApiGet<ListApiResponse<DsChannel>>(
      ApiRoutes.topology.ds_channel.filter({
        macDomain: Number(macDomain),
        latest: true,
      }),
    )
      .then((response: ListApiResponse<DsChannel>) => {
        const dsChannelIdToIfDescr: { [key: string]: DsChannel } = {};

        response.results.map((result) => {
          if (result.channel_id === undefined) {
            return;
          }
          dsChannelIdToIfDescr[Number(result.channel_id)] = result;
        });
        setDsChannelIdToDsChannel(dsChannelIdToIfDescr);
      })
      .catch((error) => {
        feedbackError(t("selectors.ds_channel_error"));
      });
  }, [macDomain]);

  const dsChannels = Object.values(dsChannelIdToDsChannel).sort(
    function (a, b) {
      return a.ifDescr.localeCompare(b.ifDescr, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    },
  );

  const defaultKey = dsChannels.length
    ? BigInt(dsChannels[0].channel_id).toString()
    : "none";

  useEffect(() => {
    if (dsChannels.length) {
      if (
        currentDsChannel === "" ||
        !dsChannelIdToDsChannel[currentDsChannel]?.channel_id
      ) {
        setCurrentDsChannel(Number(dsChannels[0].channel_id).toString());
        setSelectedDsChannel(Number(dsChannels[0].channel_id).toString());
      } else {
        setSelectedDsChannel(currentDsChannel);
      }
    } else {
      setSelectedDsChannel("none");
    }
  }, [dsChannels, currentDsChannel]);

  return (
    <Grid lg={width ? width : 3} sx={{ padding: 0 }}>
      <FormControl
        sx={{ m: 1, minWidth: 120 }}
        size="small"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Typography
          sx={{
            fontFamily: "Encode Sans",
            alignSelf: "start",
            padding: "7px",
            borderRadius: "6px 0px 0px 6px",
            color: "#fff",
            fontWeight: "600",
            whiteSpace: "nowrap",
            backgroundColor:
              direction === "upstream"
                ? (theme) => theme.colors.direction.upstream
                : direction === "downstream"
                  ? (theme) => theme.colors.direction.downstream
                  : (theme) => theme.palette.primary.main,
          }}
        >
          {t("selectors.ds_channel")}
        </Typography>
        <Select
          value={selectedDsChannel}
          onChange={handleChannelChange}
          displayEmpty
          label={t("selectors.channel_aria")}
          defaultValue={defaultKey}
          inputProps={{
            "aria-label": t("selectors.channel_aria"),
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: '#FEFEFE'
                }
              }
            }
          }}
          sx={{
            borderRadius: "0px 6px 6px 0",
            width: "100%",
            flex: 1,
            "& .MuiSvgIcon-root": {
              color: (theme) => theme.palette.secondary.main,
            },
            "& .MuiOutlinedInput-notchedOutline span": {
              display: "none",
            },
            "& .MuiSelect-select": {
              backgroundColor: "#FEFEFE"
            }
          }}
        >
          {dsChannels.length ? (
            dsChannels.map((channel) => {
              return (
                <MenuItem
                  key={Number(channel.channel_id)}
                  value={Number(channel.channel_id)}
                >
                  {channel.ifDescr}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem value="none">
              <em>{t('selectors.none')}</em>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Grid>
  );
}

export function DsChannelSelectorComponent({
  width,
  direction,
  macDomain,
  currentDsChannel,
  setCurrentDsChannel,
  dsChannelIdToDsChannel,
  setDsChannelIdToDsChannel,
}: DsChannelSelectorComponentProp): JSX.Element {
  return (
    <FeedbackContextProvider>
      <DsChannelSelectorComponentContent
        width={width}
        direction={direction}
        macDomain={macDomain}
        currentDsChannel={currentDsChannel}
        setCurrentDsChannel={setCurrentDsChannel}
        dsChannelIdToDsChannel={dsChannelIdToDsChannel}
        setDsChannelIdToDsChannel={setDsChannelIdToDsChannel}
      />
    </FeedbackContextProvider>
  );
}
