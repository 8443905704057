import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormGroup,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFeedbackContext } from '../../Feedback/FeedbackContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TagField } from '../../Components/TagField';
import NumberField from '../../Components/NumberField';
import { ApiRoutes } from '../../Api/ApiRoutes';
import { useFormik } from 'formik';
import { validateCmts } from './ValidateCmts';
import { MdOutlineSaveAlt } from 'react-icons/md';
import { ScheduleToggle } from './ScheduleToggle';
import { useGlobalConfigContext } from '../GlobalStateContext';
import { ListApiResponse } from '../../Api/Types/General';
import {
  Cmts,
  CmtsSchedule,
  CmtsTag,
  getDefaultCmts,
  CalcMaxProfilesInfo,
  CalcConstraintTemplateProfile
} from '../../Api/Types/Config';
import { ApiGet, ApiPost, ApiPut } from '../../Api/Util';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { CalcMaxProfilesDropdown } from "../Profiles/CalcMaxProfilesDropdown";
import { useGlobalCalcMaxProfilesContext } from "../GlobalCalcMaxProfilesContext";
import {CalcTemplateProfilesDropdown} from "../Profiles/CalcTemplateProfilesDropdown";
import {useGlobalTemplateProfilesContext} from "../GlobalTemplateProfilesContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type AddCmtsDialogProps = {
  setOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
  open: boolean;
  updateCmtsList: () => void;
};

export function AddCmtsDialog({ setOpen, open, updateCmtsList }: AddCmtsDialogProps): JSX.Element {
  const { t } = useTranslation();
  const { setFeedbackState } = useFeedbackContext();

  const { globalCalcMaxProfilesState } = useGlobalCalcMaxProfilesContext();
  const { globalTemplateProfilesState } = useGlobalTemplateProfilesContext();

  const { tenantScheduleState } = useGlobalConfigContext();
  const [initialTags, setInitialTags] = useState<CmtsTag[] | null>(null);

  const [showCMTSCommunityString, setShowCMTSCommunityString] = useState<boolean>(false);
  const [showCmCommunityString, setShowCmCommunityString] = useState<boolean>(false);
  const [showKafkaPasswordString, setShowKafkaPasswordString] = useState<boolean>(false);

  const [dsCalcMaxProfilesInfo, setDsCalcMaxProfilesInfo] = useState<CalcMaxProfilesInfo | null>(null);
  const [usCalcMaxProfilesInfo, setUsCalcMaxProfilesInfo] = useState<CalcMaxProfilesInfo | null>(null);

  const [dsCalcConstraintTemplateProfiles, setDsCalcConstraintTemplateProfiles] =
    useState<CalcConstraintTemplateProfile | null>(null);
  const [usCalcConstraintTemplateProfiles, setUsCalcConstraintTemplateProfiles] =
    useState<CalcConstraintTemplateProfile | null>(null);

  useMemo<boolean>(
    () => !!initialTags && tenantScheduleState.tenantScheduleLoaded,
    [initialTags, tenantScheduleState.tenantScheduleLoaded]
  );

  const fetchTags = (): void => {
    ApiGet<ListApiResponse<CmtsTag>>(ApiRoutes.config.cmtsTags.all).then(
      async response => {
        setInitialTags(response.results);
      },
      err => {
        console.error(err);
      }
    );
  };

  useEffect(fetchTags, [setInitialTags]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const submitCallback = useCallback(
    (values: Cmts) => {
      const formattedData = {
        ...values,
        kafka_extra_config: values.kafka_extra_config && values.kafka_extra_config.trim() !== '' ? JSON.parse(values.kafka_extra_config.trim()) : null
      };
      ApiPost<Cmts>(ApiRoutes.config.cmts.all, formattedData)
        .then(
          async (response): Promise<Cmts> => {
            setFeedbackState({
              open: true,
              message: `${t('cmts_config.success_update')}`,
              severity: 'success'
            });
            formik.resetForm();
            updateCmtsList?.();
            return response;
        })
        .then((response) => {
          submitProfiles(response);
          submitTemplates(response);
        })
        .catch(error => {
          setFeedbackState({
            open: true,
            message: `${t('cmts_config.failed_update')}: ${error.data.error ? error.data.error : error.message}`,
            severity: 'error'
          });
        })
        .finally(() => {
          setOpen(false);
        });
    },
    [setOpen, dsCalcMaxProfilesInfo, usCalcMaxProfilesInfo, dsCalcConstraintTemplateProfiles, usCalcConstraintTemplateProfiles]
  );

  const submitProfiles = (cmts: Cmts) => {
    const dsCalcMaxProfilesData = {
      tenant: 1,
      cmts: cmts.id,
      channel_direction: "D",
      calc_max_profiles: dsCalcMaxProfilesInfo?.calc_max_profiles === 1 ? null : dsCalcMaxProfilesInfo?.calc_max_profiles
    };
    const usCalcMaxProfilesData = {
      tenant: 1,
      cmts: cmts.id,
      channel_direction: "U",
      calc_max_profiles: usCalcMaxProfilesInfo?.calc_max_profiles === 1 ? null : usCalcMaxProfilesInfo?.calc_max_profiles
    };
    const data = [dsCalcMaxProfilesData, usCalcMaxProfilesData];
    ApiPut(
      ApiRoutes.config.calcMaxProfiles.update,
      data
    ).then(() => {
      setFeedbackState({
        open: true,
        message: `${t('calc_max_profiles_config.success_update')}`,
        severity: 'success'
      });
    }).catch((error:any) => {
      setFeedbackState({
        open: true,
        message: `${t('calc_max_profiles_config.failed_update')}: ${error.message}`,
        severity: 'error'
      });
    });
  }

  const submitTemplates = (cmts: Cmts) => {
    const dsTemplateProfilesData = {
      tenant: 1,
      channel_direction: 'D',
      cmts: cmts.id,
      calc_constraint_template_profile_id:
        dsCalcConstraintTemplateProfiles?.calc_constraint_template_profile_id === 0
          ? null
          : dsCalcConstraintTemplateProfiles?.calc_constraint_template_profile_id
    };
    const usTemplateProfilesData = {
      tenant: 1,
      channel_direction: 'U',
      cmts: cmts.id,
      calc_constraint_template_profile_id:
        usCalcConstraintTemplateProfiles?.calc_constraint_template_profile_id === 0
          ? null
          : usCalcConstraintTemplateProfiles?.calc_constraint_template_profile_id
    };
    const data = [dsTemplateProfilesData, usTemplateProfilesData];
    ApiPut(ApiRoutes.config.calcConstraintTemplateProfile.update, data)
      .then(async () => {
        setFeedbackState({
          open: true,
          message: `${t('template_config.success_update')}`,
          severity: 'success'
        });
        updateCmtsList?.();
      })
      .catch(error => {
        setFeedbackState({
          open: true,
          message: `${t('template_config.failed_update')}: ${error.message}`,
          severity: 'error'
        });
      });
  };

  const initialValues: Cmts = getDefaultCmts();

  const formik = useFormik<Cmts>({
    initialValues: {
      ...initialValues,
      hostname: initialValues.hostname || '',
      cmts_port: initialValues.cmts_port || 0,
      cmts_community_string: initialValues.cmts_community_string || '',
      cm_port: initialValues.cm_port || 0,
      cm_community_string: initialValues.cm_community_string || '',
      pnm_server_ipv4_addr: initialValues.pnm_server_ipv4_addr || '',
      tags: initialValues.tags || [],
      topology_schedule: initialValues.topology_schedule || { schedule_rate_s: 0 },
      cm_topo_schedule: initialValues.cm_topo_schedule || { schedule_rate_s: 0 },
      ds_rxmer_schedule: initialValues.ds_rxmer_schedule || { schedule_rate_s: 0 },
      us_rxmer_schedule: initialValues.us_rxmer_schedule || { schedule_rate_s: 0 },
      ds_fec_schedule: initialValues.ds_fec_schedule || { schedule_rate_s: 0 },
      us_fec_schedule: initialValues.us_fec_schedule || { schedule_rate_s: 0 },
      ds_profile_calc_schedule: initialValues.ds_profile_calc_schedule || { schedule_rate_s: 0 },
      us_profile_calc_schedule: initialValues.us_profile_calc_schedule || { schedule_rate_s: 0 },
      cmts_kafka_stream_schedule: initialValues.cmts_kafka_stream_schedule || { schedule_rate_s: 0 },
      kafka_username: initialValues.kafka_username || '',
      kafka_password: initialValues.kafka_password || '',
      kafka_bootstrap_servers: initialValues.kafka_bootstrap_servers || '',
      kafka_group_id: initialValues.kafka_group_id || '',
      kafka_rxmer_topic: initialValues.kafka_rxmer_topic || '',
      kafka_topology_topic: initialValues.kafka_topology_topic || '',
      kafka_extra_config:
        initialValues.kafka_extra_config === null ? '' : JSON.stringify(initialValues.kafka_extra_config, null, 3)
    },
    validate: (values: Cmts) => {
      return validateCmts(t, values, values.ds_rxmer_schedule.active);
    },
    onSubmit: submitCallback,
    validateOnBlur: true,
    validateOnChange: true
  });

  return (
    <Dialog maxWidth="lg" open={open}>
      <DialogTitle>{t('cmts_config.add_cmts')}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <DialogContentText>{t('cmts_config.add_cmts_dialog')}</DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                label={t('cmts_config.name')}
                fullWidth
                variant="standard"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                inputProps={{ 'data-testid': 'name' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                {t('cmts_config.cmts')} {t('cmts_config.configuration')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormGroup row>
                <TextField
                  name="hostname"
                  label={t('cmts_config.host_name')}
                  variant="standard"
                  value={formik.values.hostname}
                  onChange={formik.handleChange}
                  error={formik.touched.hostname && Boolean(formik.errors.hostname)}
                  helperText={formik.touched.hostname && formik.errors.hostname}
                  inputProps={{ 'data-testid': 'hostname' }}
                />
                <NumberField
                  mode="integer"
                  label={t('cmts_config.snmp_port')}
                  name="cmts_port"
                  variant="standard"
                  value={formik.values.cmts_port}
                  onChange={formik.handleChange}
                  inputProps={{ 'data-testid': 'cmts_port' }}
                />
                <TextField
                  name="cmts_community_string"
                  label={t('cmts_config.community_string')}
                  variant="standard"
                  type={showCMTSCommunityString ? 'text' : 'password'}
                  value={formik.values.cmts_community_string}
                  error={
                    formik.touched.cmts_community_string &&
                    Boolean(formik.errors.cmts_community_string)
                  }
                  helperText={
                    formik.touched.cmts_community_string && formik.errors.cmts_community_string
                  }
                  onChange={formik.handleChange}
                  inputProps={{
                    'data-testid': 'cmts_community_string'
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={t('cmts_config.community_string_aria')}
                          onClick={() => setShowCMTSCommunityString(!showCMTSCommunityString)}
                          onMouseDown={() => setShowCMTSCommunityString(!showCMTSCommunityString)}
                          edge="end"
                        >
                          {showCMTSCommunityString ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormGroup>
              <TagField
                initTags={initialTags}
                initValue={formik.values.tags}
                onChange={(values): void => {
                  formik.setFieldValue('tags', values);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                {t('cmts_config.cable_modem')} {t('cmts_config.configuration')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormGroup row>
                <NumberField
                  mode="integer"
                  label="SNMP Port"
                  name="cm_port"
                  variant="standard"
                  value={formik.values.cm_port}
                  onChange={formik.handleChange}
                  inputProps={{ 'data-testid': 'cm_port' }}
                />
                <TextField
                  name="cm_community_string"
                  label={t('cmts_config.community_string')}
                  variant="standard"
                  type={showCmCommunityString ? 'text' : 'password'}
                  value={formik.values.cm_community_string}
                  error={
                    formik.touched.cm_community_string && Boolean(formik.errors.cm_community_string)
                  }
                  helperText={
                    formik.touched.cm_community_string && formik.errors.cm_community_string
                  }
                  onChange={formik.handleChange}
                  inputProps={{ 'data-testid': 'cm_community_string' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={t('cmts_config.community_string_aria')}
                          onClick={() => setShowCmCommunityString(!showCmCommunityString)}
                          onMouseDown={() => setShowCmCommunityString(!showCmCommunityString)}
                          edge="end"
                        >
                          {showCmCommunityString ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">{t('cmts_config.topology_collection')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <ScheduleToggle
                handleChange={(values: CmtsSchedule): void => {
                  formik.setFieldValue('topology_schedule', values);
                }}
                schedule_toggle_info={formik.values.topology_schedule}
                toggle_field_name="topology_schedule"
                global_value_s={tenantScheduleState.topologySchedule.topology?.schedule_rate_s}
              />
            </Grid>
            <Grid item xs={12}>
              <ScheduleToggle
                handleChange={(values: CmtsSchedule): void => {
                  formik.setFieldValue('cm_topo_schedule', values);
                }}
                schedule_toggle_info={formik.values.cm_topo_schedule}
                toggle_field_name="cm_topology_schedule"
                global_value_s={tenantScheduleState.topologySchedule.cm_topology?.schedule_rate_s}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">{t('cmts_config.pnm_collection')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <ScheduleToggle
                handleChange={(values: CmtsSchedule): void => {
                  formik.setFieldValue('ds_rxmer_schedule', values);
                }}
                toggle_field_name="ds_rxmer_schedule"
                schedule_toggle_info={formik.values.ds_rxmer_schedule}
                global_value_s={tenantScheduleState.pnmSchedule.dsRxmer?.schedule_rate_s}
              />
            </Grid>
            <Grid item xs={12}>
              <ScheduleToggle
                handleChange={(values: CmtsSchedule): void => {
                  formik.setFieldValue('us_rxmer_schedule', values);
                }}
                toggle_field_name="us_rxmer_schedule"
                schedule_toggle_info={formik.values.us_rxmer_schedule}
                global_value_s={tenantScheduleState.pnmSchedule.usRxmer?.schedule_rate_s}
              />
            </Grid>
            <Grid item xs={12}>
              <ScheduleToggle
                handleChange={(values: CmtsSchedule): void => {
                  formik.setFieldValue('ds_fec_schedule', values);
                }}
                toggle_field_name="ds_fec_schedule"
                schedule_toggle_info={formik.values.ds_fec_schedule}
                global_value_s={tenantScheduleState.pnmSchedule.dsFec?.schedule_rate_s}
              />
            </Grid>
            <Grid item xs={12}>
              <ScheduleToggle
                handleChange={(values: CmtsSchedule): void => {
                  formik.setFieldValue('us_fec_schedule', values);
                }}
                toggle_field_name="us_fec_schedule"
                schedule_toggle_info={formik.values.us_fec_schedule}
                global_value_s={tenantScheduleState.pnmSchedule.usFec?.schedule_rate_s}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">{t('cmts_config.profile_calculation')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <ScheduleToggle
                handleChange={(values: CmtsSchedule): void => {
                  formik.setFieldValue('ds_profile_calc_schedule', values);
                }}
                toggle_field_name="ds_profile_calc_schedule"
                schedule_toggle_info={formik.values.ds_profile_calc_schedule}
                global_value_s={
                  tenantScheduleState.profileCalcSchedule?.dsProfileCalc?.schedule_rate_s
                }
              />
            </Grid>
            <Grid item xs={12}>
              <ScheduleToggle
                handleChange={(values: CmtsSchedule): void => {
                  formik.setFieldValue('us_profile_calc_schedule', values);
                }}
                toggle_field_name="us_profile_calc_schedule"
                schedule_toggle_info={formik.values.us_profile_calc_schedule}
                global_value_s={
                  tenantScheduleState.profileCalcSchedule?.usProfileCalc?.schedule_rate_s
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <FormGroup row>
                <TextField
                  name="pnm_server_ipv4_addr"
                  label={t('cmts_config.pma_recv_addr')}
                  variant="outlined"
                  inputProps={{ 'data-testid': 'pnm_server_ip_addr' }}
                  value={formik.values.pnm_server_ipv4_addr}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.pnm_server_ipv4_addr &&
                    Boolean(formik.errors.pnm_server_ipv4_addr)
                  }
                  helperText={
                    formik.touched.pnm_server_ipv4_addr && formik.errors.pnm_server_ipv4_addr
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {/* Kafka Configuration */}
            <Accordion sx={{width: "100%"}} disableGutters elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="kafka-config-content"
                id="kafka-config-header"
              >
                <Typography variant="h5">
                  {t('cmts_config.kafka_configuration')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12} sx={{marginBottom: "20px"}}>
                  <ScheduleToggle
                    handleChange={(values: CmtsSchedule): void => {
                      formik.setFieldValue('cmts_kafka_stream_schedule', values);
                    }}
                    toggle_field_name="cmts_kafka_stream_schedule"
                    schedule_toggle_info={formik.values.cmts_kafka_stream_schedule}
                    global_value_s={
                      tenantScheduleState.kafkaSchedule?.kafkaStream?.schedule_rate_s
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormGroup
                    sx={{ width: '400px', display: 'flex', flexDirection: 'column', gap: '10px' }}
                  >
                    <FormControl>
                      <TextField
                        label={t('cmts_config.kafka_username')}
                        name="kafka_username"
                        variant="outlined"
                        value={formik.values.kafka_username}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        label={t('cmts_config.kafka_password')}
                        name="kafka_password"
                        variant="outlined"
                        type={showKafkaPasswordString ? 'text' : 'password'}
                        value={formik.values.kafka_password}
                        onChange={formik.handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label={t('cmts_config.kafka_password_string_aria')}
                                onClick={() => setShowKafkaPasswordString(!showKafkaPasswordString)}
                                onMouseDown={() =>
                                  setShowKafkaPasswordString(!showKafkaPasswordString)
                                }
                                edge="end"
                              >
                                {showKafkaPasswordString ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        label={t('cmts_config.kafka_bootstrap_servers')}
                        name="kafka_bootstrap_servers"
                        variant="outlined"
                        value={formik.values.kafka_bootstrap_servers}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        label={t('cmts_config.kafka_group_id')}
                        name="kafka_group_id"
                        variant="outlined"
                        value={formik.values.kafka_group_id}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        label={t('cmts_config.kafka_rxmer_topic')}
                        name="kafka_rxmer_topic"
                        variant="outlined"
                        value={formik.values.kafka_rxmer_topic}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        label={t('cmts_config.kafka_topology_topic')}
                        name="kafka_topology_topic"
                        variant="outlined"
                        value={formik.values.kafka_topology_topic}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        label={t('cmts_config.kafka_extra_config')}
                        name="kafka_extra_config"
                        variant="outlined"
                        multiline
                        value={formik.values.kafka_extra_config}
                        error={
                          formik.touched.kafka_extra_config &&
                          Boolean(formik.errors.kafka_extra_config)
                        }
                        helperText={
                          formik.touched.kafka_extra_config &&
                          (formik.errors.kafka_extra_config as string)
                        }
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Profiles config */}
            <Grid item xs={12}><Divider /></Grid>
            <Grid item xs={12}>
              <Typography variant="h5">{t('calc_max_profiles_config.cmts_title')}</Typography>
            </Grid>
            <Grid item xs={12} display="flex" gap="20px">
              <Grid item xs={6}>
                <CalcMaxProfilesDropdown
                  handleChange={(values: CalcMaxProfilesInfo): void => {
                    setDsCalcMaxProfilesInfo(values);
                  }}
                  calcMaxProfilesInfo={dsCalcMaxProfilesInfo}
                  fieldName="ds_max_profiles"
                  globalCalcMaxProfiles={globalCalcMaxProfilesState.globalDsUsCalcMaxProfilesInfo.dsCalcMaxProfilesInfo?.calc_max_profiles}
                />
              </Grid>
              <Grid item xs={6}>
                <CalcMaxProfilesDropdown
                  handleChange={(values: CalcMaxProfilesInfo): void => {
                    setUsCalcMaxProfilesInfo(values);
                  }}
                  calcMaxProfilesInfo={usCalcMaxProfilesInfo}
                  fieldName="us_max_profiles"
                  globalCalcMaxProfiles={globalCalcMaxProfilesState.globalDsUsCalcMaxProfilesInfo.usCalcMaxProfilesInfo?.calc_max_profiles}
                />
              </Grid>
            </Grid>

            {/* Templates Config*/}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">{t('template_config.title')}</Typography>
            </Grid>
            <Grid item xs={12} display="flex" gap="20px">
              <Grid item xs={6}>
                <CalcTemplateProfilesDropdown
                  handleChange={(values: CalcConstraintTemplateProfile): void => {
                    setDsCalcConstraintTemplateProfiles(values);
                  }}
                  calcTemplateProfilesInfo={dsCalcConstraintTemplateProfiles}
                  fieldName="ds_template"
                  globalCalcTemplateProfileValue={
                    globalTemplateProfilesState.templateProfilesGlobalConfig
                      .downstreamTemplateProfiles?.calc_constraint_template_profile_id
                  }
                  direction="D"
                />
              </Grid>
              <Grid item xs={6}>
                <CalcTemplateProfilesDropdown
                  handleChange={(values: CalcConstraintTemplateProfile): void => {
                    setUsCalcConstraintTemplateProfiles(values);
                  }}
                  calcTemplateProfilesInfo={usCalcConstraintTemplateProfiles}
                  fieldName="us_template"
                  globalCalcTemplateProfileValue={
                    globalTemplateProfilesState.templateProfilesGlobalConfig
                      .upstreamTemplateProfiles?.calc_constraint_template_profile_id
                  }
                  direction="U"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          <Button type="submit" startIcon={<MdOutlineSaveAlt />} data-testid="submit">
            {t('create')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

// request_ds_rxmer
// request_us_rxmer
// request_ds_fec
// request_us_fec
