import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, {useCallback, useEffect} from "react";
import {ApiRoutes} from "../../Api/ApiRoutes";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {useFeedbackContext} from "../../Feedback/FeedbackContext";
import Placeholder from "../../Placeholder";
import NumberField from "../../Components/NumberField";
import {MdSave} from "react-icons/md";
import {TenantProfileCalcScheduleState, useGlobalConfigContext} from "../GlobalStateContext";
import {IsValidTime, RequireField} from "../../Feedback/ValidationUtils";
import {ApiPut} from "../../Api/Util";


function validateGlobalProfileCalculation(t: any, values: TenantProfileCalcScheduleState): object {
    const errors: any = {};

    RequireField(t, errors, values.dsProfileCalc?.schedule_rate_s, "dsProfileCalc");
    RequireField(t, errors, values.usProfileCalc?.schedule_rate_s, "usProfileCal");

    IsValidTime(t, errors, values.dsProfileCalc?.schedule_rate_s, "dsProfileCalc");
    IsValidTime(t, errors, values.usProfileCalc?.schedule_rate_s, "usProfileCalc");

    return errors;
}

export function GlobalProfileCalculationScheduleConfig(): JSX.Element {
    const {tenantScheduleState: {profileCalcSchedule, tenantScheduleLoaded}} = useGlobalConfigContext();
    const {t} = useTranslation();
    const {setFeedbackState} = useFeedbackContext();

    const updateFailure = useCallback((error: any) => {
        setFeedbackState({
            open: true,
            message: `${t('profile_calc_config.failed_update')}: ${error.message}`,
            severity: "error"
        });
    }, [setFeedbackState, t]);

    const updateSuccess = useCallback(() => {
        setFeedbackState({open: true, message: `${t('profile_calc_config.success_update')}`, severity: "success"});
    }, [setFeedbackState, t]);

    const submitCallback = useCallback((values: TenantProfileCalcScheduleState) => {
        ApiPut(
            ApiRoutes.config.schedule.subset,
            [values.dsProfileCalc, values.usProfileCalc]
        ).then(updateSuccess, updateFailure);
    }, [updateSuccess, updateFailure]);

    const validateSubmission = useCallback(
        (values: TenantProfileCalcScheduleState): object => validateGlobalProfileCalculation(t, values),
        [t]
    );

    const formik = useFormik({
            initialValues: profileCalcSchedule,
            validate: validateSubmission,
            onSubmit: submitCallback,
            validateOnBlur: true,
            validateOnChange: true
        }
    );

    useEffect(() => {
        formik.setValues(profileCalcSchedule);
    }, [profileCalcSchedule]);

    return <>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="global-profile-calc-schedule"
            >
                <Typography variant="h5">Profile Calculation Schedule</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    !tenantScheduleLoaded ? (
                        <Placeholder />
                    ) : (
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <NumberField
                                        mode="integer"
                                        label={t('profile_calc_config.ds_pma_schedule_rate')}
                                        name="dsProfileCalc.schedule_rate_s"
                                        value={formik.values.dsProfileCalc?.schedule_rate_s || ''}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors.dsProfileCalc)}
                                        helperText={formik.errors.dsProfileCalc}
                                        variant="outlined"
                                        inputProps={{'data-testid':"ds_pma_schedule_rate_s"}}
                                        sx={{width: "100%"}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <NumberField
                                        mode="integer"
                                        label={t('profile_calc_config.us_pma_schedule_rate')}
                                        name="usProfileCalc.schedule_rate_s"
                                        value={formik.values.usProfileCalc?.schedule_rate_s || ''}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors.usProfileCalc)}
                                        helperText={formik.errors.usProfileCalc}
                                        variant="outlined"
                                        inputProps={{'data-testid':"us_pma_schedule_rate_s"}}
                                        sx={{width: "100%"}}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Button color="primary" startIcon={<MdSave />} variant="outlined"
                                            data-testid="submit"
                                            type="submit">
                                        {t('profile_calc_config.save')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }
            </AccordionDetails>
        </Accordion>
    </>;
}
