import {FormControlLabel, FormGroup, Switch} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useChannelDeploymentContext} from "../DeploymentContext";
import {ConstructC100gUsInstructions} from "./InstructionConstructors/C100gUs";
import {ManualDeploymentInstructionsDetail} from "./ManualProfileDeployment";


export function C100gUsManualProfileDeployment(): JSX.Element {
  const {t} = useTranslation();
  const t_no_escape = function (key: string, options: { [p: string]: any } = {}): string {
    options = Object.assign({interpolation: {escapeValue: false}}, options);
    return t(key, options);
  };

  const {cmts, macDomain, usChannel, pmaUsResult} = useChannelDeploymentContext();

  // C100G Config form state
  const [ofdmaIucProfileId, setOfdmaIucProfileId] = useState<string>(t("deploy.c100g_config.ofdma_iuc_profile_id_default"));
  const [firstNewOfdmaMinislotCfgId, setFirstNewOfdmaMinislotCfgId] = useState<string>(t("deploy.c100g_config.first_new_ofdma_minislot_cfg_id_default"));
  const [includeSafetyChecks, setIncludeSafetyChecks] = useState<boolean>(true);
  const [includePmaUsResult, setIncludePmaUsResult] = useState<boolean>(false);

  const instructions = useMemo((): string => {
    return ConstructC100gUsInstructions(
      t_no_escape, cmts, macDomain, usChannel, pmaUsResult,
      ofdmaIucProfileId, firstNewOfdmaMinislotCfgId, includeSafetyChecks, includePmaUsResult
    );
  }, [
    t_no_escape, cmts, macDomain, usChannel, pmaUsResult,
    ofdmaIucProfileId, firstNewOfdmaMinislotCfgId, includeSafetyChecks, includePmaUsResult
  ]);

  return (
    <ManualDeploymentInstructionsDetail instructions={instructions}>
      <Typography variant={"h6"} color={(theme): any => theme.palette.primary.main}>
        {t("deploy.c100g_config.upstream_config_header", {cmts_type: cmts?.state?.cmts_type})}
      </Typography>
      <FormGroup sx={{marginBottom: "25px"}}>
        <TextField
          sx={{marginTop: "25px"}}
          label={t_no_escape("deploy.c100g_config.ofdma_iuc_profile_id_label", {
            channel_name: usChannel?.ifDescr
          })}
          variant="outlined"
          defaultValue={t("deploy.c100g_config.ofdma_iuc_profile_id_default")}
          onChange={(e): void => {
            setOfdmaIucProfileId(e.target.value);
          }}
          helperText={t("deploy.c100g_config.ofdma_iuc_profile_id_note")}
        />
        <TextField
          sx={{marginTop: "25px"}}
          label={t_no_escape("deploy.c100g_config.first_new_ofdma_minislot_cfg_id_label", {
            channel_name: usChannel?.ifDescr
          })}
          variant="outlined"
          defaultValue={t("deploy.c100g_config.first_new_ofdma_minislot_cfg_id_default")}
          onChange={(e): void => {
            setFirstNewOfdmaMinislotCfgId(e.target.value);
          }}
          helperText={t("deploy.c100g_config.first_new_ofdma_minislot_cfg_id_note")}
        />
        <FormControl sx={{marginTop: "15px", alignItems: "start"}}>
          <FormControlLabel
            control={
              <Switch color="primary"
                      onChange={(e): void => {
                        setIncludeSafetyChecks(e.target.checked);
                      }}
                      defaultChecked={includeSafetyChecks}
              />
            }
            label={t("deploy.include_safety_checks")}
            labelPlacement="start"
          ></FormControlLabel>
        </FormControl>
        <FormControl sx={{marginTop: "15px", alignItems: "start"}}>
          <FormControlLabel
            control={
              <Switch color="primary"
                      onChange={(e): void => {
                        setIncludePmaUsResult(e.target.checked);
                      }}
                      defaultChecked={includePmaUsResult}
              />
            }
            label={t("deploy.include_pma_result")}
            labelPlacement="start"
          ></FormControlLabel>
        </FormControl>
      </FormGroup>
    </ManualDeploymentInstructionsDetail>
  );
}
