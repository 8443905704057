import {
  GrafanaPanel,
  GrafanaPlaceholder,
  GraphPanelStyle,
  LazyGrafanaPanel,
  SingleValuePanelStyle,
} from "../Components/GrafanaPanel";
import Grid from "@mui/material/Unstable_Grid2";
import { alpha, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { SummaryTypeSelector } from "./SummaryTypeSelector";
import { Cmts } from "../Api/Types/Config";
import { DsChannel, MacDomain, UsChannel } from "../Api/Types/Topology";
import { StyledDivider } from "./StyledDivider";
import { useUserContext } from "../Components/UserContext";
import { useGrafanaLink } from "../Dashboard/Dashboard";
import { CmtsSelectorComponent } from "../Components/Selectors/CmtsSelectorComponent";
import { MacDomainSelectorComponent } from "../Components/Selectors/MacDomainSelectorComponent";
import { DsChannelSelectorComponent } from "../Components/Selectors/DsSelectorComponent";
import { UsChannelSelectorComponent } from "../Components/Selectors/UsSelectorComponent";

type StateDispatch<T> = [T, Dispatch<SetStateAction<T>>];

const CommonPanelIDs = {
  DsChannels: 54,
  UsChannels: 60,
  MacIP: 53,
  RegStatus: 52,
  PartialService: 56,
  PartialChannel: 57,
};

const DownstreamPanelIDs = {
  RxMERAllExistingProfiles: 23,
  FECByProfile: 50,
  FECValidCodewordsbyProfile: 59,
  FECTotalCodewordsByProfile: 61,
};

const UpstreamPanelIDs = {
  RxMERAllExistingProfiles: 27,
  FECByProfile: 50,
  FECValidCodewordsbyProfile: 59,
  FECTotalCodewordsByProfile: 61,
};
export type IdMap<T> = { [key: string]: T };

export type ChannelContextProp = {
  searchParams: URLSearchParams;
  summaryType: { time: string };
  setSummaryType: Dispatch<SetStateAction<{ time: string }>>;
  preferredStream: string;
};

export const ChannelContext = createContext<ChannelContextProp>(
  {} as ChannelContextProp
);

enum PreferredStream {
  both = "both",
  downstream = "downstream",
  upstream = "upstream",
}

type GrafanaStreamProp = {
  stream: any;
  title: string;
  extraParams: any;
  preferredStream: string;
  loading: boolean;
};

function GrafanaStream({
  stream,
  title,
  extraParams,
  preferredStream,
  loading,
}: GrafanaStreamProp): JSX.Element {
  const dashboardUrl =
    "/grafana/d-solo/lXsaTFwnz/operational-cm";

  const isSingleStream = preferredStream !== PreferredStream.both;

  return (
    <>
      <Grid xs={12}>
        <StyledDivider textAlign="left">
          <Typography
            color={(theme): any => alpha(theme.palette.primary.main, 0.7)}
            fontWeight="600"
          >
            {title}
          </Typography>
        </StyledDivider>
      </Grid>
      <Grid xs={12} display={isSingleStream ? "flex" : ""} gap={1}>
        <Grid lg={isSingleStream ? 6 : 12}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.RxMERAllExistingProfiles}
            width="100%"
            height="229px"
            injectedClassName="graph-panel"
            injectedStyle={GraphPanelStyle}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        <Grid lg={isSingleStream ? 6 : 12}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.FECByProfile}
            width="100%"
            height="229px"
            injectedClassName="graph-panel"
            injectedStyle={GraphPanelStyle}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid xs={12} display={isSingleStream ? "flex" : ""} gap={1}>
        <Grid lg={isSingleStream ? 6 : 12}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.FECValidCodewordsbyProfile}
            width="100%"
            height="229px"
            injectedClassName="graph-panel"
            injectedStyle={GraphPanelStyle}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        <Grid lg={isSingleStream ? 6 : 12}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.FECTotalCodewordsByProfile}
            width="100%"
            height="229px"
            injectedClassName="graph-panel"
            injectedStyle={GraphPanelStyle}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
    </>
  );
}

export function OperationalCM(): JSX.Element {
  const { t } = useTranslation();
  const dashboardUrl =
    "/grafana/d-solo/lXsaTFwnz/operational-cm";
  const dashboardLinkUrl =
    "/grafana/d/lXsaTFwnz/operational-cm";

  const { setGrafanaLink, setGrafanaLinkButton } = useGrafanaLink();

  const [loading, setLoading] = useState(true);

  const [cmtsIdToCmts, setCmtsIdToCmts] = useState<IdMap<Cmts>>({});
  const [macDomainIdToMacDomain, setMacDomainIdToMacDomain] = useState<
    IdMap<MacDomain>
  >({});
  const [dsChannelIdToDsChannel, setDsChannelIdToDsChannel] = useState<
    IdMap<DsChannel>
  >({});
  const [usChannelIdToUsChannel, setUsChannelIdToUsChannel] = useState<
    IdMap<UsChannel>
  >({});

  useEffect(() => {
    setGrafanaLinkButton(true);
  }, []);

  useEffect(() => {
    if (
      Object.keys(cmtsIdToCmts).length &&
      Object.keys(macDomainIdToMacDomain).length &&
      (Object.keys(dsChannelIdToDsChannel).length ||
        Object.keys(usChannelIdToUsChannel).length)
    ) {
      setLoading(false);
    }
  }, []);

  const [searchParams, setSearchParams] = useSearchParams({
    cmts: "",
    macdomain: "",
    dschannel: "",
    uschannel: "",
    summarytype: "",
  });

  const cmtsParam: string | null = searchParams.get("cmts");
  const [currentCmtsId, setCurrentCmtsId] = useState(
    cmtsParam ? cmtsParam : ""
  );

  const macDomainParam = searchParams.get("macdomain");
  const [currentMacDomainId, setCurrentMacDomainId] = useState(
    macDomainParam ? macDomainParam : ""
  );

  const dsChannelParam: string | null = searchParams.get("dschannel");
  const [currentDsChannelId, setCurrentDsChannelId] = useState(
    dsChannelParam ? dsChannelParam : ""
  );

  const usChannelParam: string | null = searchParams.get("uschannel");
  const [currentUsChannelId, setCurrentUsChannelId] = useState(
    usChannelParam ? usChannelParam : ""
  );

  const summaryTypeParam: string | null = searchParams.get("summarytype");
  const [summaryType, setSummaryType] = useState(
    summaryTypeParam ? { time: summaryTypeParam } : { time: "" }
  );

  const {
    tenant: { preferred_stream: preferredStream },
  } = useUserContext();

  useEffect(() => {
    setSearchParams({
      cmts: currentCmtsId,
      macdomain: currentMacDomainId,
      dschannel: currentDsChannelId,
      uschannel: currentUsChannelId,
      summarytype: summaryType.time,
    });
  }, [
    currentCmtsId,
    currentMacDomainId,
    currentDsChannelId,
    currentUsChannelId,
    summaryType,
  ]);

  const cmstName = Object.hasOwn(cmtsIdToCmts, currentCmtsId)
    ? cmtsIdToCmts[currentCmtsId].name
    : "";
  const macDomainIfDescr = Object.hasOwn(
    macDomainIdToMacDomain,
    currentMacDomainId
  )
    ? macDomainIdToMacDomain[currentMacDomainId].ifDescr
    : "";
  const dsChannelIfDescr = Object.hasOwn(
    dsChannelIdToDsChannel,
    currentDsChannelId
  )
    ? dsChannelIdToDsChannel[currentDsChannelId].ifDescr
    : "";
  const usChannelIfDescr = Object.hasOwn(
    usChannelIdToUsChannel,
    currentUsChannelId
  )
    ? usChannelIdToUsChannel[currentUsChannelId].ifDescr
    : "";

  useEffect(() => {
    setGrafanaLink(
      `${dashboardLinkUrl}?var-cmts=${cmstName}&var-mac_dom=${macDomainIfDescr}&var-ds_chan=${dsChannelIfDescr}&var-us_chan=${usChannelIfDescr}`
    );
  }, [cmstName, macDomainIfDescr, dsChannelIfDescr, usChannelIfDescr]);

  const extraParams = {
    "var-cmts": cmstName,
    "var-mac_dom": macDomainIfDescr,
    "var-ds_fec_summary_type_str": summaryType.time,
  };

  const extraDsParams = {
    "var-cmts": cmstName,
    "var-mac_dom": macDomainIfDescr,
    "var-ds_fec_summary_type_str": summaryType.time,
    "var-ds_chan": dsChannelIfDescr,
  };

  const extraUsParams = {
    "var-cmts": cmstName,
    "var-mac_dom": macDomainIfDescr,
    "var-ds_fec_summary_type_str": summaryType.time,
    "var-us_chan": usChannelIfDescr,
  };

  return (
    <ChannelContext.Provider
      value={{
        searchParams,
        summaryType,
        setSummaryType,
        preferredStream,
      }}
    >
      <Grid container spacing={2}>
        <Grid xs={12} display="flex" gap={1}>
          {(preferredStream === PreferredStream.both ||
            preferredStream === PreferredStream.downstream) && (
            <Grid lg={preferredStream === PreferredStream.downstream ? 4 : 2}>
              <LazyGrafanaPanel
                url={dashboardUrl}
                panelId={CommonPanelIDs.DsChannels}
                width="100%"
                height="100%"
                injectedClassName="single-value-panel"
                injectedStyle={SingleValuePanelStyle}
                extraParams={extraDsParams}
                loading={loading}
              />
            </Grid>
          )}
          {preferredStream === PreferredStream.upstream && (
            <Grid lg={4}>
              <LazyGrafanaPanel
                url={dashboardUrl}
                panelId={CommonPanelIDs.UsChannels}
                width="100%"
                height="100%"
                injectedClassName="single-value-panel"
                injectedStyle={SingleValuePanelStyle}
                extraParams={extraUsParams}
                loading={loading}
              />
            </Grid>
          )}
          <Grid lg={2}>
            <LazyGrafanaPanel
              url={dashboardUrl}
              panelId={CommonPanelIDs.MacIP}
              width="100%"
              height="100%"
              injectedClassName="single-value-panel"
              injectedStyle={SingleValuePanelStyle}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
          <Grid lg={2}>
            <LazyGrafanaPanel
              url={dashboardUrl}
              panelId={CommonPanelIDs.RegStatus}
              width="100%"
              height="100%"
              injectedClassName="single-value-panel"
              injectedStyle={SingleValuePanelStyle}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
          <Grid lg={2}>
            <LazyGrafanaPanel
              url={dashboardUrl}
              panelId={CommonPanelIDs.PartialService}
              width="100%"
              height="100%"
              injectedClassName="single-value-panel"
              injectedStyle={SingleValuePanelStyle}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
          <Grid lg={2}>
            <LazyGrafanaPanel
              url={dashboardUrl}
              panelId={CommonPanelIDs.PartialChannel}
              width="100%"
              height="100%"
              injectedClassName="single-value-panel"
              injectedStyle={SingleValuePanelStyle}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
          {preferredStream === PreferredStream.both && (
            <Grid lg={2}>
              <LazyGrafanaPanel
                url={dashboardUrl}
                panelId={CommonPanelIDs.UsChannels}
                width="100%"
                height="100%"
                injectedClassName="single-value-panel"
                injectedStyle={SingleValuePanelStyle}
                extraParams={extraUsParams}
                loading={loading}
              />
            </Grid>
          )}
        </Grid>

        <Grid
          xs={12}
          sx={{
            display: "flex",
            // padding: 0,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <CmtsSelectorComponent
            width={preferredStream !== PreferredStream.both ? 3 : 4}
            currentCmtsId={currentCmtsId}
            setCurrentCmtsId={setCurrentCmtsId}
            cmtsIdToCmts={cmtsIdToCmts}
            setCmtsIdToCmts={setCmtsIdToCmts}
          />
          <MacDomainSelectorComponent
            width={preferredStream !== PreferredStream.both ? 3 : 4}
            cmtsId={currentCmtsId}
            currentMacDomainId={currentMacDomainId}
            setCurrentMacDomainId={setCurrentMacDomainId}
            macDomainIdToMacDomain={macDomainIdToMacDomain}
            setMacDomainIdToMacDomain={setMacDomainIdToMacDomain}
          />
          {preferredStream === PreferredStream.downstream && (
            <DsChannelSelectorComponent
              width={3}
              macDomain={currentMacDomainId}
              currentDsChannel={currentDsChannelId}
              setCurrentDsChannel={setCurrentDsChannelId}
              dsChannelIdToDsChannel={dsChannelIdToDsChannel}
              setDsChannelIdToDsChannel={setDsChannelIdToDsChannel}
            />
          )}
          {preferredStream === PreferredStream.upstream && (
            <UsChannelSelectorComponent
              width={3}
              macDomain={currentMacDomainId}
              currentUsChannel={currentUsChannelId}
              setCurrentUsChannel={setCurrentUsChannelId}
              usChannelIdToUsChannel={usChannelIdToUsChannel}
              setUsChannelIdToUsChannel={setUsChannelIdToUsChannel}
            />
          )}
          <SummaryTypeSelector />
        </Grid>

        <Grid xs={12} display="flex" gap={1}>
          {preferredStream === PreferredStream.both && (
            <>
              <DsChannelSelectorComponent
                width={6}
                macDomain={currentMacDomainId}
                currentDsChannel={currentDsChannelId}
                setCurrentDsChannel={setCurrentDsChannelId}
                dsChannelIdToDsChannel={dsChannelIdToDsChannel}
                setDsChannelIdToDsChannel={setDsChannelIdToDsChannel}
              />
              <UsChannelSelectorComponent
                width={6}
                macDomain={currentMacDomainId}
                currentUsChannel={currentUsChannelId}
                setCurrentUsChannel={setCurrentUsChannelId}
                usChannelIdToUsChannel={usChannelIdToUsChannel}
                setUsChannelIdToUsChannel={setUsChannelIdToUsChannel}
              />
            </>
          )}
        </Grid>

        {/* Downstream */}
        {(preferredStream === PreferredStream.both ||
          preferredStream === PreferredStream.downstream) && (
          <Grid lg={preferredStream === PreferredStream.both ? 6 : 12}>
            <GrafanaStream
              stream={DownstreamPanelIDs}
              title={t("operational_cm.downstream")}
              extraParams={extraDsParams}
              preferredStream={preferredStream}
              loading={loading}
            />
          </Grid>
        )}

        {/* Upstream */}
        {(preferredStream === PreferredStream.both ||
          preferredStream === PreferredStream.upstream) && (
          <Grid lg={preferredStream === PreferredStream.both ? 6 : 12}>
            <GrafanaStream
              stream={UpstreamPanelIDs}
              title={t("operational_cm.upstream")}
              extraParams={extraUsParams}
              preferredStream={preferredStream}
              loading={loading}
            />
          </Grid>
        )}
      </Grid>
    </ChannelContext.Provider>
  );
}
