import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useCallback, useEffect } from 'react';
import { ApiRoutes } from '../../Api/ApiRoutes';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useFeedbackContext } from '../../Feedback/FeedbackContext';
import Placeholder from '../../Placeholder';
import NumberField from '../../Components/NumberField';
import { MdSave } from 'react-icons/md';
import { TenantKafkaScheduleState, useGlobalConfigContext } from '../GlobalStateContext';
import { IsValidTime, RequireField } from '../../Feedback/ValidationUtils';
import { ApiPut } from '../../Api/Util';

function validateGlobalKafka(t: any, values: TenantKafkaScheduleState): object {
  const errors: any = {};

  RequireField(t, errors, values.kafkaStream?.schedule_rate_s, 'kafkaStream');

  IsValidTime(t, errors, values.kafkaStream?.schedule_rate_s, 'kafkaStream');

  return errors;
}

export function GlobalKafkaScheduleConfig(): JSX.Element {
  const {
    tenantScheduleState: { kafkaSchedule, tenantScheduleLoaded }
  } = useGlobalConfigContext();
  const { t } = useTranslation();
  const { setFeedbackState } = useFeedbackContext();

  const updateFailure = useCallback(
    (error: any) => {
      setFeedbackState({
        open: true,
        message: `${t('kafka_config.failed_update')}: ${error.message}`,
        severity: 'error'
      });
    },
    [setFeedbackState, t]
  );

  const updateSuccess = useCallback(() => {
    setFeedbackState({
      open: true,
      message: `${t('kafka_config.success_update')}`,
      severity: 'success'
    });
  }, [setFeedbackState, t]);

  const submitCallback = useCallback(
    (values: TenantKafkaScheduleState) => {
      ApiPut(ApiRoutes.config.schedule.subset, [values.kafkaStream]).then(
        updateSuccess,
        updateFailure
      );
    },
    [updateSuccess, updateFailure]
  );

  const validateSubmission = useCallback(
    (values: TenantKafkaScheduleState): object => validateGlobalKafka(t, values),
    [t]
  );

  const formik = useFormik({
    initialValues: kafkaSchedule,
    validate: validateSubmission,
    onSubmit: submitCallback,
    validateOnBlur: true,
    validateOnChange: true
  });

  useEffect(() => {
    formik.setValues(kafkaSchedule);
  }, [kafkaSchedule]);

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="global-profile-kafka-schedule"
        >
          <Typography variant="h5">{t('kafka_config.global_title')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!tenantScheduleLoaded ? (
            <Placeholder />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} >
                  <NumberField
                    mode="integer"
                    label={t('kafka_config.kafka_schedule_rate')}
                    name="kafkaStream.schedule_rate_s"
                    value={formik.values.kafkaStream?.schedule_rate_s || ''}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.kafkaStream)}
                    helperText={formik.errors.kafkaStream}
                    variant="outlined"
                    inputProps={{ 'data-testid': 'kafka_stream_schedule_rate_s' }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    color="primary"
                    startIcon={<MdSave />}
                    variant="outlined"
                    data-testid="submit"
                    type="submit"
                  >
                    {t('kafka_config.save')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
