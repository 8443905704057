import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MacDomain } from '../../Api/Types/Topology';
import { Typography } from '@mui/material';
import { ListApiResponse } from '../../Api/Types/General';
import { ApiRoutes } from '../../Api/ApiRoutes';
import Grid from '@mui/material/Unstable_Grid2';
import { FeedbackContextProvider } from '../../Feedback/FeedbackContext';
import { useFeedbackContext } from '../../Feedback/FeedbackContext';
import { ApiGet } from '../../Api/Util';
import { useTranslation } from 'react-i18next';

type IdMap<T> = { [key: string]: T };

export type MacDomainSelectorComponentProp = {
  width?: number;
  direction?: string;
  region?: number[];
  cmtsId: string | undefined;
  currentMacDomainId: string;
  setCurrentMacDomainId: Dispatch<SetStateAction<string>>;
  macDomainIdToMacDomain: IdMap<MacDomain>;
  setMacDomainIdToMacDomain: Dispatch<SetStateAction<IdMap<MacDomain>>>;
  firstItem?: boolean;
};

export function MacDomainSelectorComponentContent({
  width,
  direction,
  region,
  cmtsId,
  currentMacDomainId,
  setCurrentMacDomainId,
  macDomainIdToMacDomain,
  setMacDomainIdToMacDomain,
  firstItem,
}: MacDomainSelectorComponentProp): JSX.Element {
  const { feedbackError } = useFeedbackContext();
  const { t } = useTranslation();
  const [selectedMacDomain, setSelectedMacDomain] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleDomainChange = (event: SelectChangeEvent): void => {
    const id = event.target.value;
    setCurrentMacDomainId(id);
  };

  useEffect(() => {
    setLoading(true);
    ApiGet<ListApiResponse<MacDomain>>(
      ApiRoutes.topology.mac_domain.filter({
        cmtsId: Number(cmtsId),
        regions: region,
        latest: true
      })
    )
      .then((response: ListApiResponse<MacDomain>) => {
        const macDomainIdToMacDomain: { [key: string]: MacDomain } = {};

        response.results.map(result => {
          if (result.mac_domain_id === undefined) {
            return;
          }
          macDomainIdToMacDomain[Number(result.mac_domain_id)] = result;
        });

        setMacDomainIdToMacDomain(macDomainIdToMacDomain);
        setLoading(false);
      })
      .catch(error => {
        feedbackError(t('selectors.mac_domain_error'));
      });
  }, [cmtsId, region]);

  const macDomains = Object.values(macDomainIdToMacDomain).sort(function (a, b) {
    return a.ifDescr.localeCompare(b.ifDescr, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  });
  const defaultKey = macDomains.length ? BigInt(macDomains[0].mac_domain_id).toString() : 'all';

  useEffect(() => {
    if (!loading) {
      if (macDomains.length) {
        if (currentMacDomainId === "" || !currentMacDomainId || !macDomainIdToMacDomain[currentMacDomainId]?.ifIndex) {
          if(currentMacDomainId === 'all') {
            setCurrentMacDomainId("all");
            setSelectedMacDomain("all");
          }else if(firstItem){
            setCurrentMacDomainId(defaultKey);
            setSelectedMacDomain(defaultKey);
          }else{
            setCurrentMacDomainId("all");
            setSelectedMacDomain("all");
          }
        } else {
          setSelectedMacDomain(currentMacDomainId);
        }
      } else {
        setSelectedMacDomain("all");
      }
    }
  }, [macDomains, currentMacDomainId]);

  return (
    <>
      <Grid lg={width ? width : 3} sx={{ padding: 0 }}>
        <FormControl
          sx={{ m: 1, minWidth: 120 }}
          size="small"
          style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
        >
          <Typography
            sx={{
              fontFamily: 'Encode Sans',
              alignSelf: 'center',
              padding: '7px',
              borderRadius: '6px 0px 0px 6px',
              color: '#fff',
              fontWeight: '600',
              backgroundColor:
                direction === 'upstream'
                  ? theme => theme.colors.direction.upstream
                  : direction === 'downstream'
                    ? theme => theme.colors.direction.downstream
                    : theme => theme.palette.primary.main
            }}
          >
            {t('selectors.mac_domain')}
          </Typography>
          <Select
            value={selectedMacDomain}
            onChange={handleDomainChange}
            displayEmpty
            label={t('selectors.mac_domain_aria')}
            defaultValue={defaultKey}
            inputProps={{
              'aria-label': t('selectors.mac_domain_aria'),
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundColor: '#FEFEFE'
                  }
                }
              }
            }}
            sx={{
              borderRadius: '0px 6px 6px 0',
              width: '100%',
              flex: 1,
              '& .MuiSvgIcon-root': {
                color: theme => theme.palette.secondary.main
              },
              '& .MuiOutlinedInput-notchedOutline span': {
                display: 'none'
              },
              '& .MuiSelect-select': {
                backgroundColor: '#FEFEFE'
              }
            }}
          >
            <MenuItem value="all">{t('selectors.all')}</MenuItem>
            {macDomains.length >= 1 &&
              macDomains.map(macDomain => {
                return (
                  <MenuItem
                    key={Number(macDomain.mac_domain_id)}
                    value={Number(macDomain.mac_domain_id)}
                  >
                    {macDomain.ifDescr}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}

export function MacDomainSelectorComponent({
  width,
  direction,
  region,
  cmtsId,
  currentMacDomainId,
  setCurrentMacDomainId,
  macDomainIdToMacDomain,
  setMacDomainIdToMacDomain,
  firstItem
}: MacDomainSelectorComponentProp): JSX.Element {
  return (
    <FeedbackContextProvider>
      <MacDomainSelectorComponentContent
        width={width}
        direction={direction}
        region={region}
        cmtsId={cmtsId}
        currentMacDomainId={currentMacDomainId}
        setCurrentMacDomainId={setCurrentMacDomainId}
        macDomainIdToMacDomain={macDomainIdToMacDomain}
        setMacDomainIdToMacDomain={setMacDomainIdToMacDomain}
        firstItem={firstItem}
      />
    </FeedbackContextProvider>
  );
}
