import React, {useEffect, useState} from 'react';
import {Navigate, Outlet, useLocation, useOutletContext} from 'react-router-dom';
import { useAuthContext } from './AuthContext';
import CircularProgress from "@mui/material/CircularProgress";

const AdminProtectedRoutes = () => {
  const { userInfo } = useAuthContext();

  return (
    userInfo?.role === undefined ? <div><CircularProgress/></div> : userInfo.role === 'Admin' ? <Outlet context={useOutletContext()} /> : <Navigate to='/'/>
  )
};

export default AdminProtectedRoutes;
