import {
  Box,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useChannelDeploymentContext } from "./DeploymentContext";
import { useCallback, useMemo, useState } from "react";
import { useFeedbackContext } from "../../Feedback/FeedbackContext";
import { ApiRoutes } from "../../Api/ApiRoutes";
import { ApiPost, ChannelDirection } from "../../Api/Util";
import { NotImplementedError } from "../../Error/NotImplementedError";


export function DeploymentStrategy(): JSX.Element {
  throw new NotImplementedError();
  const { t } = useTranslation();
  const { dsChannel } = useChannelDeploymentContext();
  // const {setFeedbackState} = useFeedbackContext();

  // const handleClick = useCallback(
  //     (
  //         event: React.MouseEvent<HTMLElement>,
  //         newDeploymentStrategy: string | null
  //     ) => {
  //         if (deploymentStrategy) {
  //             // TODO feedback wile setting the value
  //
  //             // ApiPut<DeployProfilesResponse>(ApiRoutes.cb.channelAutoDeploy.single(dsChannel?.config.id), dsChannel?.config).then(
  //             //     (value: DeployProfilesResponse) => {
  //             //         setFeedbackState({
  //             //             open: true,
  //             //             severity: "success",
  //             //             message: t("deploy.deploy_fallback_to_channel_success")
  //             //         });
  //             //     }
  //             // ).catch(
  //             //     (reason) => {
  //             //         setFeedbackState({
  //             //             open: true,
  //             //             severity: "error",
  //             //             message: reason.message
  //             //         });
  //             //     }
  //             // );
  //         }
  //     },
  //     [dsChannel]
  // );

  const [deploymentStrategy, setDeploymentStrategy] = useState<string | null>(
    dsChannel?.config.override_cmts
      ? dsChannel?.config.auto_deploy
        ? "auto"
        : "manual"
      : "default",
  );

  return (
    <>
      {" "}
      {deploymentStrategy ? (
        <ToggleButtonGroup
          value={deploymentStrategy}
          exclusive
          color="primary"
          size="small"
          aria-label="deployment strategy"
          // onChange={handleClick}
          sx={{ marginRight: "15px" }}
        >
          <ToggleButton
            value="default"
            aria-label="default deployment strategy"
            sx={{
              borderRadius: "1.5px",
              boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography>{t("deploy.strategy_default")}</Typography>
          </ToggleButton>
          <ToggleButton
            value="auto"
            aria-label="automatic deployment strategy"
            sx={{
              borderRadius: "1.5px",
              boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography>{t("deploy.strategy_auto")}</Typography>
          </ToggleButton>
          <ToggleButton
            value="manual"
            aria-label="manual deployment strategy"
            sx={{
              borderRadius: "1.5px",
              boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography>{t("deploy.strategy_manual")}</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      ) : (
        <Box>
          <Typography>{t("deployment.loading")}</Typography>
        </Box>
      )}{" "}
    </>
  );
}

export type DeployProfilesResponse = {
  status: string;
  trace_id: bigint;
};

export function DeployCalculatedProfiles({
  channel_direction,
}: {
  channel_direction: ChannelDirection;
}): JSX.Element {
  const { t } = useTranslation();
  const { pmaDsResult, pmaUsResult } = useChannelDeploymentContext();
  const { setFeedbackState } = useFeedbackContext();

  const handleClick = useCallback(() => {
    const url =
      channel_direction == ChannelDirection.Downstream
        ? ApiRoutes.cb.deployDsCalculatedProfiles
        : ApiRoutes.cb.deployUsCalculatedProfiles;
    const data =
      channel_direction == ChannelDirection.Downstream
        ? { ds_result_id: pmaDsResult?.id }
        : { us_result_id: pmaUsResult?.id };

    ApiPost<DeployProfilesResponse>(url, data)
      .then((value: DeployProfilesResponse) => {
        setFeedbackState({
          open: true,
          severity: "success",
          message: t("deploy.deploy_calc_to_channel_success"),
        });
      })
      .catch((reason) => {
        setFeedbackState({
          open: true,
          severity: "error",
          message: reason.message,
        });
      });
  }, [channel_direction, setFeedbackState, pmaDsResult, pmaUsResult]);

  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down("md"));
  const disabled = useMemo(
    () =>
      channel_direction === ChannelDirection.Downstream
        ? !pmaDsResult
        : !pmaUsResult,
    [channel_direction, pmaUsResult, pmaDsResult],
  );

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={handleClick}
      sx={{
        borderRadius: "1.5px",
        boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)",
        marginRight: matchMD ? "0" : "15px",
      }}
      disabled={disabled}
    >
      {t("deploy.deploy_calc_to_channel")}
    </Button>
  );
}

export function DeployFallbackProfiles({
  channel_direction,
}: {
  channel_direction: ChannelDirection;
}): JSX.Element {
  const { t } = useTranslation();
  const { dsChannel, usChannel } = useChannelDeploymentContext();
  const { setFeedbackState } = useFeedbackContext();

  const handleClick = useCallback(() => {
    const url =
      channel_direction == ChannelDirection.Downstream
        ? ApiRoutes.cb.deployDsFallbackProfiles
        : ApiRoutes.cb.deployUsGroundProfiles;
    const data =
      channel_direction == ChannelDirection.Downstream
        ? { channel_id: dsChannel?.channel_id }
        : { channel_id: usChannel?.channel_id };

    ApiPost<DeployProfilesResponse>(url, data)
      .then((value: DeployProfilesResponse) => {
        setFeedbackState({
          open: true,
          severity: "success",
          message: t("deploy.deploy_fallback_to_channel_success"),
        });
      })
      .catch((reason) => {
        setFeedbackState({
          open: true,
          severity: "error",
          message: reason.message,
        });
      });
  }, [setFeedbackState, channel_direction, dsChannel, usChannel]);

  const disabled = useMemo(
    () =>
      channel_direction === ChannelDirection.Downstream
        ? !dsChannel
        : !usChannel,
    [channel_direction, dsChannel, usChannel],
  );

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={handleClick}
      sx={{
        borderRadius: "1.5px",
        boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)",
      }}
      disabled={disabled}
    >
      {t("deploy.deploy_fallback_to_channel")}
    </Button>
  );
}

type ActionButtonBarProps = {
  children: JSX.Element[] | JSX.Element | null;
};

export function ActionButtonBar({
  children,
}: ActionButtonBarProps): JSX.Element {
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        marginBottom: "20px",
        display: "flex",
        flexWrap: "wrap",
        gap: matchMD ? "5px" : "",
      }}
    >
      {children}
    </Box>
  );
}
