import {PmaDsProfile} from "../../../../Api/Types/CapacityBooster";

export const INDENT = "    ";

export const ALLOWED_IUCS = [13, 12, 11, 10, 9, 6, 5];
export const ALLOWED_PROFILE_IDS = [0, 1, 2, 3, 4, 5, 6, 7];

export const DEFAULT_PROFILE_TYPE = 0;

export type FrequencyHz = number;
export type PilotPattern = number;

export type C100gModulation = string;
export const BITLOADING_TO_C100G_MODULATION: { [index: number]: C100gModulation } = {
  "0": "zero",
  "2": "qpsk",  // may not be available
  "3": "8qam",  // may not be available
  "4": "16qam",
  "5": "32qam",  // may not be available
  "6": "64qam",
  "7": "128qam",
  "8": "256qam",
  "9": "512qam",
  "10": "1024qam",
  "11": "2048qam",
  "12": "4096qam"
};

export type Cbr8Modulation = string;
export const BITLOADING_TO_CBR8_MODULATION: { [index: number]: Cbr8Modulation } = {
  "0": "zero-bit-load",
  "2": "QPSK",
  "3": "8-QAM",
  "4": "16-QAM",
  "5": "32-QAM",
  "6": "64-QAM",
  "7": "128-QAM",
  "8": "256-QAM",
  "9": "512-QAM",
  "10": "1024-QAM",
  "11": "2048-QAM",
  "12": "4096-QAM"
};

export type E6kUsModulation = string;
export const BITLOADING_TO_E6K_US_MODULATION: { [index: number]: E6kUsModulation } = {
  "0": "zeroval",
  "2": "qpsk",
  "3": "8qam",
  "4": "16qam",
  "5": "32qam",
  "6": "64qam",
  "7": "128qam",
  "8": "256qam",
  "9": "512qam",
  "10": "1024qam",
  "11": "2048qam",
  "12": "4096qam"
};

export type E6kDsModulation = string;
export const BITLOADING_TO_E6K_DS_MODULATION: { [index: number]: E6kDsModulation } = {
  "0": "0qam",
  "6": "64qam",
  "7": "128qam",
  "8": "256qam",
  "9": "512qam",
  "10": "1024qam",
  "11": "2048qam",
  "12": "4096qam"
};
//######################################################################################################################


//----------------------------------------------------------------------------------------------------------------------
export const AddDefaultProfileIfMissing = function (profiles: PmaDsProfile[], defaultProfileBitLoading: number): void {
  const newDefaultProfile: PmaDsProfile = {
    id: 0n,
    ds_result: 0n,
    profile_type: 0,
    profile_id: 0,
    num_compatible_cms: 0,
    assigned_cm_mac_addresses: [],
    num_assigned_cms: 0,
    total_efficiency_bits: 0,
    most_frequent_bit_loading: defaultProfileBitLoading,
    most_frequent_db: 0,
    num_segments: 0,
    histogram_subcarriers_bit_loading: {}, // filled in below
    num_samples: 0,
    fraction_of_samples: 0,
    num_optimal_profile_cms: 0,
    num_defaulted_cms: 0,
    mean_subcarrier_bit_loading: defaultProfileBitLoading,
    mean_subcarrier_db: 0,
    canonical_profile_descriptor: "",
    raw_total_efficiency_bits: 0,
    ds_subcarrier_statuses: [
      {
        id: 0n,
        ds_result: 0n,
        ds_profile: 0n,
        profile_type: DEFAULT_PROFILE_TYPE,
        profile_id: 0,
        start_id: 0,
        end_id: 0,
        start_frequency_hz: 0,
        end_frequency_hz: 0,
        main_bit_loading: defaultProfileBitLoading,
        main_db: 0,
        main_modulation: 0,
        skip: false,
        skip_bit_loading: 0,
        skip_db: 0,
        skip_modulation: 0
      }
    ]
  };
  newDefaultProfile.histogram_subcarriers_bit_loading[defaultProfileBitLoading] = 1;

  for (let index = 0; index < profiles.length; ++index) {
    const profile = profiles[index];
    const histogram = profile.histogram_subcarriers_bit_loading;
    let sumBitLoading = 0;
    let sumNumSubcarriers = 0;
    for (const [bitloading, num_subcarriers] of Object.entries(histogram)) {
      sumBitLoading += +bitloading * num_subcarriers;
      sumNumSubcarriers += num_subcarriers;
    }
    const defaultProfileSumBitloading = defaultProfileBitLoading * sumNumSubcarriers;

    // found default profile already defined
    if (Object.keys(histogram).length == 1 && sumBitLoading == defaultProfileSumBitloading) {
      // profile.profile_type = DEFAULT_PROFILE_TYPE; // it came from PMA, don't call it default
      return;
    }

    // went past where default profile should be - add before current
    if (sumBitLoading > defaultProfileSumBitloading) {
      profiles.splice(index, 0, newDefaultProfile);
      return;
    }
  }

  // add at end
  profiles.push(newDefaultProfile);
};

