import {PmaDsResult} from "../../../../Api/Types/CapacityBooster";
import {SimpleDeepCopy} from "../../../../Utils/SimpleDeepCopy";
import {BigIntReplacer} from "../../../../Utils/Converters";
import {DsChannel, MacDomain} from "../../../../Api/Types/Topology";
import {CmtsDsOfdmChan} from "../../../../Api/Types/Docis31";
import {Cmts} from "../../../../Api/Types/Config";
import {
  BITLOADING_TO_CBR8_MODULATION,
  Cbr8Modulation,
  DEFAULT_PROFILE_TYPE,
  FrequencyHz,
  INDENT
} from "./Common";

export function ConstructCbr8DsInstructions(
  t_no_escape: (key: string, options?: any) => string,
  cmts: Cmts | null,
  macDomain: MacDomain | null,
  dsChannel: DsChannel | null,
  pmaDsResult: PmaDsResult | null,
  cmtsDsOfdmChan: CmtsDsOfdmChan | null,
  firstNewOfdmModProfId: string,
  ofdmChanProfId: string,
  hitless: boolean,
  includeSafetyChecks: boolean,
  includePmaDsResult: boolean
): string {

  const CBR8_PROFILE_DESCRIPTION_MAX_LENGTH = 65;
  const CBR8_NUM_PROFILE_IDS = 8;
  const FIRST_PROFILE_ID = 2;
  const CBR8_DS_CHAN_DESCR_RE
    = /Integrated-Cable(?<controller_location>\d+\/\d+\/\d+)-downstream(?<cmts_channel_id>\d+)/;

  type Cbr8OfdmModulationProfileId = string;
  type Cbr8SubcarrierSpacing = string;
  const HZ_TO_CBR8_SUBCARRIER_SPACING: { [index: number]: Cbr8SubcarrierSpacing } = {
    "25000": "25KHZ",
    "50000": "50KHZ"
  };

  const getProfileName = function(profileId: number | null): string {
    if (0 === profileId) {
      return "profile-control";
    } else {
      return `profile-data ${profileId}`;
    }
  }

  class Cbr8ModulationSpec {
    defaultModulation: Cbr8Modulation | null = null;
    ofdmModulationProfileId: Cbr8OfdmModulationProfileId | null = null;
    meanBitLoading: number | null = null;

    toConfig(): string {
      if (this.defaultModulation !== null) {
        return `modulation-default ${this.defaultModulation}`;
      } else {
        return `modulation-profile ${this.ofdmModulationProfileId}`;
      }
    }
  }

  class Cbr8OfdmModulationProfileExceptionZone {
    modulation: Cbr8Modulation | null = null;
    startFreqHz: FrequencyHz | null = null;
    widthHz: FrequencyHz | null = null;
  }

  class Cbr8OfdmModulationProfile {
    expectedDataProfileId: number | null = null;
    meanBitLoading: number | null = null;
    ofdmModulationProfileId: Cbr8OfdmModulationProfileId | null = null;
    description: string | null = null;
    subcarrierSpacing: Cbr8SubcarrierSpacing | null = null;
    widthHz: FrequencyHz | null = null;
    startFreqHz: FrequencyHz | null = null;
    defaultModulation: Cbr8Modulation | null = null;
    exceptionZones: Cbr8OfdmModulationProfileExceptionZone[] = [];

    getLines(): string[] {
      const lines: string[] = [];
      lines.push("");
      lines.push(`# ${t_no_escape("deploy.commands.cbr8.ofdm_mod_profile_info", {
        profile_name: getProfileName(this.expectedDataProfileId),
        bit_loading: this.meanBitLoading
      })}`);
      lines.push(`cable downstream ofdm-modulation-profile ${this.ofdmModulationProfileId}`);
      if (this.description) {
        lines.push(`${INDENT}description ${this.description}`);
      }
      lines.push(`${INDENT}subcarrier-spacing ${this.subcarrierSpacing}`);
      lines.push(`${INDENT}width ${this.widthHz}`);
      if (this.startFreqHz) {
        lines.push(`${INDENT}start-freq ${this.startFreqHz}`);
      }
      lines.push(`${INDENT}assign modulation-default ${this.defaultModulation}`);
      this.exceptionZones.forEach(exceptionZone => {
        lines.push(`${INDENT}assign modulation ${exceptionZone.modulation}`
          + ` range-subcarriers freq-abs ${exceptionZone.startFreqHz}`
          + ` width ${exceptionZone.widthHz}`);
      });
      lines.push(`exit`);
      return lines;
    }
  }

  const nextOfdmModulationProfileId = function (): () => Cbr8OfdmModulationProfileId {
    let counter: number = +firstNewOfdmModProfId;
    if (isNaN(counter)) {
      counter = 0;
      return function (): Cbr8OfdmModulationProfileId {
        const s: Cbr8OfdmModulationProfileId = firstNewOfdmModProfId + (counter == 0 ? "" : `+${counter}`);
        counter++;
        return s;
      };
    }
    return function (): Cbr8OfdmModulationProfileId {
      const s: Cbr8OfdmModulationProfileId = `${counter}`;
      counter++;
      return s;
    };
  }();

  // ====

  const mutablePmaDsResult = SimpleDeepCopy(pmaDsResult);

  const resultsLines: string[] = [];
  resultsLines.push(`# ${t_no_escape("deploy.commands.instructions_title")}`);
  if (mutablePmaDsResult == null || !mutablePmaDsResult.calculated_profiles) {
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.no_profile_data")}`);
    return resultsLines.join("\n");
  }

  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.for_cmts_mac_domain_ds_channel", {
    cmts: mutablePmaDsResult.cmts_name,
    mac_domain: mutablePmaDsResult.md_ifdescr,
    ds_channel: mutablePmaDsResult.ds_ifdescr
  })}`);
  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.for_pma_task_id_ds_result_id", {
    pma_task_id: mutablePmaDsResult.task,
    pma_ds_result_id: mutablePmaDsResult.id
  })}`);

  let timestamp = mutablePmaDsResult.result_completed_timestamp;
  timestamp = timestamp.replace("T", " ").replace("Z", " UTC");
  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.profiles_calculated_at_timestamp", {
    timestamp: timestamp
  })}`);

  // adjust boundaries if needed
  let originalStartFreqHz: number | null = null;
  let correctedStartFreqHz: number | null = null;
  let originalEndFreqHz: number | null = null;
  let correctedEndFreqHz: number | null = null;
  if (cmtsDsOfdmChan) {
    const subcarrierSpacingHz = cmtsDsOfdmChan.subcarrier_spacing * 1000;
    const lowerBoundaryFrequencyHz = Number(cmtsDsOfdmChan.lower_bdry_freq);
    const upperBoundaryFrequencyHz
      = Math.ceil(Number(cmtsDsOfdmChan.upper_bdry_freq) / subcarrierSpacingHz)
      * subcarrierSpacingHz;

    mutablePmaDsResult.calculated_profiles.forEach(pmaDsProfile => {
      const firstRecord = pmaDsProfile.ds_subcarrier_statuses[0];
      if (firstRecord.start_frequency_hz > lowerBoundaryFrequencyHz) {
        originalStartFreqHz = firstRecord.start_frequency_hz;
        correctedStartFreqHz = lowerBoundaryFrequencyHz;
        firstRecord.start_frequency_hz = lowerBoundaryFrequencyHz;
      }

      const lastIndex = pmaDsProfile.ds_subcarrier_statuses.length - 1;
      const lastRecord = pmaDsProfile.ds_subcarrier_statuses[lastIndex];
      if (lastRecord.end_frequency_hz < upperBoundaryFrequencyHz) {
        originalEndFreqHz = lastRecord.end_frequency_hz;
        correctedEndFreqHz = upperBoundaryFrequencyHz;
        lastRecord.end_frequency_hz = upperBoundaryFrequencyHz;
      }
    });
  } else {
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.no_cmts_ds_ofdm_chan_data")}`);
  }

  // build modulationProfiles and modulationSpecs
  const modulationSpecs: Cbr8ModulationSpec[] = [];
  const modulationProfiles: Cbr8OfdmModulationProfile[] = [];
  mutablePmaDsResult.calculated_profiles.forEach(pmaDsProfile => {
    const subcarrier_statuses = pmaDsProfile.ds_subcarrier_statuses;
    const modulationSpec = new Cbr8ModulationSpec();

    modulationSpec.meanBitLoading = pmaDsProfile.mean_subcarrier_bit_loading;

    if (subcarrier_statuses.length == 1) {
      modulationSpec.defaultModulation = BITLOADING_TO_CBR8_MODULATION[subcarrier_statuses[0].main_bit_loading];
    } else {
      const modulationProfile = new Cbr8OfdmModulationProfile();

      modulationProfile.expectedDataProfileId = pmaDsProfile.profile_id;
      modulationProfile.meanBitLoading = pmaDsProfile.mean_subcarrier_bit_loading;

      modulationProfile.ofdmModulationProfileId = nextOfdmModulationProfileId();
      modulationSpec.ofdmModulationProfileId = modulationProfile.ofdmModulationProfileId;

      let description = `OV-PMA ${pmaDsProfile.canonical_profile_descriptor}`;
      if (description.length > CBR8_PROFILE_DESCRIPTION_MAX_LENGTH) {
        description = `${description.substring(0, CBR8_PROFILE_DESCRIPTION_MAX_LENGTH - 1)}x`;
      }
      modulationProfile.description = description;

      const firstSubcarrierStatus = subcarrier_statuses[0];
      const lastSubcarrierStatus = subcarrier_statuses[subcarrier_statuses.length - 1];

      modulationProfile.subcarrierSpacing = HZ_TO_CBR8_SUBCARRIER_SPACING[mutablePmaDsResult.subcarrier_spacing_hz];
      modulationProfile.startFreqHz = firstSubcarrierStatus.start_frequency_hz;
      modulationProfile.widthHz = lastSubcarrierStatus.end_frequency_hz - modulationProfile.startFreqHz;
      modulationProfile.defaultModulation
        = BITLOADING_TO_CBR8_MODULATION[pmaDsProfile.most_frequent_bit_loading];
      subcarrier_statuses.forEach(subcarrier_status => {
        if (subcarrier_status.main_bit_loading == pmaDsProfile.most_frequent_bit_loading) {
          return;
        }
        const exceptionZone = new Cbr8OfdmModulationProfileExceptionZone();
        exceptionZone.modulation = BITLOADING_TO_CBR8_MODULATION[subcarrier_status.main_bit_loading];
        exceptionZone.startFreqHz = subcarrier_status.start_frequency_hz;
        exceptionZone.widthHz = subcarrier_status.end_frequency_hz - subcarrier_status.start_frequency_hz;
        modulationProfile.exceptionZones.push(exceptionZone);
      });
      modulationProfiles.push(modulationProfile);
    }
    modulationSpecs.push(modulationSpec);
  });

  // get cmts-specific ids
  let ds_chan_info = mutablePmaDsResult.ds_ifdescr.match(CBR8_DS_CHAN_DESCR_RE)?.groups;
  if (!ds_chan_info) {
    ds_chan_info = {
      controller_location: t_no_escape("deploy.commands.cbr8.unknown_controller_location"),
      cmts_channel_id: t_no_escape("deploy.commands.cbr8.unknown_cmts_ds_channel_id")
    };
  }
  const controllerLocation = ds_chan_info.controller_location;
  const cmtsChannelId = ds_chan_info.cmts_channel_id;

  if (includeSafetyChecks) {

    if (modulationProfiles.length > 0) {
      resultsLines.push("");
      resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.safety_check_profile_overlap_intro")}`);
      resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_profile_overlap_do_commands")}`);
      modulationProfiles.forEach(ofdmModulationProfile => {
        resultsLines.push("show running-config | section cable downstream"
          + ` ofdm-modulation-profile ${ofdmModulationProfile.ofdmModulationProfileId}`);
      });
      resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_profile_overlap_check")}`);
      resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_profile_overlap_show_more")}`);
      resultsLines.push("show running-config | section cable downstream ofdm-modulation-profile");
    }

    resultsLines.push("");
    const controllerName = `Integrated-Cable ${controllerLocation}`;
    const rfChanName = `rf-chan ${cmtsChannelId}`;
    const profileDescr = `ofdm channel-profile ${ofdmChanProfId} start-frequency ...`;
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.safety_check_controller_has_channel_intro", {
      controller_name: controllerName,
      channel_id: cmtsChannelId
    })}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_controller_has_channel_do_command")}`);
    resultsLines.push(`show running-config | section controller ${controllerName}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_controller_has_channel_check_channel", {
      channel_name: rfChanName
    })}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_controller_has_channel_check_profile", {
      channel_name: rfChanName,
      profile_description: profileDescr
    })}`);

    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.safety_check_unique_chan_profile_intro", {
      profile_id: ofdmChanProfId
    })}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_unique_chan_profile_do_command")}`);
    resultsLines.push(`show running-config | section ofdm channel-profile ${ofdmChanProfId}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_unique_chan_profile_check_expected")}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_unique_chan_profile_check_none")}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_unique_chan_profile_check_multiple")}`);

    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.safety_check_control_profile_intro", {
      profile_id: ofdmChanProfId
    })}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_control_profile_do_command")}`);
    resultsLines.push(`show running-config | section cable downstream ofdm-chan-profile ${ofdmChanProfId}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_control_profile_check_expected")}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_control_profile_check_examples", {
      profile_ncp: "profile-ncp",
      profile_control: "profile-control",
    })}`);
    resultsLines.push(`# ${INDENT}${INDENT}profile-ncp modulation-default 64-QAM`);
    resultsLines.push(`# ${INDENT}${INDENT}profile-control modulation-default 256-QAM`);
  }

  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.begin_configuration_heading")}`);
  resultsLines.push("configure terminal");

  // output modulationProfiles
  if (modulationProfiles.length > 0) {
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.create_mod_profiles_heading")}`);
    modulationProfiles.forEach(ofdmModulationProfile => {
      ofdmModulationProfile.getLines().forEach(line => resultsLines.push(line));
    });
  }

  if (!hitless) {
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.shut_down_ofdm_channel_heading")}`);
    resultsLines.push(`controller integrated-cable ${controllerLocation}`);
    resultsLines.push(`${INDENT}rf-chan ${cmtsChannelId}`);
    resultsLines.push(`${INDENT}${INDENT}shutdown`);
    resultsLines.push(`${INDENT}exit`);
    resultsLines.push(`exit`);
  } else {
    // get the list of known modems
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.get_modem_list_heading")}`);
    resultsLines.push(`# ${INDENT}Execute the following commands:`);
    resultsLines.push(`exit`);
    // privileged exec
    resultsLines.push(`show cable modem c${controllerLocation} verbose`);
    resultsLines.push(`# ${INDENT}Record the MAC addresses of all the modems that are using OFDM channel ${cmtsChannelId}.`);

    // switch to external PMA mode
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.switch_to_external_pma_heading")}`);
    resultsLines.push(`configure terminal`);
    resultsLines.push(`${INDENT}cable downstream ofdm-prof-mgmt prof-upgrade-pma`);
    resultsLines.push(`exit`);

    // move all cable modems to only profile 1
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.move_all_cms_to_only_1_heading")}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.move_all_cms_to_only_1_for_each")}`);

    // add profile 1 to the allowed profiles for the current modem
    resultsLines.push("");
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.add_1_to_allowed_header")}`);
    resultsLines.push(`# ${INDENT}${INDENT}${t_no_escape("deploy.commands.cbr8.get_current_allowed_do_command")}`);
    resultsLines.push(`show cable modem <mac_addr> prof-mgmt downstream`);
    resultsLines.push(`# ${INDENT}${INDENT}${t_no_escape("deploy.commands.cbr8.get_current_allowed_check")}`);
    resultsLines.push(`# ${INDENT}${INDENT}${t_no_escape("deploy.commands.cbr8.add_1_allowed_do_command")}`);
    // privileged exec
    resultsLines.push(`cable modem <mac_addr> ofdm-set-profiles chan ${cmtsChannelId} profiles \"0 1 <in_use_profile>\"`);

    // move the current modem to profile 1
    resultsLines.push("");
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.move_to_1_header")}`);
    resultsLines.push(`# ${INDENT}${INDENT}${t_no_escape("deploy.commands.cbr8.move_to_1_do_command")}`);
    resultsLines.push(`configure terminal`);
    resultsLines.push(`${INDENT}cable downstream ofdm-flow-to-profile`
        + ` interface Integrated-Cable ${controllerLocation}:${cmtsChannelId}`
        + ` profile-data 1 mac-address <mac_addr>`)
    resultsLines.push(`exit`);
    resultsLines.push(`# ${INDENT}${INDENT}${t_no_escape("deploy.commands.cbr8.force_profile_selection_do_command")}`);
    // privileged exec
    resultsLines.push(`cable modem <mac_addr> opt0`)

    // remove all but profile 1
    resultsLines.push("");
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.remove_all_but_1_do_command")}`);
    // privileged exec
    resultsLines.push(`cable modem <mac_addr> ofdm-set-profiles chan ${cmtsChannelId} profiles \"0 1\"`);

    // clear ofdm-flow-to-profile override
    resultsLines.push("");
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.clear_override_do_command")}`);
    resultsLines.push(`configure terminal`);
    resultsLines.push(`${INDENT}no cable downstream ofdm-flow-to-profile`
        + ` interface Integrated-Cable ${controllerLocation}:${cmtsChannelId}`
        + ` profile-data 1 mac-address <mac_addr>`)
    resultsLines.push(`exit`);

    // check modem status
    resultsLines.push("");
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.check_modem_status_do_command")}`);
    resultsLines.push(`show cable modem <mac_addr> prof-mgmt downstream`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.check_modem_status_check")}`);

    // end loop
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.repeat_steps_header")}`);

    // check channel status
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.check_channel_status_do_command")}`);
    resultsLines.push(`show controllers Integrated-Cable ${controllerLocation} rf-chan ${cmtsChannelId} prof-status`);
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.check_channel_status_check")}`);

    // re-enter config mode
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.reenter_configuration_mode_header")}`);
    resultsLines.push("configure terminal");
  }

  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.update_channel_profile_heading")}`);

  // output channel profile updates
  for (let index = 0; index < CBR8_NUM_PROFILE_IDS - FIRST_PROFILE_ID; ++index) {
    const dataProfileId = index + FIRST_PROFILE_ID;
    resultsLines.push("");
    let maybeBitLoading = "";
    let maybeClearUnused = "";
    if (modulationSpecs.length > index) {
      const modulationSpec = modulationSpecs[index];
      if (modulationSpec.meanBitLoading) {
        maybeBitLoading = t_no_escape("deploy.commands.cbr8.configure_profile_bit_loading", {
          bit_loading: modulationSpec.meanBitLoading
        });
      }
    } else {
      maybeClearUnused = t_no_escape("deploy.commands.cbr8.configure_profile_clear_unused");
    }
    const profileName = getProfileName(dataProfileId);
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.configure_profile_info", {
      profile_name: profileName,
      maybe_bit_loading: maybeBitLoading,
      maybe_clear_unused: maybeClearUnused
    })}`);
    resultsLines.push(`cable downstream ofdm-chan-profile ${ofdmChanProfId}`);
    resultsLines.push(`${INDENT}no ${profileName}`);
    resultsLines.push("exit");
    if (modulationSpecs.length > index) {
      resultsLines.push(`cable downstream ofdm-chan-profile ${ofdmChanProfId}`);
      resultsLines.push(`${INDENT}${profileName} ${modulationSpecs[index].toConfig()}`);
      resultsLines.push("exit");
    }
  }

  if (!hitless) {
    // restart channel
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.restart_ofdm_channel_heading")}`);
    resultsLines.push(`controller integrated-cable ${controllerLocation}`);
    resultsLines.push(`${INDENT}rf-chan ${cmtsChannelId}`);
    resultsLines.push(`${INDENT}${INDENT}no shutdown`);
    resultsLines.push(`${INDENT}exit`);
    resultsLines.push("exit");

    // reset cable modems
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.reset_cable_modems_heading")}`);
    resultsLines.push("exit");
    // privileged exec
    resultsLines.push(`clear cable modem cable ${controllerLocation} all reset`);
  } else {
    // switch to internal PMA mode
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.switch_to_internal_pma_heading")}`);
    resultsLines.push(`no cable downstream ofdm-prof-mgmt prof-upgrade-pma`);
    resultsLines.push(`exit`);

    // trigger free profile selection on all cable modems
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.trigger_free_profile_selection_heading")}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.trigger_free_profile_selection_do_command")}`);
    // privileged exec
    resultsLines.push(`cable modem <mac_addr> opt0`)
  }

  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.configuration_complete_heading")}`);

  // output debug info if requested
  if (includePmaDsResult) {
    resultsLines.push("");
    resultsLines.push(`# pmaDsResult: ${JSON.stringify(mutablePmaDsResult, BigIntReplacer)}`);
    // resultsLines.push(`# cmtsDsOfdmChan: ${JSON.stringify(cmtsDsOfdmChan, BigIntReplacer)}`);
    if (originalStartFreqHz) {
      resultsLines.push(`# StartFreq: original: ${originalStartFreqHz}, corrected: ${correctedStartFreqHz}`);
    }
    if (originalEndFreqHz) {
      resultsLines.push(`# EndFreq: original: ${originalEndFreqHz}, corrected: ${correctedEndFreqHz}`);
    }
  }

  return resultsLines.join("\n");
}
