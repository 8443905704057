/** Does a deep copy that handles primitive types, BigInt, Object, and Array. Other types will probably not be
 * handled correctly. */
export function SimpleDeepCopy<Type> (thing: Type) : Type {
  if (typeof thing != "object" || thing === null) {
    return thing;
  }
  if (Array.isArray(thing)) {
    // @ts-ignore
    return thing.map(element => SimpleDeepCopy(element))
  }
  // @ts-ignore
  return Object.fromEntries(Object.entries(thing).map(([key, value]) => [key, SimpleDeepCopy(value)]));
}
