import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {defaultLanguage} from "./languageConfig";

i18n.use(initReactI18next).init({
    fallbackLng: defaultLanguage,
    lng: defaultLanguage,
    resources: {
        en: {
            translations: require('./locales/en/translations.json')
        },
    },
    ns: ['translations'],
    defaultNS: 'translations'
});

i18n.languages = [defaultLanguage];

export default i18n;
