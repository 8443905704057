import {Button, Grid, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {RiBarChart2Fill} from "react-icons/ri";
import React, { useEffect } from "react";
import {GrafanaPanel} from "../Components/GrafanaPanel";
import {useTranslation} from "react-i18next";
import { useGrafanaLink } from "../Dashboard/Dashboard";

const RankingPanelStyle = `
.ranking-panel .panel-title {
  background: rgba(8, 26, 81, 0.8); }
  .ranking-panel .panel-title > h2 {
    color: white;
    font-size: 16px;
    font-family: 'Montsero', Roboto, sans-serif; }
    .ranking-panel .panel-title > h2:hover {
      color: white; }
.ranking-panel [role=table] > div > div > div > [role=rowgroup] > [role=row] {
  background: white;
  color: white; }
  .ranking-panel [role=table] > div > div > div > [role=rowgroup] > [role=row] [role=columnheader] {
    color: #437BE4; }
`;

const PanelIDs ={
    CmtsDetails: 4,
    MacDomainsAndChannels: 2
}

export function Reports(): JSX.Element {
    const dashboardUrl = "/grafana/d-solo/X1iP9NQ7z/operational-overview"
    const {t} = useTranslation();

    const {setGrafanaLinkButton} = useGrafanaLink();

    useEffect(() => {
        setGrafanaLinkButton(false);
    }, []);

    return <Container>
        <Grid container spacing={2}>
            <Grid item lg={12}>
                <GrafanaPanel
                    url={dashboardUrl}
                    panelId={PanelIDs.CmtsDetails}
                    width="100%"
                    height="300px"
                    injectedClassName="ranking-panel"
                    injectedStyle={RankingPanelStyle}
                />
            </Grid>
            <Grid item lg={12}>
                <GrafanaPanel
                    url={dashboardUrl}
                    panelId={PanelIDs.MacDomainsAndChannels}
                    width="100%"
                    height="300px"
                    injectedClassName="ranking-panel"
                    injectedStyle={RankingPanelStyle}
                />
            </Grid>
            <Grid item lg={12}>
                <Box sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex"
                }}>
                    <Button
                        href="/grafana/d/X1iP9NQ7z/operational-overview?from=now-7d&to=now"
                        target="_blank" variant="contained">
                        <Typography variant="h4"><RiBarChart2Fill height="32px" /> {t("reports.launch_reports")}</Typography>
                    </Button>
                </Box>
            </Grid>
        </Grid>
    </Container>
}
