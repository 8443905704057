import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  MenuItem,
  Typography
} from '@mui/material';
import Select from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, {useCallback, useEffect, useState} from 'react';
import { ApiRoutes } from '../../Api/ApiRoutes';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useFeedbackContext } from '../../Feedback/FeedbackContext';
import Placeholder from '../../Placeholder';
import { MdSave } from 'react-icons/md';
import {ApiGet, ApiPut} from '../../Api/Util';
import Box from '@mui/material/Box';
import {
  useGlobalTemplateProfilesContext,
  TemplateProfilesGlobalConfig
} from '../GlobalTemplateProfilesContext';
import {ListApiResponse} from "../../Api/Types/General";
import {TemplateProfile} from "../../Api/Types/Config";

export function GlobalTemplateProfilesConfig(): JSX.Element {
  const {
    globalTemplateProfilesState: { templateProfilesGlobalConfig, globalTemplateProfilesLoaded }
  } = useGlobalTemplateProfilesContext();

  const [dsTemplateProfilesData, setDsTemplateProfilesData] = useState<TemplateProfile[]>([]);
  const [usTemplateProfilesData, setUsTemplateProfilesData] = useState<TemplateProfile[]>([]);

  const { t } = useTranslation();
  const { setFeedbackState } = useFeedbackContext();

  const updateFailure = useCallback(
    (error: any) => {
      setFeedbackState({
        open: true,
        message: `${t('profile_config.failed_update')}: ${error.message}`,
        severity: 'error'
      });
    },
    [setFeedbackState, t]
  );

  const updateSuccess = useCallback(() => {
    setFeedbackState({
      open: true,
      message: `${t('template_config.success_update')}`,
      severity: 'success'
    });
  }, [setFeedbackState, t]);

  const getTemplateProfiles = () => {
    ApiGet<ListApiResponse<TemplateProfile>>(
      ApiRoutes.config.templateProfile.filter({
        tenant: 1,
      })
    )
      .then((response: ListApiResponse<TemplateProfile>) => {
        const dsTemplateProfiles = response.results.filter((template) => template.channel_direction === 'D');
        const usTemplateProfiles = response.results.filter((template) => template.channel_direction === 'U');
        setDsTemplateProfilesData(dsTemplateProfiles);
        setUsTemplateProfilesData(usTemplateProfiles);
      })
      .catch(error => {
        setFeedbackState({
          open: true,
          message: `${t('template_config.failed_list')}: ${error.message}`,
          severity: 'error'
        });
      });
  }

  useEffect(() => {
    getTemplateProfiles();
  }, []);

  const submitCallback = useCallback(
    (values: TemplateProfilesGlobalConfig) => {
      const downstreamTemplateProfilesData = {
        ...values.downstreamTemplateProfiles,
        calc_constraint_template_profile_id:
          !values.downstreamTemplateProfiles?.calc_constraint_template_profile_id
            ? null
            : values.downstreamTemplateProfiles?.calc_constraint_template_profile_id
      };
      const upstreamTemplateProfilesData = {
        ...values.upstreamTemplateProfiles,
        calc_constraint_template_profile_id:
          !values.upstreamTemplateProfiles?.calc_constraint_template_profile_id
            ? null
            : values.upstreamTemplateProfiles?.calc_constraint_template_profile_id
      };
      ApiPut(ApiRoutes.config.calcConstraintTemplateProfile.update, [
        downstreamTemplateProfilesData,
        upstreamTemplateProfilesData
      ]).then(updateSuccess, updateFailure);
    },
    [updateSuccess, updateFailure]
  );

  const DsCalcConstraintTemplateProfileId = templateProfilesGlobalConfig.downstreamTemplateProfiles?.calc_constraint_template_profile_id !== undefined &&
  templateProfilesGlobalConfig.downstreamTemplateProfiles?.calc_constraint_template_profile_id !== null
    ? templateProfilesGlobalConfig.downstreamTemplateProfiles?.calc_constraint_template_profile_id
    : 0
  const UsCalcConstraintTemplateProfileId = templateProfilesGlobalConfig.upstreamTemplateProfiles?.calc_constraint_template_profile_id !== undefined &&
  templateProfilesGlobalConfig.upstreamTemplateProfiles?.calc_constraint_template_profile_id !== null
    ? templateProfilesGlobalConfig.upstreamTemplateProfiles?.calc_constraint_template_profile_id
    : 0

  const formik = useFormik({
    initialValues: {
      ...templateProfilesGlobalConfig,
      downstreamTemplateProfiles: {
        ...templateProfilesGlobalConfig.downstreamTemplateProfiles,
        tenant: 1,
        cmts: null,
        channel_direction: 'D',
        mac_domain_ifdescr: null,
        channel_ifdescr: null,
        calc_constraint_template_profile_id: DsCalcConstraintTemplateProfileId
      },
      upstreamTemplateProfiles: {
        ...templateProfilesGlobalConfig.upstreamTemplateProfiles,
        tenant: 1,
        cmts: null,
        channel_direction: 'U',
        mac_domain_ifdescr: null,
        channel_ifdescr: null,
        calc_constraint_template_profile_id: UsCalcConstraintTemplateProfileId
      }
    },
    onSubmit: submitCallback
  });

  useEffect(() => {
    if (templateProfilesGlobalConfig) {
      formik.setValues({
        ...templateProfilesGlobalConfig,
        downstreamTemplateProfiles: {
          ...templateProfilesGlobalConfig.downstreamTemplateProfiles,
          tenant: 1,
          cmts: null,
          channel_direction: 'D',
          mac_domain_ifdescr: null,
          channel_ifdescr: null,
          calc_constraint_template_profile_id: DsCalcConstraintTemplateProfileId
        },
        upstreamTemplateProfiles: {
          ...templateProfilesGlobalConfig.upstreamTemplateProfiles,
          tenant: 1,
          cmts: null,
          channel_direction: 'U',
          mac_domain_ifdescr: null,
          channel_ifdescr: null,
          calc_constraint_template_profile_id: UsCalcConstraintTemplateProfileId
        }
      });
    }
  }, [templateProfilesGlobalConfig]);

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="global-topology-schedule">
          <Typography variant="h5">{t('template_config.title')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!globalTemplateProfilesLoaded ? (
            <Placeholder />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} >
                <Grid item xs={12} md={6}>
                  <Typography>{t(`template_config.ds_template`)}</Typography>
                  <Box sx={{ pl: '4px', pr: '4px' }}>
                    <Select
                      name={t('downstreamTemplateProfiles.calc_constraint_template_profile_id')}
                      value={formik.values.downstreamTemplateProfiles?.calc_constraint_template_profile_id || 0}
                      onChange={formik.handleChange}
                      variant={'standard'}
                      inputProps={{
                        'aria-label': t('template_config.ds_template_aria')
                      }}
                      sx={{ marginTop: '16px' }}
                    >
                      <MenuItem value={0}>{t(`template_config.none`)}</MenuItem>
                      {dsTemplateProfilesData.map((template) => (
                        <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>{t(`template_config.us_template`)}</Typography>
                  <Box sx={{ pl: '4px', pr: '4px' }}>
                    <Select
                      name={t('upstreamTemplateProfiles.calc_constraint_template_profile_id')}
                      value={formik.values.upstreamTemplateProfiles?.calc_constraint_template_profile_id || 0}
                      onChange={formik.handleChange}
                      variant={'standard'}
                      inputProps={{
                        'aria-label': t('template_config.us_template_aria')
                      }}
                      sx={{ marginTop: '16px' }}
                    >
                      <MenuItem value={0}>{t(`template_config.none`)}</MenuItem>
                      {usTemplateProfilesData.map((template) => (
                        <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    color="primary"
                    startIcon={<MdSave />}
                    variant="outlined"
                    data-testid="submit"
                    type="submit"
                  >
                    {t('calc_max_profiles_config.save')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
