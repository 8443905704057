import {
  FeedbackContextProvider,
  useFeedbackContext,
} from "../Feedback/FeedbackContext";
import React, { useEffect, useState } from "react";
import { ApiGet } from "../Api/Util";
import { ListApiResponse } from "../Api/Types/General";
import { CmtsDetails as CmtsDetailsType } from "../Api/Types/Analytics";
import { ApiRoutes } from "../Api/ApiRoutes";
import {
  GridColDef,
  GridPaginationModel,
} from "@mui/x-data-grid-pro";
import DataTable from "../Components/DataTable";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import MenuIcon from '@mui/icons-material/Menu';
import {Link} from "react-router-dom";

export type CmtsDetailsContentProp = {
  cmtsTagsParamCollection: number[];
  preferredStream: string;
};

export function CmtsDetailsContent({
  cmtsTagsParamCollection,
  preferredStream,
}: CmtsDetailsContentProp): JSX.Element {
  const { t } = useTranslation();
  const { feedbackError } = useFeedbackContext();

  const [cmtsDetailsData, setCmtsDetailsData] = React.useState<CmtsDetailsType[]>(
    [],
  );
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(100);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    loadData();
  }, [cmtsTagsParamCollection]);
  const loadData = () => {
    ApiGet<ListApiResponse<CmtsDetailsType>>(
      ApiRoutes.analytics.cmts_details.filter({
        regions: cmtsTagsParamCollection,
        offset: offset,
        limit: limit,
      }),
    )
      .then(async (response: ListApiResponse<CmtsDetailsType>) => {
        setTotalRows((prevTotalRows) =>
          response?.count !== undefined
            ? response?.count
            : prevTotalRows,
        );
        setCmtsDetailsData(response.results);
      })
      .catch((error) => {
        feedbackError("Unable to reach Cmts Details, please contact your system administrator.");
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'cmts_name',
      headerName: t("cmts_details.cmts_name"),
      flex: 1,
      renderCell: ({ row }) => {
        const UrlSearchParams = new URLSearchParams();
        const cmtsName = row.cmts_name;
        UrlSearchParams.append('var-cmts', cmtsName);
        return (
          <Link to={`/grafana/d/HHpd1vQ7k/operational-cmts?${UrlSearchParams.toString()}`} target="_blank">
            {cmtsName}
          </Link>
        )
      }
    },
    {
      field: 'software_version',
      headerName: t("cmts_details.software_version"),
      flex: 1
    },
    {
      field: 'device_type',
      headerName: t("cmts_details.device_type"),
      flex: 1
    },
    {
      field: 'ip_address',
      headerName: t("cmts_details.ip_address"),
      flex: 1
    },
    {
      field: 'unrealized_gain',
      headerName: t("cmts_details.unrealized_gain"),
      flex: 1,
      valueGetter: (value: number) => {
        return `${(value / 1000000).toFixed(2)} Mbps`;
      }
    },
    {
      field: 'snapshot',
      headerName: t("cmts_details.snap_shot"),
      flex: 1,
      valueGetter: (value: string): string => {
        if (!value) {
          return '---';
        }
        const date = new Date(value);
        const dateFormat = date.toDateString() + ' ' + date.getHours() + ':' + date.getMinutes();
        return dateFormat;
      }
    },
    {
      field: 'poll_interval',
      headerName: t("cmts_details.poll_interval"),
      flex: 1
    }
  ];

  const handlePaginationChange = (model: GridPaginationModel) => {
    const page = model.page;
    const limit = model.pageSize;

    setOffset(page * limit);
    setLimit(limit);
  };

  return (
    <Grid xs={12}>
      <DataTable
        rows={cmtsDetailsData}
        columns={columns}
        pageSize={5}
        onPaginationChange={handlePaginationChange}
        totalRows={totalRows}
        checkboxSelection={false}
        dataTableType="cmtsDetails"
      >
        <Box
          sx={{
            display: "flex",
            padding: "5px 0",
            backgroundColor: (theme) => theme.palette.primary.main,
            borderRadius: "8px 8px 0 0",
            // borderBottom: (theme) => `1px solid ${theme.palette.grey}`,
          }}
        >
          <Box sx={{ flexGrow: "1", display: "flex" }}>
            <Box
              sx={{
                paddingLeft: "10px",
                // paddingTop: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <MenuIcon fontSize="small" sx={{ color: "#FEFEFE" }} />
            </Box>
            <Typography
              variant="h6"
              sx={{
                paddingLeft: "10px",
                paddingTop: "5px",
                color: "#FEFEFE",
                marginRight: "auto",
              }}
            >
              {t("cmts_details.title")}
            </Typography>
          </Box>
        </Box>
      </DataTable>
    </Grid>
  );
}

export function CmtsDetails({
  cmtsTagsParamCollection,
  preferredStream,
}: CmtsDetailsContentProp): JSX.Element {
  return (
    <FeedbackContextProvider>
      <CmtsDetailsContent
        cmtsTagsParamCollection={cmtsTagsParamCollection}
        preferredStream={preferredStream}
      />
    </FeedbackContextProvider>
  );
}
