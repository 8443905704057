import {Skeleton} from "@mui/material";


export default function Placeholder(): JSX.Element {

    return <>
        <Skeleton variant="circular" animation="wave" width={40} height={40} sx={{bgcolor: 'grey.300'}} />
        <Skeleton animation="wave" sx={{bgcolor: 'grey.300'}} />
        <Skeleton animation="wave" sx={{bgcolor: 'grey.300'}} />
        <Skeleton variant="rectangular" height={118} animation="wave" sx={{bgcolor: 'grey.300'}} />
    </>
}
