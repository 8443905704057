import {useTranslation} from "react-i18next";
import {Box, Button, Typography} from "@mui/material";

export function NoChannelInfo(): JSX.Element {
  const {t} = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "20px",
        alignItems: "center"
      }}
    >
      <Box
        component="img"
        alt="404 Robot"
        src="/404-robot.svg"
      />
      <Typography color={(theme): any => theme.colors.inactive.main}>{t("deploy.no_info_404")}</Typography>
      <Button variant="outlined" color="secondary">{t("deploy.cta_404")}</Button>
    </Box>
  );
}
