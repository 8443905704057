import styled from "@emotion/styled";
import Divider from "@mui/material/Divider";

export const StyledDivider = styled(Divider)(() => ({
    "&::before": {
        width: "0",
        flex: "0 0 0px",
    },
    "&::before, &::after": {
        borderColor: "rgba(153, 153, 153, 0.60)",
    },
}));
