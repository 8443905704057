import React from "react";
import {Alert, IconButton} from "@mui/material";
import {MdClose} from "react-icons/md";
import Snackbar from "@mui/material/Snackbar";
import {useFeedbackContext} from "./FeedbackContext";


export function FeedbackSnackbar(): JSX.Element {

    const {feedbackState, setFeedbackState} = useFeedbackContext();

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string): void => {
        if (reason === 'clickaway') {
            return;
        }
        setFeedbackState({open: false, severity: 'info'});
    };


    const snackbarAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <MdClose fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    return (
        <Snackbar
            open={feedbackState.open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={feedbackState.message}
            action={snackbarAction}
        >
            <Alert onClose={handleClose} severity={feedbackState.severity} sx={{width: '100%'}} data-testid="alert">
                {feedbackState.message}
            </Alert>
        </Snackbar>
    );
}
