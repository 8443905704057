import {createContext, useContext, useMemo, useState} from "react";
import {ScheduleTypeEnum, TenantSchedule} from "../Api/Types/Config";
import {ListApiResponse} from "../Api/Types/General";

export type TenantTopologyScheduleState = {
    topology: TenantSchedule | null,
    cm_topology: TenantSchedule | null,
}
export type TenantPnmScheduleState = {
    dsRxmer: TenantSchedule | null,
    usRxmer: TenantSchedule | null,
    dsFec: TenantSchedule | null,
    usFec: TenantSchedule | null,
}
export type TenantProfileCalcScheduleState = {
    dsProfileCalc: TenantSchedule | null,
    usProfileCalc: TenantSchedule | null,
}

export type TenantKafkaScheduleState = {
    kafkaStream: TenantSchedule | null,
}

export type TenantScheduleState = {
    topologySchedule: TenantTopologyScheduleState,
    pnmSchedule: TenantPnmScheduleState,
    profileCalcSchedule: TenantProfileCalcScheduleState,
    kafkaSchedule: TenantKafkaScheduleState,
    tenantScheduleLoaded: boolean,
}
export const DefaultTenantScheduleState: TenantScheduleState = {
    topologySchedule: {
        topology: null,
        cm_topology: null,
    },
    pnmSchedule: {
        dsRxmer: null,
        usRxmer: null,
        dsFec: null,
        usFec: null,
    },
    profileCalcSchedule: {
        dsProfileCalc: null,
        usProfileCalc: null,
    },
    kafkaSchedule: {
        kafkaStream: null,
    },
    tenantScheduleLoaded: false
}

type GlobalConfigStateContextType = {
    tenantScheduleState: TenantScheduleState;
    setTenantScheduleState: (tenantScheduleState: TenantScheduleState) => void;
};

export const DefaultInitialContext: GlobalConfigStateContextType = {
    tenantScheduleState: DefaultTenantScheduleState,
    setTenantScheduleState: () => {
    },
};

export const GlobalConfigContext = createContext<GlobalConfigStateContextType>(DefaultInitialContext);

type Props = {
    children: JSX.Element[] | JSX.Element;
    initialTenantScheduleState?: TenantScheduleState
};


export function GlobalConfigContextProvider(
    {
        initialTenantScheduleState,
        children,
    }: Props): JSX.Element {
    const defaultTenantScheduleState = initialTenantScheduleState ? initialTenantScheduleState : DefaultTenantScheduleState;

    const [tenantScheduleState, setTenantScheduleState] = useState<TenantScheduleState>(defaultTenantScheduleState);

    const ctx = useMemo<GlobalConfigStateContextType>(
        () => ({
            tenantScheduleState: tenantScheduleState,
            setTenantScheduleState: setTenantScheduleState,
        }),
        [tenantScheduleState]
    );

    return (
        <GlobalConfigContext.Provider value={ctx}>
            {children}
        </GlobalConfigContext.Provider>
    );
}

export function useGlobalConfigContext(): GlobalConfigStateContextType {
    return useContext(GlobalConfigContext);
}

export function handleTenantScheduleQuery(
  response: ListApiResponse<TenantSchedule>,
  setTenantScheduleState: (tenantScheduleState: TenantScheduleState) => void): void
{

    const tenantScheduleState: TenantScheduleState = {
        topologySchedule: {
            topology: null,
            cm_topology: null,
        },
        pnmSchedule: {
            dsRxmer: null,
            usRxmer: null,
            dsFec: null,
            usFec: null,
        },
        profileCalcSchedule: {
            dsProfileCalc: null,
            usProfileCalc: null,
        },
        kafkaSchedule: {
            kafkaStream: null,
        },
        tenantScheduleLoaded: true
    };

    response.results.forEach((value: TenantSchedule) => {
        switch (value.schedule_type) {
            case ScheduleTypeEnum.TOPOLOGY:
                tenantScheduleState.topologySchedule.topology = value;
                break;
            case ScheduleTypeEnum.CM_TOPOLOGY:
                tenantScheduleState.topologySchedule.cm_topology = value;
                break;
            case ScheduleTypeEnum.DS_RXMER:
                tenantScheduleState.pnmSchedule.dsRxmer = value;
                break;
            case ScheduleTypeEnum.US_RXMER:
                tenantScheduleState.pnmSchedule.usRxmer = value;
                break;
            case ScheduleTypeEnum.DS_FEC:
                tenantScheduleState.pnmSchedule.dsFec = value;
                break;
            case ScheduleTypeEnum.US_FEC:
                tenantScheduleState.pnmSchedule.usFec = value;
                break;
            case ScheduleTypeEnum.DS_PROFILE_CALC:
                tenantScheduleState.profileCalcSchedule.dsProfileCalc = value;
                break;
            case ScheduleTypeEnum.US_PROFILE_CALC:
                tenantScheduleState.profileCalcSchedule.usProfileCalc = value;
                break;
            case ScheduleTypeEnum.KAFKA_STREAM:
                tenantScheduleState.kafkaSchedule.kafkaStream = value;
                break;
        }
    })

    setTenantScheduleState(tenantScheduleState);

}
