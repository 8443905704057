import React, { useMemo, useState } from 'react';
import './App.css';
import Dashboard from './Dashboard/Dashboard';
import { ThemeProvider } from '@mui/material/styles';
import { Themes } from './Theme';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import { NavRoutes } from './Navigation/NavRoutes';
import Placeholder from './Placeholder';
import { Config } from './Configuration/Config';
import { Reports } from './Reports/Reports';
import { Downstream } from './Downstream/Downstream';
import { DownstreamChannelDeployment } from './Deployment/DownstreamChannelDeployment';
import { NavigationError } from './Navigation/Error';
import { TechSupervisor } from './TechSupervisor/TechSupervisor';
import { ChannelView } from './ChannelView/ChannelView';
import { Deployment } from './Deployment/Deployment';
import { OperationalCM } from './OperationalCM/OperationalCM';
import { UserContextProvider } from './Components/UserContext';
import { UpstreamChannelDeployment } from './Deployment/UpstreamChannelDeployment';
import { Login } from './Login/Login';
import { Overview } from './Overview/Overview';
import {AuthContextProvider} from "./Authentication/AuthContext";
import ProtectedRoute from "./Authentication/ProtectedRoute";
import AdminProtectedRoutes from "./Authentication/AdminProtectedRoutes";
import { ManageUsers } from "./ManageUsers/ManageUsers";

function App(): JSX.Element {
  const [themeName, setThemeName] = useState<string>('light');

  const currentTheme = useMemo(() => {
    return Themes(themeName);
  }, [themeName]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path={NavRoutes.home} errorElement={<NavigationError />}>
        <Route path={NavRoutes.login} element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route element={<Dashboard />}>
            <Route path={NavRoutes.data} element={<Placeholder />} />
            <Route path={NavRoutes.config} element={<Config />} />
            {/* <Route path={NavRoutes.overview} element={<Overview />} /> */}
            <Route path={NavRoutes.users} element={<Placeholder />} />
            <Route path={NavRoutes.care} element={<Placeholder />} />
            <Route element={<AdminProtectedRoutes />}>
              <Route path={NavRoutes.manage_users} element={<ManageUsers />} />
              <Route path={NavRoutes.deployment.home} element={<Deployment />} />
            </Route>
            <Route path={NavRoutes.account} element={<Placeholder />} />
            <Route path={NavRoutes.reports} element={<Reports />} />
            <Route path={NavRoutes.tech_supervisor} element={<TechSupervisor />} />
            <Route
              path={NavRoutes.deployment.ds_channel.route}
              element={<DownstreamChannelDeployment />}
            />
            <Route
              path={NavRoutes.deployment.us_channel.route}
              element={<UpstreamChannelDeployment />}
            />
            <Route path={NavRoutes.downstream} element={<Downstream />} />
            <Route path={NavRoutes.channel_view} element={<ChannelView />} />
            <Route path={NavRoutes.operational_cm} element={<OperationalCM />} />
            <Route path="" element={<Overview />} />
          </Route>
        </Route>
      </Route>
    )
  );

  return (
    <div className="App">
      <AuthContextProvider>
        <UserContextProvider>
          <ThemeProvider theme={currentTheme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </UserContextProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
