import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider } from "@mui/material";
import { TextField } from "@mui/material";
import { MenuItem } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { FormEvent, useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { ApiPost } from "../Api/Util";
import {ApiRoutes} from "../Api/ApiRoutes";
import {FeedbackContextProvider, useFeedbackContext} from "../Feedback/FeedbackContext";
import {useTranslation} from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

export type AddUserProp = {
  onAddUserClick: () => void;
};

export const UserRoles = {
  Viewer: ["cb_can_view"],
  Manager: ["cb_can_configure"],
  Admin: ["cb_admin"],
};

export function AddUserContent({ onAddUserClick }: AddUserProp): JSX.Element {
  const { t } = useTranslation();
  const { setFeedbackState } = useFeedbackContext();

  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [userType, setUserType] = useState<string>("");

  const emailInputRef = React.useRef<HTMLInputElement>(null);
  const passwordInputRef = React.useRef<HTMLInputElement>(null);
  const confirmPasswordInputRef = React.useRef<HTMLInputElement>(null);
  const firstNameInputRef = React.useRef<HTMLInputElement>(null);
  const lastNameInputRef = React.useRef<HTMLInputElement>(null);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const addUserHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const tenant = 1;

    const enteredEmail = emailInputRef.current?.value;
    const enteredPassword = passwordInputRef.current?.value;
    const enteredConfirmPassword = confirmPasswordInputRef.current?.value;
    const enteredFirstname = firstNameInputRef.current?.value;
    const enteredLastname = lastNameInputRef.current?.value;
    let roles: string[] = [];

    if (enteredPassword !== enteredConfirmPassword) {
      setFeedbackState({
        open: true,
        message: `${t("manage_users.password_match")}`,
        severity: "error",
      });
      return;
    }

    if (userType === "viewer") {
      roles = UserRoles.Viewer;
    } else if (userType === "admin") {
      roles = UserRoles.Admin;
    } else if (userType === "manager") {
      roles = UserRoles.Manager;
    }

    const userData = {
      email: enteredEmail,
      username: enteredEmail,
      password: enteredPassword,
      first_name: enteredFirstname,
      last_name: enteredLastname,
      tenant: tenant,
      roles: roles,
    };

    addUser(userData);
  };

  const addUser = async (userData: any) => {
    ApiPost(ApiRoutes.users.users.all, userData)
      .then(async () => {
        onAddUserClick();
        setShowAddUserModal(!showAddUserModal);
        setFeedbackState({
          open: true,
          message: `${t("manage_users.add_user_success")}`,
          severity: "success",
        });
      })
      .catch((error) => {
        setFeedbackState({
          open: true,
          message: `${t("manage_users.add_user_failed")}: ${error.message}`,
          severity: "error",
        });
      });
  }

  return (
    <div>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setShowAddUserModal(!showAddUserModal)}
          sx={{
            fontFamily: "Encode Sans",
            fontWeight: "500",
            color: "#FEFEFE",
            border: ".5px solid #FEFEFE",
            margin: "0 5px",
            "&.Mui-disabled": {
              color: "#999",
              border: ".5px solid transparent",
            },
            textTransform: "none",
            "&.MuiButton-root:hover": {
              border: ".5px solid #F39205",
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          {t("add_user_modal.button_text")}
        </Button>
      </Box>
      <Modal
        open={showAddUserModal}
        aria-labelledby="modal-modal-create-new-user"
      >
        <Box sx={modalStyle}>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              padding: "10px",
            }}
          >
            <Typography sx={{ flexGrow: "1", color: "#FFF" }}>
              {t("add_user_modal.title")}
            </Typography>
            <IconButton
              onClick={() => setShowAddUserModal(!showAddUserModal)}
              sx={{ color: "#FFF" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ marginTop: "10px" }}>
            <Box sx={{ padding: "10px" }}>
              <Box
                component="form"
                onSubmit={addUserHandler}
                display="flex"
                flexDirection="column"
                gap="10px"
              >
                <TextField
                  id="firstName-input"
                  required
                  label="First Name"
                  variant="outlined"
                  inputRef={firstNameInputRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon>
                          <PersonIcon />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    "data-testid": "firstName",
                  }}
                />
                <TextField
                  id="lastName-input"
                  required
                  label="Last Name"
                  variant="outlined"
                  inputRef={lastNameInputRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon>
                          <PersonIcon />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    "data-testid": "lastname",
                  }}
                />
                <TextField
                  id="email-input"
                  required
                  label="Email Address - Username"
                  variant="outlined"
                  inputRef={emailInputRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon>
                          <MailOutlineIcon />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    "data-testid": "email",
                  }}
                />
                <TextField
                  id="user-type-select"
                  required
                  select
                  label="User Type"
                  value={userType}
                  onChange={(event) => setUserType(event.target.value)}
                >
                  <MenuItem value={"admin"}>Admin</MenuItem>)
                  <MenuItem value={"manager"}>Manager</MenuItem>)
                  <MenuItem value={"viewer"}>Viewer</MenuItem>
                </TextField>
                <TextField
                  id="password-input"
                  required
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  inputRef={passwordInputRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    "data-testid": "password",
                  }}
                />
                <TextField
                  id="confirm-password-input"
                  required
                  label="Confirm Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  inputRef={confirmPasswordInputRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    "data-testid": "password",
                  }}
                />
                <Box display="flex" justifyContent="end" gap="10px" mt="10px">
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setShowAddUserModal(!showAddUserModal)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export function AddUser({ onAddUserClick }: AddUserProp): JSX.Element {
  return (
    <FeedbackContextProvider>
      <AddUserContent onAddUserClick={onAddUserClick}/>
    </FeedbackContextProvider>
  );
}
