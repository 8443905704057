import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  GraphPanelStyle,
  RankingPanelStyle,
  LazyGrafanaPanel,
} from "../Components/GrafanaPanel";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import { alpha } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledDivider } from "../OperationalCM/StyledDivider";
import { useUserContext } from "../Components/UserContext";
import { CmtsTag} from "../Api/Types/Config";
import { useSearchParams } from "react-router-dom";
import { ListApiResponse } from "../Api/Types/General";
import { ApiGet } from "../Api/Util";
import { ApiRoutes } from "../Api/ApiRoutes";
import { useGrafanaLink } from "../Dashboard/Dashboard";
import { RegionSelectorComponent } from "../Components/Selectors/RegionSelectorComponent";

const PanelIDs = {
  CMTSDetails: 43,
};

const DownstreamPanelIDs = {
  HighestUncorrectableError: 30,
  GreatestTraffic: 28,
  LowestBoostedQAM: 32,
};

const UpstreamPanelIDs = {
  HighestUncorrectableError: 39,
  GreatestTraffic: 40,
  LowestBoostedQAM: 41,
};

type GrafanaStreamProp = {
  stream: any;
  title: string;
  isSingleStream: boolean;
  extraParams: any;
  loading: boolean;
};

type IdMap<T> = { [key: string]: T };

function GrafanaStream({
  stream,
  title,
  isSingleStream,
  extraParams,
  loading
}: GrafanaStreamProp): JSX.Element {
  const dashboardUrl =
    "/grafana/d-solo/xXcFiqA4z/tech-supervisor-overview";

  return (
    <>
      {!isSingleStream && (
        <Grid xs={12}>
          <StyledDivider textAlign="left">
            <Typography
              color={(theme): any => alpha(theme.palette.primary.main, 0.7)}
              fontWeight="600"
            >
              {title}
            </Typography>
          </StyledDivider>
        </Grid>
      )}
      <Grid lg={4}>
        <LazyGrafanaPanel
          url={dashboardUrl}
          panelId={stream.HighestUncorrectableError}
          width="100%"
          height="300px"
          injectedClassName="ranking-panel"
          injectedStyle={RankingPanelStyle}
          extraParams={extraParams}
          loading={loading}
        />
      </Grid>
      <Grid lg={4}>
        <LazyGrafanaPanel
          url={dashboardUrl}
          panelId={stream.GreatestTraffic}
          width="100%"
          height="300px"
          injectedClassName="ranking-panel"
          injectedStyle={RankingPanelStyle}
          extraParams={extraParams}
          loading={loading}
        />
      </Grid>
      <Grid lg={4}>
        <LazyGrafanaPanel
          url={dashboardUrl}
          panelId={stream.LowestBoostedQAM}
          width="100%"
          height="300px"
          injectedClassName="ranking-panel"
          injectedStyle={RankingPanelStyle}
          extraParams={extraParams}
          loading={loading}
        />
      </Grid>
    </>
  );
}

export function TechSupervisor(): JSX.Element {
  const { t } = useTranslation();
  const dashboardUrl =
    "/grafana/d-solo/xXcFiqA4z/tech-supervisor-overview";
  const dashboardLinkUrl = "/grafana/d/xXcFiqA4z/tech-supervisor-overview";

  const {setGrafanaLink, setGrafanaLinkButton} = useGrafanaLink();

  useEffect(() => {
      setGrafanaLink(dashboardLinkUrl);
      setGrafanaLinkButton(true);
  }, []);

  const [searchParams, setSearchParams] = useSearchParams({
    region: "all",
  });

  const [loading, setLoading] = useState(true);

  const cmtsTagParam: string[] | null = searchParams.getAll("region");

  const [cmtsTagIdToCmtsTag, setCmtsTagIdToCmtsTag] = useState<IdMap<CmtsTag>>(
    {}
  );

  const [currentCmtsTagIds, setCurrentCmtsTagIds] = useState<string[]>(
    cmtsTagParam ? cmtsTagParam : []
  );

  const cmtsTagsParamCollection: string[] = useMemo(() => {
    const collection:string[] = [];

    currentCmtsTagIds.map((cmtsTagId) => {
        if(cmtsTagId === "all"){
            collection.push("All");
        }else{
            if(cmtsTagId && Object.hasOwn(cmtsTagIdToCmtsTag, cmtsTagId)){
                collection.push(cmtsTagIdToCmtsTag[cmtsTagId]?.name);
            }else{
                // console.error('tag id does not exist in cmtsTagsIdToCmtsTag', cmtsTagIdToCmtsTag, cmtsTagId);
            }
        }
      });

    return collection
  }, [currentCmtsTagIds]);

  useEffect(() => {
    ApiGet<ListApiResponse<CmtsTag>>(ApiRoutes.config.cmtsTags.all)
      .then(async (response: ListApiResponse<CmtsTag>) => {
        const cmtsTagIdToCmtsTag: { [key: string]: CmtsTag } = {};
        response.results.map((result) => {
          if (result.id === undefined) {
            return;
          }
          cmtsTagIdToCmtsTag[Number(result.id)] = result;
        });

        setCmtsTagIdToCmtsTag(cmtsTagIdToCmtsTag);
        setLoading(false);
      })
      .catch((error) => {
        //TODO
      });
  }, []);

  const extraParams = {
    "var-region": cmtsTagsParamCollection,
  };

  useEffect(() => {
    setSearchParams({
      region: currentCmtsTagIds,
    });
  }, [currentCmtsTagIds]);

  const { tenant } = useUserContext();
  const preferredStream = tenant.preferred_stream;

  const isSingleStream = preferredStream !== "both";

  const mainHeaderText =
    preferredStream === "downstream"
      ? t("tech_supervisor.downstream")
      : preferredStream === "upstream"
      ? t("tech_supervisor.upstream")
      : preferredStream === "both" && t("tech_supervisor.ds_us");

      const handleSetCurrentCmtsTagIds = (value: string[]) => {
        setCurrentCmtsTagIds(value);
      };

      const handleSetCmtsTagIdToCmtsTag = (value: IdMap<CmtsTag>) => {
        setCmtsTagIdToCmtsTag(value);
      };

  return (
      <Grid container spacing={2}>
        <Grid xs={12}>
          <RegionSelectorComponent
          width={3}
          cmtsTagIdToCmtsTag={cmtsTagIdToCmtsTag}
          setCurrentCmtsTagIds={setCurrentCmtsTagIds}
          setCmtsTagIdToCmtsTag={setCmtsTagIdToCmtsTag}
          currentCmtsTagIds={currentCmtsTagIds}
        />
        </Grid>
        <Grid xs={12}>
          <StyledDivider textAlign="left">
            <Typography
              color={(theme): any => alpha(theme.palette.primary.main, 0.7)}
              fontWeight="600"
            >
              {mainHeaderText}
            </Typography>
          </StyledDivider>
        </Grid>
        <Grid xs={12}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={PanelIDs.CMTSDetails}
            width="100%"
            height="200px"
            injectedClassName="graph-panel"
            injectedStyle={GraphPanelStyle}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        {/* Downstream */}
        {(preferredStream === "both" || preferredStream === "downstream") && (
          <GrafanaStream
            stream={DownstreamPanelIDs}
            title={t("tech_supervisor.downstream")}
            isSingleStream={isSingleStream}
            extraParams={extraParams}
            loading={loading}
          />
        )}
        {/* Upstream */}
        {(preferredStream === "both" || preferredStream === "upstream") && (
          <GrafanaStream
            stream={UpstreamPanelIDs}
            title={t("tech_supervisor.upstream")}
            isSingleStream={isSingleStream}
            extraParams={extraParams}
            loading={loading}
          />
        )}
      </Grid>
  );
}
