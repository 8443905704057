import {
  MenuItem,
  Select,
  Typography,
  useTheme
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { CalcMaxProfilesInfo } from '../../Api/Types/Config';

type CalcMaxProfilesDropdownProps = {
  handleChange: any;
  calcMaxProfilesInfo: CalcMaxProfilesInfo | null;
  fieldName: string;
  globalCalcMaxProfiles?: number | null;
};

export function CalcMaxProfilesDropdown(props: CalcMaxProfilesDropdownProps): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleChange, calcMaxProfilesInfo, fieldName, globalCalcMaxProfiles } = props;

  const globalValue = globalCalcMaxProfiles !== undefined && globalCalcMaxProfiles !== null ? globalCalcMaxProfiles : 1;

  const initialCalcMaxProfiles =
    calcMaxProfilesInfo?.calc_max_profiles === null
      ? 1
      : (typeof calcMaxProfilesInfo?.calc_max_profiles === 'number'
        ? calcMaxProfilesInfo.calc_max_profiles
        : 1);

  const [calcMaxProfiles, setCalcMaxProfiles] = useState<number>(initialCalcMaxProfiles);

  useEffect(() => {
    setCalcMaxProfiles(initialCalcMaxProfiles);
    const returnObj = {
      ...calcMaxProfilesInfo,
      calc_max_profiles: calcMaxProfiles
    };
    handleChange(returnObj);
  }, []);

  const onCalcMaxProfilesChange = (event: any): void => {
    setCalcMaxProfiles(event.target.value);

    const returnObj = {
      ...calcMaxProfilesInfo,
      calc_max_profiles: Number(event.target.value)
    };
    handleChange(returnObj);
  };

  return (
    <Box>
      <Typography>{t(`calc_max_profiles_config.${fieldName}`)}</Typography>
      <Box sx={{ pl: '4px', pr: '4px' }}>
        <Select
          name={`${fieldName}.calc_max_profiles`}
          value={calcMaxProfiles}
          onChange={onCalcMaxProfilesChange}
          variant={'standard'}
          inputProps={{
            'aria-label': t(`calc_max_profiles_config.${fieldName}`)
        }}
          sx={{ marginTop: '16px' }}
        >
          <MenuItem value={1}>{t('calc_max_profiles_config.use_global')}</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem>
        </Select>
      </Box>
      {globalValue === 1 ?
        <Typography variant="subtitle1">
          {t('cmts_config.global_setting')} {t('calc_max_profiles_config.vendor_default')}
        </Typography>
        :
        <Typography variant="subtitle1">
          {t('cmts_config.global_setting')} {globalValue}
        </Typography>
      }
    </Box>
  );
}
