export function castToNumber(value: unknown, defaultValue: number | bigint | undefined): number | bigint {
  if (typeof value === "string") {
    return parseInt(value, 10);
  }

  if (typeof value === "number" || typeof value === "bigint") {
    return value;
  }

  if (typeof defaultValue === "undefined") {
    return 0;
  }

  return defaultValue;
}
