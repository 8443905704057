import React from "react";
import {useTranslation} from "react-i18next";
import {defaultLanguage} from "../i18n/languageConfig";

export function Timestamp({value_s}: { value_s: number| string | null | undefined}): JSX.Element {
    const {t} = useTranslation()
    if (!value_s) {
        return <em>{t("timestamp.never")}</em>;
    }

    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };


    const date = typeof(value_s) == "number"
        ? new Date(value_s * 1000)
        : new Date(value_s);
    return <em>{date.toLocaleString(defaultLanguage, options)}</em>;
}
