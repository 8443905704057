/* eslint-disable no-unused-vars */

export const enum ScheduleTypeEnum {
    UNSET = 'UNSET',
    TOPOLOGY = 'TOPOLOGY',
    CM_TOPOLOGY = 'CM_TOPO',
    DS_RXMER = 'DS_RXMER',
    US_RXMER = 'US_RXMER',
    DS_FEC = 'DS_FEC',
    US_FEC = 'US_FEC',
    DS_PROFILE_CALC = 'DS_PROFILE_CALC',
    US_PROFILE_CALC = 'US_PROFILE_CALC',
    KAFKA_STREAM = 'KAFKA_STREAM',
}

export type CalcMaxProfilesInfo = {
    tenant: number;
    cmts: number | null;
    channel_direction: string;
    mac_domain_ifdescr: string | null;
    channel_ifdescr: string | null;
    calc_max_profiles: number | null;
}

export type CalcConstraintTemplateProfile = {
  tenant: number;
  cmts: number | null;
  channel_direction: string;
  mac_domain_ifdescr: string | null;
  channel_ifdescr: string | null;
  calc_constraint_template_profile_id: number | null;
}

export type TemplateProfile = {
  id?: number;
  tenant: number;
  name: string;
  channel_direction: string;
  default_bit_loading: number;
  extra: string | null;
}

export type CmtsSchedule = {
    id?: number;
    active: boolean;
    last_run?: string | null;
    schedule_rate_s?: number | null;
    use_global: boolean;
};

export const enum CmtsTypeEnum {
    CISCO_CBR8 = "Cisco cBR8",
    ARRIS_E6000 = "Arris E6000",
    CASA_C40G = "CASA C40G",
    CASA_C100G = "CASA C100G",
    CASA_VCCAP = "CASA vCCAP",
}

export type CmtsState = {
    cmts_type: string | null;  // note: null when have something, but we don't recognize it
    cmts_version: string | null;
};

export type Cmts = {
    topology_schedule: CmtsSchedule;
    ds_rxmer_schedule: CmtsSchedule;
    us_rxmer_schedule: CmtsSchedule;
    ds_fec_schedule: CmtsSchedule;
    us_fec_schedule: CmtsSchedule;
    ds_profile_calc_schedule: CmtsSchedule;
    us_profile_calc_schedule: CmtsSchedule;
    cm_topo_schedule: CmtsSchedule;
    cmts_kafka_stream_schedule: CmtsSchedule;
    id?: number;
    name: string;
    hostname: string;
    cmts_port: number;
    cmts_community_string: string;
    cmts_tags?: any[];
    cm_port: number;
    cm_community_string: string;
    pnm_server_ipv4_addr?: string;
    tenant?: number;
    tags?: string[];
    state?: CmtsState | null;  // note: null when no topo run yet; NP when sending to server
    kafka_username: string;
    kafka_password: string;
    kafka_bootstrap_servers: string;
    kafka_group_id: string;
    kafka_rxmer_topic: string;
    kafka_topology_topic: string;
    kafka_extra_config: any;  // includes null but also undefined
};

export type CmtsTag = {
    id: bigint;
    name: string;
    tenant: number;
}

export type Tenant = {
    id: bigint;
    name: string;
    preferred_stream: string;
}

export type ChannelAutoDeployConfig = {
    cmts: bigint;
    mac_domain: bigint;
    channel: bigint;
    direction: string;
    override_cmts: boolean;
    auto_deploy: boolean;
};

export type TenantSchedule = {
    id: number;
    schedule_type: string;
    schedule_rate_s: number;
};

export const DefaultTenantSchedule = {
    id: 0,
    schedule_type: ScheduleTypeEnum.UNSET,
    schedule_rate_s: 3600,
}

export function getDefaultCmts(): Cmts {
    return {
        name: "",
        hostname: "",
        cmts_port: 161,
        cmts_community_string: "",
        cm_port: 161,
        cm_community_string: "",

        topology_schedule: {
            active: false,
            use_global: true,
            schedule_rate_s: 3600,
        },

        ds_rxmer_schedule: {
            active: false,
            use_global: true,
            schedule_rate_s: 3600,
        },

        us_rxmer_schedule: {
            active: false,
            use_global: true,
            schedule_rate_s: 3600,
        },

        ds_fec_schedule: {
            active: false,
            use_global: true,
            schedule_rate_s: 3600,
        },

        us_fec_schedule: {
            active: false,
            use_global: true,
            schedule_rate_s: 3600,
        },

        ds_profile_calc_schedule: {
            active: false,
            use_global: true,
            schedule_rate_s: 3600,
        },

        us_profile_calc_schedule: {
            active: false,
            use_global: true,
            schedule_rate_s: 3600,
        },

        cm_topo_schedule: {
            active: false,
            use_global: true,
            schedule_rate_s: 3600,
        },

        cmts_kafka_stream_schedule: {
            active: false,
            use_global: true,
            schedule_rate_s: 3600,
        },

        pnm_server_ipv4_addr: "",
        tags: [],

        kafka_username: "",
        kafka_password: "",
        kafka_bootstrap_servers: "",
        kafka_group_id: "",
        kafka_rxmer_topic: "",
        kafka_topology_topic: "",
        kafka_extra_config: null,
    }
}

export type ConfigType = Cmts | ChannelAutoDeployConfig | CmtsSchedule | TenantSchedule | CmtsTag | CalcMaxProfilesInfo | CalcConstraintTemplateProfile | TemplateProfile;
