import axios, {AxiosHeaders, AxiosRequestConfig, AxiosResponse} from 'axios';

import { ListApiResponse } from './Types/General';
import { CapacityBoosterType } from './Types/CapacityBooster';
import { ConfigType } from './Types/Config';
import { TopologyType } from './Types/Topology';
import { TraceType } from './Types/Trace';
import { Docsis31Type } from './Types/Docis31';
import { ApiRoutes } from './ApiRoutes';

import { UserLoginResponse } from './Types/Auth';

import { getCookie, setCookie, removeCookie } from '../Utils/Cookies';
import { NavigationException } from '../Navigation/Error';


const axiosConfig = axios.create({
  headers: {
    "Content-Type": "application/json"
  },
  responseType: "json"
})

axiosConfig.interceptors.response.use(function (response: AxiosResponse) {
  return response.data;
}, async function (error) {
  const originalRequest = error.config;
  if ((error.response.status === 403 || error.response.status === 401) && !originalRequest._retry) {
    originalRequest._retry = true;
    const accessToken = await ApiRefreshAccessToken();
    originalRequest.headers.set("Authorization", `bearer ${accessToken}`)
    return axiosConfig(originalRequest)
  }
  return Promise.reject(error)
});

class ApiDataError extends Error {
  public data: any;

  constructor(message: string, data: any) {
    super();
    this.message = message;
    this.data = data;
  }
}

function getDefaultHeaders(): any {
  const accessToken = getCookie("userToken")

  const headers: any = {
    "Content-Type": "application/json"
  }
  if (accessToken) {
    headers["Authorization"] = `bearer ${accessToken}`
  }

  return headers
}

async function DoFetch<T>(request: AxiosRequestConfig): Promise<T> {
  return window.api.request(request)
    .then(async (response: AxiosResponse) => {
      if (response.status < 200 || response.status > 299) {
        return Promise.reject(
          new NavigationException(response.status, response.statusText, response.data)
        );
      }
      return response;
    }, (error) => {
      return Promise.reject(
        new NavigationException(error.response.status, error.response.statusText, error.response.data)
      );
    })
    .then((value: unknown) => {
      const cast = value as T;
      if (!cast && value) {
        return Promise.reject(
          new ApiDataError('Data cast failure: Response from server unknown', value)
        );
      }
      return cast;
    });
}

async function ApiGet<T>(url: string, headerConfig: object = {}): Promise<T> {
  const headers = {
    ...getDefaultHeaders(),
    ...headerConfig
  }

  const request: AxiosRequestConfig = {
    url,
    method: "GET",
    headers: new AxiosHeaders(headers)
  }

  return DoFetch<T>(request)
}

async function ApiPatch<T>(url: string, data: any, headerConfig: object = {}): Promise<T> {
  const headers = {
    ...getDefaultHeaders(),
    ...headerConfig
  }

  const request: AxiosRequestConfig = {
    url,
    method: "PATCH",
    headers: new AxiosHeaders(headers),
    data: JSON.stringify(data)
  }

  return DoFetch<T>(request)
}

async function ApiPut<T>(url: string, data: any, headerConfig: object = {}): Promise<T> {
  const headers = {
    ...getDefaultHeaders(),
    ...headerConfig
  }

  const request: AxiosRequestConfig = {
    url,
    method: "PUT",
    headers: new AxiosHeaders(headers),
    data: JSON.stringify(data)
  }

  return DoFetch<T>(request)
}

async function ApiPost<T>(url: string, data: any, headerConfig: object = {}): Promise<T> {
  const headers = {
    ...getDefaultHeaders(),
    ...headerConfig
  }

  const request: AxiosRequestConfig = {
    url,
    method: "POST",
    headers: new AxiosHeaders(headers),
    data: JSON.stringify(data)
  }

  return DoFetch<T>(request)
}

async function ApiDelete<T>(url: string, headerConfig: object = {}): Promise<T> {
  const headers = {
    ...getDefaultHeaders(),
    ...headerConfig
  }

  const request: AxiosRequestConfig = {
    url,
    method: "DELETE",
    headers: new AxiosHeaders(headers)
  }

  return DoFetch<T>(request)
}

function isListApiResponse<T>(value: any): value is ListApiResponse<T> {
  return !!(value as ListApiResponse<T>);
}

function isApiResponse<T>(value: any): value is ListApiResponse<T> {
  return !!(value as T);
}

async function ApiRefreshAccessToken() {
  const refreshToken = getCookie("refreshToken")
  const data = {
    refresh_token: refreshToken
  }
  let response: any = {};
  try {
    response = await window.api.post(ApiRoutes.auth.refresh_token, data)
  } catch(error) {
    console.error(error);
    removeCookie("userToken");
    removeCookie("userTokenExpiration")
    removeCookie("refreshToken")
    return null
  }
  const accessToken = response["access_token"]

  const tokenExpires = new Date();
  tokenExpires.setHours(tokenExpires.getHours() + 24);
  setCookie("userToken", accessToken, tokenExpires.getTime())
  setCookie("userTokenExpiration", tokenExpires.toString(), tokenExpires.getTime())
  return accessToken
}

async function ApiLogin<T>(username: string, password: string, headerConfig: object = {}) {
  const data = {
    username: username,
    password: password
  };

  return ApiPost<T>(
    ApiRoutes.auth.login,
    data
  )
}

async function ApiLogout<T>(refreshToken: string, headerConfig: object = {}) {
  const data = { refresh_token: refreshToken };
  return ApiPost<T>(ApiRoutes.auth.logout, data)
}

enum ChannelDirection {
  Downstream = 'DS',
  Upstream = 'US'
}

enum ChannelDirectionLong {
  Downstream = 'downstream',
  Upstream = 'upstream'
}

export type ApiType =
  | UserLoginResponse
  | ConfigType
  | ListApiResponse<ConfigType>
  | TopologyType
  | ListApiResponse<TopologyType>
  | TraceType
  | ListApiResponse<TraceType>
  | CapacityBoosterType
  | ListApiResponse<CapacityBoosterType>
  | Docsis31Type
  | ListApiResponse<Docsis31Type>;


export {
  axiosConfig,
  ApiDataError,
  ChannelDirection,
  ChannelDirectionLong,
  ApiGet,
  ApiPatch,
  ApiPut,
  ApiPost,
  ApiDelete,
  ApiLogin,
  ApiLogout,
  isListApiResponse,
  isApiResponse,
  ApiRefreshAccessToken
}
