import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";
import {useTranslation} from "react-i18next";

export function Copyright(props: any): JSX.Element {

  const { t } = useTranslation();

  const versionNumber = process.env.REACT_APP_RELEASE_VERSION || 'dev';

    return (
      <>
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
          {'Copyright © '}
          <Link color="inherit" href="https://www.openvault.com/">
            OpenVault
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center">
          {t(`core.version`)} {versionNumber}
        </Typography>
      </>
    );
}
