import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Cmts, CmtsTypeEnum} from "../../../Api/Types/Config";
import {ClipboardCopyButton} from "../../../Components/ClipboardCopyButton";
import {C100gDsManualProfileDeployment} from "./C100gDsManualProfileDeployment";
import {C100gUsManualProfileDeployment} from "./C100gUsManualProfileDeployment";
import {Cbr8DsManualProfileDeployment} from "./Cbr8DsManualProfileDeployment";
import {Cbr8UsManualProfileDeployment} from "./Cbr8UsManualProfileDeployment";
import {DataLoadingManualProfileDeployment} from "./DataLoadingManualProfileDeployment";
import {E6kDsManualProfileDeployment} from "./E6kDsManualProfileDeployment";
import {E6kUsManualProfileDeployment} from "./E6kUsManualProfileDeployment";
import {UnknownCmtsManualProfileDeployment} from "./UnknownCmtsManualProfileDeployment";


//----------------------------------------------------------------------------------------------------------------------
export function ManualDeploymentInstructionsDetail(
  {children, instructions}: {
    children: JSX.Element | JSX.Element[];
    instructions?: string
  }): JSX.Element
{

  const {t} = useTranslation();

  return <AccordionDetails>
    {children}
    {instructions
      ?
      <>
        <Typography variant={"h6"} color={(theme): any => theme.palette.primary.main}>
          {t("deploy.suggested_commands")}
        </Typography>
        <TextField
          multiline
          rows={10}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          value={instructions}
        />
        <ClipboardCopyButton copyText={instructions} />
      </>
      :
      <></>
    }
  </AccordionDetails>;

}

//----------------------------------------------------------------------------------------------------------------------
export function ManualProfileDeployment(
  {cmtsInfo, downstream, title}: {
    cmtsInfo: Cmts | null,
    downstream: boolean,
    title?: string,
  }): JSX.Element
{

  const {t} = useTranslation();

  const manualDeploymentInstructions = useMemo((): JSX.Element => {
    if (cmtsInfo === null) {
      return <DataLoadingManualProfileDeployment />;
    }

    if (downstream) {
      if (cmtsInfo.state?.cmts_type === CmtsTypeEnum.CASA_C100G
        || cmtsInfo.state?.cmts_type === CmtsTypeEnum.CASA_C40G
        || cmtsInfo.state?.cmts_type === CmtsTypeEnum.CASA_VCCAP
      ) {
        return <C100gDsManualProfileDeployment />;
      } else if (cmtsInfo.state?.cmts_type === CmtsTypeEnum.CISCO_CBR8) {
        return <Cbr8DsManualProfileDeployment />;
      } else if (cmtsInfo.state?.cmts_type === CmtsTypeEnum.ARRIS_E6000) {
        return <E6kDsManualProfileDeployment />;
      }
    } else {
      if (cmtsInfo.state?.cmts_type === CmtsTypeEnum.CASA_C100G
        || cmtsInfo.state?.cmts_type === CmtsTypeEnum.CASA_C40G
        || cmtsInfo.state?.cmts_type === CmtsTypeEnum.CASA_VCCAP
      ) {
        return <C100gUsManualProfileDeployment />;
      } else if (cmtsInfo.state?.cmts_type === CmtsTypeEnum.CISCO_CBR8) {
        return <Cbr8UsManualProfileDeployment />;
      } else if (cmtsInfo.state?.cmts_type === CmtsTypeEnum.ARRIS_E6000) {
        return <E6kUsManualProfileDeployment />;
      }
    }

    return <UnknownCmtsManualProfileDeployment cmtsType={cmtsInfo.state?.cmts_type} />;

  }, [cmtsInfo, downstream]);

  return (
    <Grid2 xs={12}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="manualDeploy-content"
          id="manualDeploy-header"
        >
          <Typography variant={"h5"} color={(theme): any => theme.palette.primary.main}>
            {title ? title : t("deploy.manual_deployment_accordion_header")}
          </Typography>
        </AccordionSummary>
        {manualDeploymentInstructions}
      </Accordion>
    </Grid2>
  );
}


