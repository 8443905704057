import React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { ChannelActivityType } from '../../Api/Types/CapacityBooster';
import { Link } from 'react-router-dom';
import { NavRoutes } from '../../Navigation/NavRoutes';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';

const infoColor = '#214B83';
const infoBackgroundColor = 'rgba(16, 124, 185, 0.15)';
const warningColor = '#D27E20';
const warningBackgroundColor = 'rgba(210, 126, 32, 0.15)';
const errorColor = '#A61818';
const errorBackgroundColor = 'rgba(187, 31, 31, 0.15)';

export const enum ConfirmationDialogColors {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}

export type ConfirmationDialogType = {
  open: boolean;
  title: string;
  message: string;
  subMessage?: string;
  subMessage2?: string;
  confirmText?: string;
  confirmButton: string;
  configText?: boolean;
  testId?: string;
  onClose: (value: boolean) => void;
  children?: JSX.Element[] | JSX.Element;
  channels?: any;
  colorType?: ConfirmationDialogColors;
};

export function ConfirmationDialog({
  open,
  title,
  message,
  subMessage,
  confirmText,
  subMessage2,
  confirmButton,
  configText,
  onClose,
  channels,
  colorType,
  children,
  testId
}: ConfirmationDialogType) {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onClose(true);
  };

  const handleClose = () => {
    onClose(false);
  };

  const getColors = () => {
    return colorType === ConfirmationDialogColors.INFO
      ? infoColor
      : colorType === ConfirmationDialogColors.WARNING
        ? warningColor
        : colorType === ConfirmationDialogColors.ERROR
          ? errorColor
          : '#A61818';
  };

  const getBackgroundColors = () => {
    return colorType === ConfirmationDialogColors.INFO
      ? infoBackgroundColor
      : colorType === ConfirmationDialogColors.WARNING
        ? warningBackgroundColor
        : colorType === ConfirmationDialogColors.ERROR
          ? errorBackgroundColor
          : '#A61818';
  };

  return (
    <>
      {children}
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle
          sx={{
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            gap: '20px'
          }}
        >
          <WarningOutlinedIcon
            sx={{
              color: getColors(),
              backgroundColor: getBackgroundColors(),
              borderRadius: '30px',
              padding: '5px',
              fontSize: '50px'
            }}
          />
          <Box>
            <Typography
              fontWeight="600"
              fontSize="26px"
              sx={{
                color: getColors()
              }}
            >
              {title}
            </Typography>
            <DialogContentText>{message}</DialogContentText>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Box>
            {channels && channels.length > 0 ? (
              <ul>
                {channels.map((channel: ChannelActivityType) => (
                  <li key={Number(channel.channel_id)}>{channel.channel_ifdescr}</li>
                ))}
              </ul>
            ) : (
              ''
            )}
          </Box>
          <DialogContentText
            sx={{
              color: getColors(),
              display: 'flex',
              alignItems: 'center',
              gap: '5px'
            }}
          >
            <InfoOutlinedIcon
              fontSize="small"
              sx={{
                color: getColors()
              }}
            />{' '}
            {subMessage}
          </DialogContentText>
          {subMessage2 && (
            <DialogContentText
              sx={{
                color: getColors(),
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
              }}
            >
              <InfoOutlinedIcon
                fontSize="small"
                sx={{
                  color: getColors()
                }}
              />
              {subMessage2}
            </DialogContentText>
          )}
          {configText && (
            <DialogContentText
              sx={{
                color: getColors(),
                textAlign: 'center'
              }}
            >
              <Typography fontSize="14px" color="rgba(51, 51, 51, 1)" lineHeight={2}>
                {t('channel_activity.configure')}
                <Link to={NavRoutes.config}>{t('channel_activity.configure_link')}</Link>
              </Typography>
            </DialogContentText>
          )}
          <DialogContentText
            sx={{
              fontWeight: '600',
              color: getColors()
            }}
          >
            {confirmText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              color: getColors()
            }}
          >
            {t('channel_activity.go_back')}
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            autoFocus
            sx={{
              backgroundColor: getColors(),
              color: 'rgba(250, 250, 250, 1)'
            }}
            data-testid={testId}
          >
            {confirmButton}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
