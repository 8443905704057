import React, { useCallback, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  FormControl,
  FormGroup,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { MdDelete, MdSave } from 'react-icons/md';
import { useFormik } from 'formik';
import NumberField from '../../Components/NumberField';
import { ApiRoutes } from '../../Api/ApiRoutes';
import { useTranslation } from 'react-i18next';
import { useFeedbackContext } from '../../Feedback/FeedbackContext';
import { validateCmts } from './ValidateCmts';
import { ScheduleToggle } from './ScheduleToggle';
import { useGlobalConfigContext } from '../GlobalStateContext';
import { TagField } from '../../Components/TagField';
import { ListApiResponse } from '../../Api/Types/General';
import {
  Cmts,
  CmtsSchedule,
  CmtsTag,
  CalcMaxProfilesInfo,
  CalcConstraintTemplateProfile
} from '../../Api/Types/Config';
import { ApiGet, ApiPut, ApiDelete } from '../../Api/Util';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { DeleteConfirmationDialog } from '../../Utils/DeleteConfirmationDialog';
import { useGlobalCalcMaxProfilesContext } from '../GlobalCalcMaxProfilesContext';
import { CalcMaxProfilesDropdown } from '../Profiles/CalcMaxProfilesDropdown';
import { useGlobalTemplateProfilesContext } from '../GlobalTemplateProfilesContext';
import { CalcTemplateProfilesDropdown } from '../Profiles/CalcTemplateProfilesDropdown';

type CmtsConfigPanelProps = {
  cmts: Cmts;
  onDeleteCallback?: any;
  updateCmtsList: any;
};

export function CmtsConfigPanel(props: CmtsConfigPanelProps): JSX.Element {
  const { cmts, updateCmtsList } = props;
  const { t } = useTranslation();
  const { setFeedbackState } = useFeedbackContext();
  const [initialTags, setInitialTags] = useState<CmtsTag[]>([]);

  const { globalCalcMaxProfilesState } = useGlobalCalcMaxProfilesContext();
  const { globalTemplateProfilesState } = useGlobalTemplateProfilesContext();

  const [showCMTSCommunityString, setShowCMTSCommunityString] = useState<boolean>(false);
  const [showCmCommunityString, setShowCmCommunityString] = useState<boolean>(false);
  const [showKafkaPasswordString, setShowKafkaPasswordString] = useState<boolean>(false);

  const [dsCalcConstraintTemplateProfiles, setDsCalcConstraintTemplateProfiles] =
    useState<CalcConstraintTemplateProfile | null>(null);
  const [usCalcConstraintTemplateProfiles, setUsCalcConstraintTemplateProfiles] =
    useState<CalcConstraintTemplateProfile | null>(null);

  const [dsCalcMaxProfilesInfo, setDsCalcMaxProfilesInfo] = useState<CalcMaxProfilesInfo | null>(
    null
  );
  const [usCalcMaxProfilesInfo, setUsCalcMaxProfilesInfo] = useState<CalcMaxProfilesInfo | null>(
    null
  );

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { tenantScheduleState } = useGlobalConfigContext();

  const fetchTags = (): void => {
    ApiGet<ListApiResponse<CmtsTag>>(ApiRoutes.config.cmtsTags.all)
      .then(async (response): Promise<any> => {
        setInitialTags(response.results);
      })
      .catch(error => {
        setFeedbackState({
          open: true,
          message: `${t('cmts_config.failed_get_tags')}: ${error.message}`,
          severity: 'error'
        });
      });
  };

  const fetchDsCalcMaxProfiles = (): void => {
    ApiGet<CalcMaxProfilesInfo>(
      ApiRoutes.config.calcMaxProfiles.filter({
        tenantId: 1,
        channelDirection: 'D',
        cmtsId: cmts.id
      })
    )
      .then(async (response: CalcMaxProfilesInfo): Promise<any> => {
        setDsCalcMaxProfilesInfo(response);
      })
      .catch(error => {
        setFeedbackState({
          open: true,
          message: `${t('cmts_config.failed_get_ds_calc_max_profiles')}: ${error.message}`,
          severity: 'error'
        });
      });
  };

  const fetchUsCalcMaxProfiles = (): void => {
    ApiGet<CalcMaxProfilesInfo>(
      ApiRoutes.config.calcMaxProfiles.filter({
        tenantId: 1,
        channelDirection: 'U',
        cmtsId: cmts.id
      })
    )
      .then(async (response: CalcMaxProfilesInfo): Promise<any> => {
        setUsCalcMaxProfilesInfo(response);
      })
      .catch(error => {
        setFeedbackState({
          open: true,
          message: `${t('cmts_config.failed_get_us_calc_max_profiles')}: ${error.message}`,
          severity: 'error'
        });
      });
  };

  const fetchDsCalcConstraintTemplateProfile = (): void => {
    ApiGet<CalcConstraintTemplateProfile>(
      ApiRoutes.config.calcConstraintTemplateProfile.filter({
        tenantId: 1,
        channelDirection: 'D',
        cmtsId: cmts.id
      })
    )
      .then(async (response: CalcConstraintTemplateProfile): Promise<any> => {
        setDsCalcConstraintTemplateProfiles(response);
      })
      .catch(error => {
        setFeedbackState({
          open: true,
          message: `${t('cmts_config.failed_get_ds_template_profiles')}: ${error.message}`,
          severity: 'error'
        });
      });
  };

  const fetchUsCalcConstraintTemplateProfile = (): void => {
    ApiGet<CalcConstraintTemplateProfile>(
      ApiRoutes.config.calcConstraintTemplateProfile.filter({
        tenantId: 1,
        channelDirection: 'U',
        cmtsId: cmts.id
      })
    )
      .then(async (response: CalcConstraintTemplateProfile): Promise<any> => {
        setUsCalcConstraintTemplateProfiles(response);
      })
      .catch(error => {
        setFeedbackState({
          open: true,
          message: `${t('cmts_config.failed_get_us_template_profiles')}: ${error.message}`,
          severity: 'error'
        });
      });
  };

  useEffect(fetchTags, [setInitialTags]);
  useEffect(() => {
    fetchDsCalcMaxProfiles();
    fetchUsCalcMaxProfiles();
    fetchDsCalcConstraintTemplateProfile();
    fetchUsCalcConstraintTemplateProfile();
  }, []);

  const submitCallback = (values: Cmts) => {
      const formattedData = {
        ...values,
        kafka_extra_config:
          values.kafka_extra_config && values.kafka_extra_config.trim() !== ''
            ? JSON.parse(values.kafka_extra_config.trim())
            : null
      };
      ApiPut(ApiRoutes.config.cmts.single(values.id), formattedData)
        .then(async () => {
          setFeedbackState({
            open: true,
            message: `${t('cmts_config.success_update')}`,
            severity: 'success'
          });
          submitProfiles();
          submitTemplates();
          updateCmtsList?.();
        },
        error => {
          setFeedbackState({
            open: true,
            message: `${t('cmts_config.failed_update')}: ${error.message}`,
            severity: 'error'
          });
        }
      );
    };

  const submitProfiles = () => {
    const dsCalcMaxProfilesData = {
      ...dsCalcMaxProfilesInfo,
      calc_max_profiles:
        dsCalcMaxProfilesInfo?.calc_max_profiles === 1
          ? null
          : dsCalcMaxProfilesInfo?.calc_max_profiles
    };
    const usCalcMaxProfilesData = {
      ...usCalcMaxProfilesInfo,
      calc_max_profiles:
        usCalcMaxProfilesInfo?.calc_max_profiles === 1
          ? null
          : usCalcMaxProfilesInfo?.calc_max_profiles
    };
    const data = [dsCalcMaxProfilesData, usCalcMaxProfilesData];
    ApiPut(ApiRoutes.config.calcMaxProfiles.update, data)
      .then(async () => {
        setFeedbackState({
          open: true,
          message: `${t('calc_max_profiles_config.success_update')}`,
          severity: 'success'
        });
        updateCmtsList?.();
      })
      .catch(error => {
        setFeedbackState({
          open: true,
          message: `${t('calc_max_profiles_config.failed_update')}: ${error.message}`,
          severity: 'error'
        });
      });
  };

  const submitTemplates = () => {
    const dsTemplateProfilesData = {
      ...dsCalcConstraintTemplateProfiles,
      tenant: 1,
      channel_direction: 'D',
      calc_constraint_template_profile_id:
        dsCalcConstraintTemplateProfiles?.calc_constraint_template_profile_id === 0
          ? null
          : dsCalcConstraintTemplateProfiles?.calc_constraint_template_profile_id
    };
    const usTemplateProfilesData = {
      ...usCalcConstraintTemplateProfiles,
      tenant: 1,
      channel_direction: 'U',
      calc_constraint_template_profile_id:
        usCalcConstraintTemplateProfiles?.calc_constraint_template_profile_id === 0
          ? null
          : usCalcConstraintTemplateProfiles?.calc_constraint_template_profile_id
    };
    const data = [dsTemplateProfilesData, usTemplateProfilesData];
    ApiPut(ApiRoutes.config.calcConstraintTemplateProfile.update, data)
      .then(async () => {
        setFeedbackState({
          open: true,
          message: `${t('template_config.success_update')}`,
          severity: 'success'
        });
        updateCmtsList?.();
      })
      .catch(error => {
        setFeedbackState({
          open: true,
          message: `${t('template_config.failed_update')}: ${error.message}`,
          severity: 'error'
        });
      });
  };

  const handleDelete = useCallback(() => {
    ApiDelete(ApiRoutes.config.cmts.single(cmts.id)).then(async () => {
      updateCmtsList();
    });
  }, [updateCmtsList]);

  const validateSubmission = useCallback((values: Cmts): object => validateCmts(t, values), [t]);

  const formik = useFormik({
    initialValues: {
      ...cmts,
      hostname: cmts.hostname || '',
      cmts_port: cmts.cmts_port || 0,
      cmts_community_string: cmts.cmts_community_string || '',
      cm_port: cmts.cm_port || 0,
      cm_community_string: cmts.cm_community_string || '',
      pnm_server_ipv4_addr: cmts.pnm_server_ipv4_addr || '',
      tags: cmts.tags || [],
      topology_schedule: cmts.topology_schedule || { schedule_rate_s: 0 },
      cm_topo_schedule: cmts.cm_topo_schedule || { schedule_rate_s: 0 },
      ds_rxmer_schedule: cmts.ds_rxmer_schedule || { schedule_rate_s: 0 },
      us_rxmer_schedule: cmts.us_rxmer_schedule || { schedule_rate_s: 0 },
      ds_fec_schedule: cmts.ds_fec_schedule || { schedule_rate_s: 0 },
      us_fec_schedule: cmts.us_fec_schedule || { schedule_rate_s: 0 },
      ds_profile_calc_schedule: cmts.ds_profile_calc_schedule || { schedule_rate_s: 0 },
      us_profile_calc_schedule: cmts.us_profile_calc_schedule || { schedule_rate_s: 0 },
      cmts_kafka_stream_schedule: cmts.cmts_kafka_stream_schedule || { schedule_rate_s: 0 },
      kafka_username: cmts.kafka_username || '',
      kafka_password: cmts.kafka_password || '',
      kafka_bootstrap_servers: cmts.kafka_bootstrap_servers || '',
      kafka_group_id: cmts.kafka_group_id || '',
      kafka_rxmer_topic: cmts.kafka_rxmer_topic || '',
      kafka_topology_topic: cmts.kafka_topology_topic || '',
      kafka_extra_config:
        cmts.kafka_extra_config === null ? '' : JSON.stringify(cmts.kafka_extra_config, null, 3)
    },
    validate: validateSubmission,
    onSubmit: submitCallback,
    validateOnBlur: true,
    validateOnChange: true
  });

  return (
    <>
      <Accordion
        // Only renders in DOM when accordion is opened to save load time
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`cmts-${cmts.id}-content`}
          id={`cmts-${cmts.id}-header`}
          data-testid={`cmts-${cmts.id}-header`}
        >
          <Typography variant="h4">{cmts.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={formik.handleSubmit}>
            <input type="hidden" name="id" value={formik.values.id} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">CMTS</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormGroup row>
                  <TextField
                    name="hostname"
                    label={t('cmts_config.host_name')}
                    variant="outlined"
                    value={formik.values.hostname}
                    onChange={formik.handleChange}
                    error={formik.touched.hostname && Boolean(formik.errors.hostname)}
                    helperText={formik.touched.hostname && formik.errors.hostname}
                    inputProps={{ 'data-testid': 'hostname' }}
                  />
                  <NumberField
                    mode="integer"
                    label={t('cmts_config.snmp_port')}
                    name="cmts_port"
                    value={formik.values.cmts_port}
                    onChange={formik.handleChange}
                    variant="outlined"
                  />
                  <TextField
                    name="cmts_community_string"
                    label={t('cmts_config.community_string')}
                    variant="outlined"
                    type={showCMTSCommunityString ? 'text' : 'password'}
                    value={formik.values.cmts_community_string}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={t('cmts_config.community_string_aria')}
                            onClick={() => setShowCMTSCommunityString(!showCMTSCommunityString)}
                            onMouseDown={() => setShowCMTSCommunityString(!showCMTSCommunityString)}
                            edge="end"
                          >
                            {showCMTSCommunityString ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormGroup>
                <TagField
                  initTags={initialTags}
                  initValue={formik.values.tags}
                  onChange={(values): void => {
                    formik.setFieldValue('tags', values);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Cable Modem</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormGroup row>
                  <FormControl>
                    <NumberField
                      mode="integer"
                      label={t('cmts_config.snmp_port')}
                      name="cm_port"
                      variant="outlined"
                      value={formik.values.cm_port}
                      onChange={formik.handleChange}
                    />
                  </FormControl>

                  <FormControl>
                    <TextField
                      name="cm_community_string"
                      label={t('cmts_config.community_string')}
                      variant="outlined"
                      type={showCmCommunityString ? 'text' : 'password'}
                      value={formik.values.cm_community_string}
                      onChange={formik.handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label={t('cmts_config.community_string_aria')}
                              onClick={() => setShowCmCommunityString(!showCmCommunityString)}
                              onMouseDown={() => setShowCmCommunityString(!showCmCommunityString)}
                              edge="end"
                            >
                              {showCmCommunityString ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">{t('cmts_config.topology_collection')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <ScheduleToggle
                  handleChange={(values: CmtsSchedule): void => {
                    formik.setFieldValue('topology_schedule', values);
                  }}
                  schedule_toggle_info={formik.values.topology_schedule}
                  toggle_field_name="topology_schedule"
                  global_value_s={tenantScheduleState.topologySchedule.topology?.schedule_rate_s}
                />
              </Grid>
              <Grid item xs={12}>
                <ScheduleToggle
                  handleChange={(values: CmtsSchedule): void => {
                    formik.setFieldValue('cm_topo_schedule', values);
                  }}
                  schedule_toggle_info={formik.values.cm_topo_schedule}
                  toggle_field_name="cm_topology_schedule"
                  global_value_s={tenantScheduleState.topologySchedule.cm_topology?.schedule_rate_s}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">{t('cmts_config.pnm_collection')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <ScheduleToggle
                  handleChange={(values: CmtsSchedule): void => {
                    formik.setFieldValue('ds_rxmer_schedule', values);
                  }}
                  toggle_field_name="ds_rxmer_schedule"
                  schedule_toggle_info={formik.values.ds_rxmer_schedule}
                  global_value_s={tenantScheduleState.pnmSchedule.dsRxmer?.schedule_rate_s}
                />
              </Grid>
              <Grid item xs={12}>
                <ScheduleToggle
                  handleChange={(values: CmtsSchedule): void => {
                    formik.setFieldValue('us_rxmer_schedule', values);
                  }}
                  toggle_field_name="us_rxmer_schedule"
                  schedule_toggle_info={formik.values.us_rxmer_schedule}
                  global_value_s={tenantScheduleState.pnmSchedule.usRxmer?.schedule_rate_s}
                />
              </Grid>
              <Grid item xs={12}>
                <ScheduleToggle
                  handleChange={(values: CmtsSchedule): void => {
                    formik.setFieldValue('ds_fec_schedule', values);
                  }}
                  toggle_field_name="ds_fec_schedule"
                  schedule_toggle_info={formik.values.ds_fec_schedule}
                  global_value_s={tenantScheduleState.pnmSchedule.dsFec?.schedule_rate_s}
                />
              </Grid>
              <Grid item xs={12}>
                <ScheduleToggle
                  handleChange={(values: CmtsSchedule): void => {
                    formik.setFieldValue('us_fec_schedule', values);
                  }}
                  toggle_field_name="us_fec_schedule"
                  schedule_toggle_info={formik.values.us_fec_schedule}
                  global_value_s={tenantScheduleState.pnmSchedule.usFec?.schedule_rate_s}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">{t('cmts_config.profile_calculation')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <ScheduleToggle
                  handleChange={(values: CmtsSchedule): void => {
                    formik.setFieldValue('ds_profile_calc_schedule', values);
                  }}
                  toggle_field_name="ds_profile_calc_schedule"
                  schedule_toggle_info={formik.values.ds_profile_calc_schedule}
                  global_value_s={
                    tenantScheduleState.profileCalcSchedule?.dsProfileCalc?.schedule_rate_s
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ScheduleToggle
                  handleChange={(values: CmtsSchedule): void => {
                    formik.setFieldValue('us_profile_calc_schedule', values);
                  }}
                  toggle_field_name="us_profile_calc_schedule"
                  schedule_toggle_info={formik.values.us_profile_calc_schedule}
                  global_value_s={
                    tenantScheduleState.profileCalcSchedule?.usProfileCalc?.schedule_rate_s
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FormGroup row>
                  <TextField
                    name="pnm_server_ipv4_addr"
                    label={t('cmts_config.pma_recv_addr')}
                    variant="outlined"
                    value={formik.values.pnm_server_ipv4_addr}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.pnm_server_ipv4_addr &&
                      Boolean(formik.errors.pnm_server_ipv4_addr)
                    }
                    helperText={
                      formik.touched.pnm_server_ipv4_addr && formik.errors.pnm_server_ipv4_addr
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* Kafka Configuration */}
              <Accordion sx={{width: "100%"}} disableGutters elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="kafka-config-content"
                  id="kafka-config-header"
                >
                  <Typography variant="h5">
                    {t('cmts_config.kafka_configuration')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12} sx={{marginBottom: "20px"}}>
                    <ScheduleToggle
                      handleChange={(values: CmtsSchedule): void => {
                        formik.setFieldValue('cmts_kafka_stream_schedule', values);
                      }}
                      toggle_field_name="cmts_kafka_stream_schedule"
                      schedule_toggle_info={formik.values.cmts_kafka_stream_schedule}
                      global_value_s={
                        tenantScheduleState.kafkaSchedule?.kafkaStream?.schedule_rate_s
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup
                      sx={{ width: '400px', display: 'flex', flexDirection: 'column', gap: '10px' }}
                    >
                      <FormControl>
                        <TextField
                          label={t('cmts_config.kafka_username')}
                          name="kafka_username"
                          variant="outlined"
                          value={formik.values.kafka_username}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          label={t('cmts_config.kafka_password')}
                          name="kafka_password"
                          variant="outlined"
                          type={showKafkaPasswordString ? 'text' : 'password'}
                          value={formik.values.kafka_password}
                          onChange={formik.handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label={t('cmts_config.kafka_password_string_aria')}
                                  onClick={() => setShowKafkaPasswordString(!showKafkaPasswordString)}
                                  onMouseDown={() =>
                                    setShowKafkaPasswordString(!showKafkaPasswordString)
                                  }
                                  edge="end"
                                >
                                  {showKafkaPasswordString ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          label={t('cmts_config.kafka_bootstrap_servers')}
                          name="kafka_bootstrap_servers"
                          variant="outlined"
                          value={formik.values.kafka_bootstrap_servers}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          label={t('cmts_config.kafka_group_id')}
                          name="kafka_group_id"
                          variant="outlined"
                          value={formik.values.kafka_group_id}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          label={t('cmts_config.kafka_rxmer_topic')}
                          name="kafka_rxmer_topic"
                          variant="outlined"
                          value={formik.values.kafka_rxmer_topic}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          label={t('cmts_config.kafka_topology_topic')}
                          name="kafka_topology_topic"
                          variant="outlined"
                          value={formik.values.kafka_topology_topic}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          label={t('cmts_config.kafka_extra_config')}
                          name="kafka_extra_config"
                          variant="outlined"
                          multiline
                          value={formik.values.kafka_extra_config}
                          error={
                            formik.touched.kafka_extra_config &&
                            Boolean(formik.errors.kafka_extra_config)
                          }
                          helperText={
                            formik.touched.kafka_extra_config &&
                            (formik.errors.kafka_extra_config as string)
                          }
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {/* Profiles config */}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">{t('calc_max_profiles_config.cmts_title')}</Typography>
              </Grid>
              <Grid item xs={12} display="flex" gap="20px">
                <Grid item xs={6}>
                  <CalcMaxProfilesDropdown
                    handleChange={(values: CalcMaxProfilesInfo): void => {
                      setDsCalcMaxProfilesInfo(values);
                    }}
                    calcMaxProfilesInfo={dsCalcMaxProfilesInfo}
                    fieldName="ds_max_profiles"
                    globalCalcMaxProfiles={
                      globalCalcMaxProfilesState.globalDsUsCalcMaxProfilesInfo.dsCalcMaxProfilesInfo
                        ?.calc_max_profiles
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CalcMaxProfilesDropdown
                    handleChange={(values: CalcMaxProfilesInfo): void => {
                      setUsCalcMaxProfilesInfo(values);
                    }}
                    calcMaxProfilesInfo={usCalcMaxProfilesInfo}
                    fieldName="us_max_profiles"
                    globalCalcMaxProfiles={
                      globalCalcMaxProfilesState.globalDsUsCalcMaxProfilesInfo.usCalcMaxProfilesInfo
                        ?.calc_max_profiles
                    }
                  />
                </Grid>
              </Grid>
              {/* Templates Config*/}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">{t('template_config.title')}</Typography>
              </Grid>
              <Grid item xs={12} display="flex" gap="20px">
                <Grid item xs={6}>
                  <CalcTemplateProfilesDropdown
                    handleChange={(values: CalcConstraintTemplateProfile): void => {
                      setDsCalcConstraintTemplateProfiles(values);
                    }}
                    calcTemplateProfilesInfo={dsCalcConstraintTemplateProfiles}
                    fieldName="ds_template"
                    globalCalcTemplateProfileValue={
                      globalTemplateProfilesState.templateProfilesGlobalConfig
                        .downstreamTemplateProfiles?.calc_constraint_template_profile_id
                    }
                    direction="D"
                  />
                </Grid>
                <Grid item xs={6}>
                  <CalcTemplateProfilesDropdown
                    handleChange={(values: CalcConstraintTemplateProfile): void => {
                      setUsCalcConstraintTemplateProfiles(values);
                    }}
                    calcTemplateProfilesInfo={usCalcConstraintTemplateProfiles}
                    fieldName="us_template"
                    globalCalcTemplateProfileValue={
                      globalTemplateProfilesState.templateProfilesGlobalConfig
                        .upstreamTemplateProfiles?.calc_constraint_template_profile_id
                    }
                    direction="U"
                  />
                </Grid>
              </Grid>
              {/*<Grid item xs={6}>*/}
              {/*  <Button*/}
              {/*    color="secondary"*/}
              {/*    startIcon={<RiTreasureMapFill />}*/}
              {/*    variant="contained"*/}
              {/*    type="button"*/}
              {/*    data-testid="forceRetopo"*/}
              {/*  >*/}
              {/*    {t('cmts_config.force_topo')}*/}
              {/*  </Button>*/}
              {/*</Grid>*/}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <DeleteConfirmationDialog
                  open={openDeleteDialog}
                  message={t('cmts_config.delete_message')}
                  confirmText={t('cmts_config.delete')}
                  onClose={value => {
                    setOpenDeleteDialog(false);
                    if (value) {
                      handleDelete();
                    }
                  }}
                >
                  <Button
                    color="secondary"
                    startIcon={<MdDelete />}
                    variant="contained"
                    type="button"
                    data-testid="delete"
                    onClick={() => setOpenDeleteDialog(true)}
                  >
                    {t('cmts_config.delete')}
                  </Button>
                </DeleteConfirmationDialog>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  startIcon={<MdSave />}
                  variant="outlined"
                  data-testid="submit"
                  type="submit"
                >
                  {t('cmts_config.save')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

/*
id: number;
name: string;
hostname: string;
cmts_port: number;
cmts_community_string: string;
cm_port: number;
cm_community_string: string;
collect_topology: boolean
use_global_topo_schedule: boolean;
schedule_rate_s : number;
pnm_server_ipv4_addr?: string;
pnm_server_ipv6_addr?: string;
*/
