import {
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Switch,
  Typography,
  useTheme
} from '@mui/material';
import NumberField from '../../Components/NumberField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SecondsToTimeInterval, TimeIntervalToSeconds } from '../../Utils/Converters';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { CmtsSchedule } from '../../Api/Types/Config';
import { Timestamp } from '../../Components/Timestamp';

type ScheduleToggleProps = {
  handleChange: any;
  schedule_toggle_info: CmtsSchedule;
  toggle_field_name: string;
  global_value_s?: number;
};

const MIN_TIME = 5 * 60; // 5 minutes
const MAX_TIME = 30 * 24 * 60 * 60; // 30 days

export function ScheduleToggle(props: ScheduleToggleProps): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleChange, schedule_toggle_info, toggle_field_name, global_value_s } = props;

  const [error, setError] = React.useState<boolean>(false);

  const [global_value, global_value_unit] = SecondsToTimeInterval(global_value_s || 0);
  const [time, timeUnit] = SecondsToTimeInterval(schedule_toggle_info?.schedule_rate_s || 0);
  const toggledDisplayValue = schedule_toggle_info?.active ? 'flex' : 'none';
  const showGlobalEdit = !(schedule_toggle_info?.use_global || !schedule_toggle_info?.active);
  const globalToggledDisplayValue = showGlobalEdit ? 'block' : 'none';

  const onToggleChange = (event: any): void => {
    const [scheduleType, name] = event.target.name.split('.');
    const returnObj: any = {
      ...schedule_toggle_info,
    };
    returnObj[name] = event.target.checked;
    handleChange(returnObj);
  };

  const onTimeChange = (event: any): void => {
    const eventType = event.target.name === `${toggle_field_name}.schedule_rate` ? 'time' : 'unit';
    let unit = timeUnit;
    let timeValue = time;
    if (eventType === 'unit') {
      unit = event.target.value;
    } else {
      timeValue = event.target.value;
    }

    const timeS = TimeIntervalToSeconds(timeValue, unit);
    if (timeS < MIN_TIME || timeS > MAX_TIME) {
      setError(true);
    }else{
      setError(false);
    }
    const returnObj = {
      ...schedule_toggle_info,
      schedule_rate_s: timeS
    };
    handleChange(returnObj);
  };

  return (
    <>
      <FormGroup row sx={{ alignItems: 'center', height: '48px' }}>
        <FormControlLabel
          control={
            <Switch
              checked={schedule_toggle_info?.active}
              onChange={onToggleChange}
              name={`${toggle_field_name}.active`}
              inputProps={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                'data-testid': toggle_field_name
              }}
            />
          }
          label={t(`cmts_config.${toggle_field_name}`)}
        />
        {schedule_toggle_info?.last_run === null || schedule_toggle_info?.last_run === undefined ? (
          <></>
        ) : (
          <Typography variant="subtitle1">
            {t('cmts_config.last_schedule_attempt')}
            <Timestamp value_s={(schedule_toggle_info?.last_run)} />
          </Typography>
        )}
      </FormGroup>
      <FormGroup
        row
        style={{ display: toggledDisplayValue }}
        sx={{
          height: schedule_toggle_info?.active ? '48px' : 0,
          alignItems: 'center',
          transition: theme.transitions.create(['height', 'display'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
          })
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={schedule_toggle_info?.use_global}
              onChange={onToggleChange}
              name={`${toggle_field_name}.use_global`}
              disabled={!schedule_toggle_info?.active}
              inputProps={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                'data-testid': `${toggle_field_name}-use_global`
              }}
            />
          }
          label={t(`cmts_config.${toggle_field_name}-use_global`)}
        />
        <Box style={{ display: globalToggledDisplayValue }} sx={{ pl: '4px', pr: '4px' }}>
          <NumberField
            mode="integer"
            label={t('cmts_config.scan_every')}
            name={`${toggle_field_name}.schedule_rate`}
            value={time}
            variant="standard"
            onChange={onTimeChange}
            disabled={schedule_toggle_info?.use_global || !schedule_toggle_info?.active}
            error={error}
            helperText={error ? t('cmts_config.invalid_time') : ''}
            inputProps={{ 'data-testid': `${toggle_field_name}-schedule_rate` }}
          />
          <Select
            name={`${toggle_field_name}.schedule_rate_unit`}
            value={timeUnit}
            onChange={onTimeChange}
            disabled={schedule_toggle_info?.use_global || !schedule_toggle_info?.active}
            variant={'standard'}
            inputProps={{ 'data-testid': `${toggle_field_name}-schedule_rate_unit` }}
            sx={{ marginTop: '16px' }}
          >
            <MenuItem value="seconds">Seconds</MenuItem>
            <MenuItem value="minutes">Minutes</MenuItem>
            <MenuItem value="hours">Hours</MenuItem>
          </Select>
        </Box>
        <Typography variant="subtitle1">
          {t('cmts_config.global_setting')} {global_value} {global_value_unit}
        </Typography>
      </FormGroup>
      <Box
        sx={{ justifyContent: 'center', alignContent: 'center', width: '100%', display: 'flex' }}
      >
        <Divider style={{ display: toggledDisplayValue }} sx={{ width: '50%' }} />
      </Box>
    </>
  );
}
