import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, {useCallback, useEffect} from "react";
import {ApiRoutes} from "../../Api/ApiRoutes";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {useFeedbackContext} from "../../Feedback/FeedbackContext";
import Placeholder from "../../Placeholder";
import NumberField from "../../Components/NumberField";
import {MdSave} from "react-icons/md";
import {TenantPnmScheduleState, useGlobalConfigContext} from "../GlobalStateContext";
import {IsValidTime, RequireField} from "../../Feedback/ValidationUtils";
import {ApiPut} from "../../Api/Util";

function validateGlobalPnm(t: any, values: TenantPnmScheduleState): object {
    const errors: any = {};

    RequireField(t, errors, values.dsRxmer?.schedule_rate_s, "dsRxmer");
    RequireField(t, errors, values.usRxmer?.schedule_rate_s, "usRxmer");
    RequireField(t, errors, values.dsFec?.schedule_rate_s, "dsFec");
    RequireField(t, errors, values.usFec?.schedule_rate_s, "usFec");

    IsValidTime(t, errors, values.dsRxmer?.schedule_rate_s, "dsRxmer");
    IsValidTime(t, errors, values.usRxmer?.schedule_rate_s, "usRxmer");
    IsValidTime(t, errors, values.dsFec?.schedule_rate_s, "dsFec");
    IsValidTime(t, errors, values.usFec?.schedule_rate_s, "usFec");
    return errors;
}

export function GlobalPnmRequesterScheduleConfig(): JSX.Element {
    const {tenantScheduleState: {pnmSchedule, tenantScheduleLoaded}} = useGlobalConfigContext();

    const {t} = useTranslation();
    const {setFeedbackState} = useFeedbackContext();

    const updateFailure = useCallback((error: any) => {
        console.error(`${error}`)
        setFeedbackState({
            open: true,
            message: `${t('pnm_config.failed_update')}: ${error.message}`,
            severity: "error"
        });
    }, [setFeedbackState, t]);

    const updateSuccess = useCallback(() => {
        setFeedbackState({open: true, message: `${t('pnm_config.success_update')}`, severity: "success"});
    }, [setFeedbackState, t]);

    const submitCallback = useCallback((values: TenantPnmScheduleState) => {
        ApiPut(
            ApiRoutes.config.schedule.subset,
            [values.dsRxmer, values.usRxmer, values.dsFec, values.usFec]
        ).then(updateSuccess, updateFailure);
    }, [updateSuccess, updateFailure]);


    const validateSubmission = useCallback(
        (values: TenantPnmScheduleState): object => validateGlobalPnm(t, values),
        [t]
    );

    const formik = useFormik({
            initialValues: pnmSchedule,
            validate: validateSubmission,
            onSubmit: submitCallback,
            validateOnBlur: true,
            validateOnChange: true
        }
    );

    useEffect(() => {
        formik.setValues(pnmSchedule);
    }, [pnmSchedule]);

    return <>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="global-pnm-schedule"
            >
                <Typography variant="h5">PNM Requester Schedule</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    !tenantScheduleLoaded ? (
                        <Placeholder />
                    ) : (
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <NumberField
                                        mode="integer"
                                        label={t('pnm_config.request_ds_rxmer_schedule_rate')}
                                        name="dsRxmer.schedule_rate_s"
                                        value={formik.values.dsRxmer?.schedule_rate_s || ''}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors.dsRxmer)}
                                        helperText={formik.errors.dsRxmer}
                                        variant="outlined"
                                        inputProps={{'data-testid': "request_ds_rxmer_schedule_rate"}}
                                        sx={{width: "100%"}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <NumberField
                                        mode="integer"
                                        label={t('pnm_config.request_us_rxmer_schedule_rate')}
                                        name="usRxmer.schedule_rate_s"
                                        value={formik.values.usRxmer?.schedule_rate_s || ''}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors.usRxmer)}
                                        helperText={formik.errors.usRxmer}
                                        variant="outlined"
                                        inputProps={{'data-testid': "request_us_rxmer_schedule_rate"}}
                                        sx={{width: "100%"}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <NumberField
                                        mode="integer"
                                        label={t('pnm_config.request_ds_fec_schedule_rate')}
                                        name="dsFec.schedule_rate_s"
                                        value={formik.values.dsFec?.schedule_rate_s || ''}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors.dsFec)}
                                        helperText={formik.errors.dsFec}
                                        variant="outlined"
                                        inputProps={{'data-testid': "request_ds_fec_schedule_rate"}}
                                        sx={{width: "100%"}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <NumberField
                                        mode="integer"
                                        label={t('pnm_config.request_us_fec_schedule_rate')}
                                        name="usFec.schedule_rate_s"
                                        value={formik.values.usFec?.schedule_rate_s || ''}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors.usFec)}
                                        helperText={formik.errors.usFec}
                                        variant="outlined"
                                        inputProps={{'data-testid': "request_us_fec_schedule_rate"}}
                                        sx={{width: "100%"}}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Button color="primary" startIcon={<MdSave />} variant="outlined"
                                            data-testid="submit"
                                            type="submit">
                                        {t('pnm_config.save')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }
            </AccordionDetails>
        </Accordion>
    </>;
}
